export const getLocationFromSiteId = (locations: any, siteId: String) => {
  return locations.find((loc) =>
    loc?.sites?.some((site) => site.id === siteId)
  );
};

export const getLocationName = (location: any) => {
  if (!location) {
    return "Not Assigned";
  } else {
    return location.name;
  }
};
