import { useEffect, useContext } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

export const CORE_ALARM_FIELDS = gql`
  fragment COREALARMFIELDS on PaginatedAlarmResponses {
    page {
      size
      number
      totalPages
      totalElements
    }
    alarms {
      id
      device {
        id
        name
        type
        site {
          id
          name
          status
        }
      }
      values
      description
      severity
      time
      status
      assignedTo {
        id
        firstName
        lastName
        email
        role
        company
        status
      }
      updatedBy {
        id
        firstName
        lastName
        email
        role
        company
        status
      }
      updatedOn
    }
  }
`;

const GET_ALARMS = gql`
  ${CORE_ALARM_FIELDS}
  query alarms(
    $tenantId: ID!
    $page: Int
    $size: Int
    $sort: [String]
    $filter: [String]
  ) {
    alarms(
      tenantId: $tenantId
      page: $page
      size: $size
      sort: $sort
      filter: $filter
    ) {
      ...COREALARMFIELDS
    }
  }
`;

const UPDATE_ALARM = gql`
  mutation updateAlarms($input: UpdateAlarmsInput!) {
    updateAlarms(input: $input) {
      alarms {
        id
        device {
          id
          name
          type
          site {
            id
            name
          }
        }
        values
        description
        severity
        time
        status
        assignedTo {
          id
          firstName
          lastName
          email
          role
          company
          status
        }
        updatedBy {
          id
          firstName
          lastName
          email
          role
          company
          status
        }
        updatedOn
      }
    }
  }
`;

const ASSIGN_ALARMS = gql`
  mutation assignAlarms($input: AssignAlarmsInput!) {
    assignAlarms(input: $input) {
      alarms {
        id
        assignedTo {
          id
          firstName
          lastName
          email
          role
          company
          status
        }
      }
    }
  }
`;

const UNASSIGN_ALARMS = gql`
  mutation unassignAlarms($input: UnassignAlarmsInput!) {
    unassignAlarms(input: $input) {
      alarms {
        id
        assignedTo {
          id
          firstName
          lastName
          email
          role
          company
          status
        }
      }
    }
  }
`;

const EXPORT_ALARMS = gql`
  ${CORE_ALARM_FIELDS}
  query exportAlarms(
    $tenantId: ID!
    $page: Int
    $size: Int
    $sort: [String]
    $filter: [String]
  ) {
    alarms(
      tenantId: $tenantId
      page: $page
      size: $size
      sort: $sort
      filter: $filter
    ) {
      ...COREALARMFIELDS
    }
  }
`;

const GET_ESCALATION_HISTORY = gql`
  query escalationHistory($tenantId: ID!, $alarmId: Long!) {
    alarmEscalationLogs(tenantId: $tenantId, alarmId: $alarmId) {
      id
      message
      escalatedBy {
        firstName
        lastName
      }
      escalatedOn
    }
  }
`;

const useAlarm = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [
    fetchAlarms,
    {
      loading: isFetchAlarmLoading,
      error: fetchAlarmError,
      data: fetchAlarmData,
    },
  ] = useLazyQuery(GET_ALARMS, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const [
    exportAlarms,
    {
      loading: isExportAlarmsLoading,
      error: exportAlarmsError,
      data: exportAlarmsData,
    },
  ] = useLazyQuery(EXPORT_ALARMS, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const [
    updateAlarms,
    {
      loading: isUpdateAlarmLoading,
      error: updateAlarmError,
      data: updateAlarmResponseData,
      reset: resetUpdateAlarmData,
    },
  ] = useMutation(UPDATE_ALARM, {
    // Then re-run fetch
    refetchQueries: [GET_ALARMS],
  });

  const [
    assignAlarms,
    {
      loading: isAssignAlarmsLoading,
      error: assignAlarmsError,
      data: assignAlarmsResponseData,
      reset: resetAssignAlarms,
    },
  ] = useMutation(ASSIGN_ALARMS, {
    // Then re-run fetch
    refetchQueries: [GET_ALARMS],
  });

  const [
    unassignAlarms,
    {
      loading: isUnassignAlarmsLoading,
      error: unassignAlarmsError,
      data: unassignAlarmsResponseData,
      reset: resetUnassignAlarms,
    },
  ] = useMutation(UNASSIGN_ALARMS, {
    // Then re-run fetch
    refetchQueries: [GET_ALARMS],
  });

  const [
    escalationHistory,
    {
      loading: escalationHistoryLoading,
      error: escalationHistoryError,
      data: escalationHistoryData,
    },
  ] = useLazyQuery(GET_ESCALATION_HISTORY, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  useEffect(() => {
    if (fetchAlarmError || updateAlarmError) {
      const error =
        fetchAlarmError ||
        updateAlarmError ||
        assignAlarmsError ||
        exportAlarmsError ||
        escalationHistoryError;
      handleError(error, uri, enqueueSnackbar, intl);
    }
  }, [
    fetchAlarmError,
    updateAlarmError,
    assignAlarmsError,
    exportAlarmsError,
    escalationHistoryError,
  ]);

  return {
    isFetchAlarmLoading,
    fetchAlarmError,
    fetchAlarmData,
    fetchAlarms,
    updateAlarms,
    isUpdateAlarmLoading,
    updateAlarmError,
    updateAlarmResponseData,
    resetUpdateAlarmData,
    assignAlarms,
    isAssignAlarmsLoading,
    assignAlarmsError,
    assignAlarmsResponseData,
    resetAssignAlarms,
    unassignAlarms,
    isUnassignAlarmsLoading,
    unassignAlarmsError,
    unassignAlarmsResponseData,
    resetUnassignAlarms,
    exportAlarms,
    isExportAlarmsLoading,
    exportAlarmsError,
    exportAlarmsData,
    escalationHistory,
    escalationHistoryLoading,
    escalationHistoryError,
    escalationHistoryData,
  };
};

export default useAlarm;
