const messages = {
  // Login
  email: "E-mail",
  login: "Connexion",
  password: "Mot de passe",

  // Profile
  my_profile: "Mon Profil",
  name: "Nom",
  company: "Entreprise",
  contact_number: "Numéro de contact",
  role: "Rôle",
  change_password: "Changer le mot de passe",
  profile_info: "Informations sur le profil",
  log_out: "Se déconnecter",
  confirm_logout: "Êtes-vous sûr de vouloir vous déconnecter?",

  // Users
  add_user: "Ajouter un utilisateur",
  update_profile_info: "Mettre à jour les informations du profil",
  first_name: "Prénom",
  last_name: "Nom de famille",
  deactivate_user: "Désactiver l'utilisateur",
  excel_filename_user: "Rapport des utilisateurs",
  select_country_code: "Sélectionnez le code du pays",

  // Roles
  ADMIN: "Administrateur",
  OPERATOR: "Opérateur",

  // success messages
  user_deactivated_success: "Utilisateur(s) désactivé(s) avec succès",
  new_user_added: "Nouvel utilisateur ajouté avec succès",
};

export default messages;
