const messages = {
  title_alarm: "Alarms",
  alarm_tbl_col_header_device: "Device",
  alarm_tbl_col_header_site: "Site",
  alarm_tbl_col_header_attribute: "Attribute",
  alarm_tbl_col_header_description: "Description",
  alarm_tbl_col_header_severity: "Level",
  alarm_tbl_col_header_assigned_to: "Assigned To",
  excel_filename_alarm: "Alarm Report",
  alarm_tbl_assign_to: "Assign To",
  alarm_tble_col_id: "ID",

  // Filters
  sites: "Sites",
  device_types: "Device Types",
  alarm_levels: "Alarm Levels",
  date_range: "Date Range",

  // Table More button
  go_to_device: "Go To Device",
  acknowledge: "Acknowledge",
  deactivate: "Deactivate",
  reactivate: "Reactivate",

  // Rules Types
  EQ: "Equal",
  NE: "Not equal",
  GT: "Greater than",
  GTE: "Greater than or equal to",
  LT: "Less than",
  LTE: "Less than or equal to",

  // success messages
  alarms_deactivated: "Alarms Deactivated",
  issue_escalation: "Issue Escalation",
  close: "Close",

  //escalation logs table
  escalation_logs: "Escalation Logs",
  escalated_id: "ID",
  escalated_message: "message",
  escalated_by: "Escalated By",
  escalated_on: "Escalated On",

  unassign_alarm: "Unassign Alarm",
};

export default messages;
