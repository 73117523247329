import { useEffect, useContext } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const GET_MODELS = gql`
  query models {
    models {
      id
      name
      deviceType
      description
      status
    }
  }
`;

const useModel = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [
    fetchModels,
    {
      loading: isFetchModelsLoading,
      error: fetchModelsError,
      data: fetchModelsData,
    },
  ] = useLazyQuery(GET_MODELS);

  useEffect(() => {
    if (fetchModelsError) {
      handleError(fetchModelsError, uri, enqueueSnackbar, intl);
    }
  }, [fetchModelsError]);

  return {
    isFetchModelsLoading,
    fetchModelsError,
    fetchModelsData,
    fetchModels,
  };
};

export default useModel;
