const messages = {
  title_alarm: "Alarmes",
  alarm_tbl_col_header_device: "Appareil",
  alarm_tbl_col_header_site: "Site",
  alarm_tbl_col_header_attribute: "Attribut",
  alarm_tbl_col_header_description: "Description",
  alarm_tbl_col_header_severity: "Niveau",
  alarm_tbl_col_header_assigned_to: "Assigné",
  excel_filename_alarm: "Rapport d'alarme",
  alarm_tbl_assign_to: "Affecter à",
  alarm_tble_col_id: "IDENTIFIANT",

  // Filters
  sites: "Des sites",
  device_types: "Types d'appareils",
  alarm_levels: "Niveaux d'alarme",
  date_range: "Plage de dates",

  // Table More button
  go_to_device: "Aller à l'appareil",
  acknowledge: "Reconnaître",
  deactivate: "Désactiver",
  reactivate: "Réactiver",

  // Rules Types
  EQ: "égal",
  NE: "Inégal",
  GT: "Plus grand que",
  GTE: "Plus grand ou égal à",
  LT: "Moins que",
  LTE: "Inférieur ou égal à",

  // success messages
  alarms_deactivated: "Alarmes désactivées",
  issue_escalation: "Escalade du problème",
  close: "fermer",

  //escalation logs table
  escalation_logs: "journaux d'escalade",
  escalated_id: "identifiant",
  escalated_message: "message",
  escalated_by: "Escaladé par",
  escalated_on: "Escaladé le",

  unassign_alarm: "Annuler l'attribution d'une alarme",
};

export default messages;
