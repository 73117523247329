import { useEffect, useContext } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const GET_LOCATIONS = gql`
  query locations($tenantId: ID!) {
    locations(tenantId: $tenantId) {
      id
      country {
        id
        name
      }
      name
      latitude
      longitude
      sites {
        id
        name
        url
        status
      }
      highestSeverity
      status
    }
  }
`;

const ADD_LOCATION = gql`
  mutation addLocation($input: AddLocationInput!) {
    addLocation(input: $input) {
      location {
        id
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        country {
          id
          name
        }
        name
        latitude
        longitude
        sites {
          id
          name
          url
          status
        }
        status
      }
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation updateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      location {
        id
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        country {
          id
          name
        }
        name
        latitude
        longitude
        sites {
          id
          name
          status
          url
        }
        status
      }
    }
  }
`;

const useLocation = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [
    fetchLocations,
    { loading: isFetchLoading, error: fetchError, data: fetchLocationData },
  ] = useLazyQuery(GET_LOCATIONS);

  const [
    addLocation,
    {
      loading: isAddLocationLoading,
      error: addLocationError,
      data: addLocationResponseData,
      reset: resetAddLocationData,
    },
  ] = useMutation(ADD_LOCATION, {
    // Then re-run fetch
    refetchQueries: [GET_LOCATIONS],
  });

  // for updating and deleting locations
  const [
    updateLocation,
    {
      loading: isUpdateLocationLoading,
      error: updateLocationError,
      data: updateLocationResponseData,
      reset: resetUpdateLocationData,
    },
  ] = useMutation(UPDATE_LOCATION, {
    // Then re-run fetch
    refetchQueries: [GET_LOCATIONS],
  });

  useEffect(() => {
    if (fetchError || addLocationError || updateLocationError) {
      const error = fetchError || addLocationError || updateLocationError;
      handleError(error, uri, enqueueSnackbar, intl);
    }
  }, [fetchError, addLocationError, updateLocationError]);

  return {
    isFetchLoading,
    fetchError,
    fetchLocationData,
    fetchLocations,
    isAddLocationLoading,
    addLocationError,
    addLocationResponseData,
    addLocation,
    resetAddLocationData,
    isUpdateLocationLoading,
    updateLocationError,
    updateLocationResponseData,
    updateLocation,
    resetUpdateLocationData,
  };
};

export default useLocation;
