import { createContext, Dispatch, SetStateAction } from "react";

interface LoadingContextType {
  isLoading: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
}

const defaultState: LoadingContextType = {
  isLoading: false,
  setIsLoading: () => {},
};

const LoadingContext = createContext<LoadingContextType | null>(defaultState);

export default LoadingContext;
