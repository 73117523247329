const messages = {
  gateway_header_title: "管理网关",
  gateway_label: "网关",
  gateway_status: "地位",
  gateway_modem_operator: "Sim 卡",
  gateway_last_connection: "上次连接",
  gateway_device_firmware_version: "固件版本",

  gateway_details: "细节",
  //gateway table headers
  gateway_table_name: "名称",
  gateway_table_type: "类型",
  gateway_table_id: "ID",
  gateway_table_location: "地点",
  gateway_table_site: "地点",
  gateway_table_status: "地位",

  // gateway tabs
  gateway_information_tab: "信息",
  gateway_alarms_tab: "报警规则",
  gateway_configuration_tab: "配置",

  gateway_details_name: "名称",
  gateway_details_deviceId: "设备ID",
  gateway_details_group: "团体",
  gateway_details_ip_address: "IP地址",
  gateway_details_port: "港口",
  gateway_details_read_community: "阅读社区",
  gateway_details_write_community: "撰写社区",
  gateway_details_activated: "活性",
  gateway_details_interval: "间隔",
  gateway_details_port_unit_id: "单位编号",
  gateway_details_serial_number: "序列号",
  //dynamic tab
  gateway_dynamic_key: "钥匙",
  gateway_dynamic_oid: "对象标识符",
  gateway_dynamic_unit: "单元",
  gateway_dynamic_multiplier: "乘数",
  gateway_dynamic_type: "类型",
  gateway_dynamic_writable: "可写",
  gateway_dynamic_logged: "已记录",
  gateway_dynamic_function: "功能",
  gateway_dynamic_fc: "足球俱乐部",
  gateway_dynamic_address: "地址",
  gateway_dynamic_length: "长度",
  gateway_dynamic_modbus_type: "Modbus 类型",
  gateway_dynamic_channel: "渠道",
  gateway_dynamic_sensor_type: "传感器类型",

  //static tab
  gateway_statics_key: "钥匙",
  gateway_statics_oid: "对象标识符",
  gateway_statics_unit: "单元",
  gateway_statics_multiplier: "乘数",
  gateway_statics_type: "类型",
  gateway_statics_writable: "可写",
  gateway_statics_logged: "已记录",
  gateway_statics_function: "功能",
  gateway_statics_fc: "足球俱乐部",
  gateway_statics_address: "地址",
  gateway_statics_length: "长度",
  gateway_statics_modbus_type: "Modbus 类型",
  gateway_statics_channel: "渠道",
  gateway_statics_sensor_type: "传感器类型",
  //trap tab
  gateway_trap_oid: "OID",
  gateway_trap_key: "属性名称",

  //gateway alarms
  gateway_alarms_name_tab: "Name",
  gateway_alarms_description_tab: "描述",
  gateway_alarms_email: "电子邮件",
  gateway_alarms_level: "信息",
  gateway_alarms_status: "地位",
  gateway_alarms_attribute_name: "属性名称",
  gateway_alarms_operator_name: "操作员",
  gateway_alarms_operator_value: "价值",

  gateway_alarm_info: "信息",
  gateway_alarm_warn: "警告",
  gateway_alarm_crictical: "批判的",

  gateway_int: "整数",
  gateway_double: "双倍的",
  gateway_string: "细绳",
  gateway_boolean: "布尔值",

  gateway_alarm_activated: "活性",
  gateway_alarm_deactivated: "已停用",

  gateway_operator_eq: "EQ",
  gateway_operator_ne: "NE",
  gateway_operator_gt: "GT",
  gateway_operator_ge: "GE",
  gateway_operator_lt: "LT",
  gateway_operator_le: "LE",

  //gateway alarms table
  gateway_alarms_name: "名称",
  gateway_alarms_description: "描述",
  gateway_alarms_properties: "描述",
  gateway_alarms_severity: "严重程度",
  gateway_alarms_date: "日期",
  gateway_alarms_actions: "操作",

  //protocols
  snmpv2c: "SNMP Agent",
  efento: "Efento BLE",
  modbustcp: "Modbus TCP",
  modbusrtu: "Modbus RTU",

  snmpv2c_address: "SNMP 代理",
  efento_address: "Efento 序列号",
  modbustcp_address: "Modbus TCP 单元",
  modbusrtu_address: "Modbus RTU 单元",
  gateway_deviceId: "设备ID",

  gateway_device_online: "在线的",
  gateway_device_offline: "离线",

  gateway_na: "无法使用",
  gateway_delete: "删除",
  gateway_setting: "环境",

  gateway_dynamics: "动力学",
  gateway_statics: "静力学",
  gateway_trap: "陷阱",
  gateway_alarms: "警报",

  gateway_true: "真的",
  gateway_false: "错误的",

  gateway_public: "民众",
  gateway_private: "私人的",

  gateway_config_create: "新的",
  gateway_config_update: "更新",

  gateway_delete_device: "删除配置",

  gateway_cancel_config_btn: "后退",
  gateway_submit_config_btn: "提交",
  gateway_update_config_btn: "更新",

  gateway_success_message: "配置成功完成。",
  gateway_reinitialize_message: "网关已成功重新初始化。",
  gateway_device_delete_message: "设备配置删除成功",
};

export default messages;
