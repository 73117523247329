import { useContext, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import Box from "@mui/material/Box";
import "./BasicGoogleMap.css";
import MapErrorBoundary from "./MapErrorBoundary";
import LocationPinBlue from "../../images/Icons/LocationPinBlue.svg";
import LocationPinYellow from "../../images/Icons/LocationPinYellow.svg";
import LocationPinOrange from "../../images/Icons/LocationPinOrange.svg";
import LocationPinRed from "../../images/Icons/LocationPinRed.svg";
import { useIntl } from "react-intl";
import axios from "axios";
import DarkModeContext from "../../providers/context/darkModeContext";

const googleMapApiKey = "AIzaSyCHt3o4mCRQcY8VB88d1_jHya7sljozyhM";
const zoom = 3;

const defaultOptions = {
  mapTypeControl: false,
  streetViewControl: false,
};

const darkModeOptions = {
  styles: [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ],
};

const BasicGoogleMap = (props) => {
  const { mapProps, onFailCallback, locations, setSelectedLocation } = props;
  const intl = useIntl();
  const locale = intl.locale;
  const { isLoaded, loadError } = useLoadScript({
    //googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    googleMapsApiKey: googleMapApiKey,
    language: locale,
  });
  const { darkMode }: any = useContext(DarkModeContext);

  useEffect(() => {
    console.log("calling google map api..."); //eslint-disable-line
    // try to call googlemap apis if timeout call onFailCallback
    axios
      .get(
        "https://maps.googleapis.com/maps/api/mapsjs/gen_204?csp_test=true",
        { timeout: 2000 }
      )
      .then(() => {
        console.log("google map api called successfully"); //eslint-disable-line
      })
      .catch((e) => {
        console.log("google map api error: ", e.message); //eslint-disable-line
        onFailCallback();
      });

    if (loadError) {
      onFailCallback();
    }
  }, [loadError]);

  useEffect(() => {
    const isGoogleMapAvail =
      window.localStorage.getItem("isGoogleMapAvail") === "" ||
      window.localStorage.getItem("isGoogleMapAvail") === null;
    // if script is loaded window.google will not be null
    // if there was an error before, isGoogleMapAvail will be 'false'
    if (window.google && !isGoogleMapAvail) {
      onFailCallback();
    }
  }, []);

  return (
    <Box
      sx={{ display: "flex", height: "100%", width: "100%" }}
      className="Map"
    >
      {isLoaded && (
        <MapErrorBoundary fallback={onFailCallback}>
          <GoogleMap
            mapContainerClassName="map-container"
            center={mapProps.center}
            zoom={zoom}
            options={
              darkMode
                ? { ...defaultOptions, ...darkModeOptions }
                : defaultOptions
            }
          >
            {locations?.map((loc) => {
              const lat = loc.latitude;
              const lng = loc.longitude;
              let icon = null;
              switch (loc.highestSeverity) {
                case "INFORMATION":
                  icon = LocationPinYellow;
                  break;
                case "WARNING":
                  icon = LocationPinOrange;
                  break;
                case "CRITICAL":
                  icon = LocationPinRed;
                  break;
                default:
                  icon = LocationPinBlue;
              }

              return (
                <Marker
                  key={loc.id}
                  position={{ lat, lng }}
                  icon={icon}
                  onClick={() => {
                    setSelectedLocation(loc);
                  }}
                />
              );
            })}
          </GoogleMap>
        </MapErrorBoundary>
      )}
    </Box>
  );
};

export default BasicGoogleMap;
