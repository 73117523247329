const messages = {
  // Device Monitoring
  devices: "Devices",
  new_device_added: "New Device Added",
  update_device: "Update Device",
  select_type_of_device: "Select Type of Device",
  type_of_env: "Type of Environment",
  select_model: "Select Model",
  enter_device_name: "Enter Device Name",
  env_type: "Environment Type",
  add_an_env: "Add an Environment",
  copy_device_id: "Copy Device ID",

  // Table headers
  type: "Type",
  device_id: "Device Id",

  // Filters
  select_device: "Select Device",

  // Device Types
  GATEWAY: "Gateway",
  DOOR_CONTACT: "Door Contact",
  WATER_LEAK: "Water Leak",
  COOLING_SYSTEM: "Cooling System",
  UPS: "UPS",
  PDU: "PDU",
  SMOKE_DETECTOR: "Smoke Detector",
  TEMP_HUMIDITY: "Temperature and Humidity",
  env: "Environment",

  // Device Details
  device: "Device",
  device_updated: "Device Updated",
  back_to_device: "Back to Device",
  restart: "Restart",
  update_firmware: "Update Firmware",
  delete_device: "Delete Device",
  alarm_rule: "Alarm Rule",
  update_lan_setting: "Update LAN Setting",
  download_installer_sh: "Download Installer .sh",
  device_id_copy_success: "Device ID copied",

  // menu tabs
  device_detail: "Device Detail",
  set_parameter: "Set Parameter",

  // Device Cards
  device_power_status: "Status",
  device_on: "Online",
  device_off: "Offline",
  sim_card: "Sim Card",
  sim_inserted: "Inserted",
  sim_not_inserted: "Not Inserted",
  last_connection: "Last Connection",
  firmware_version: "Firmware Version",
  device_information: "Device Information",
  connection: "Connection",
  device_type: "Device Type",
  serial_number: "Serial Number",
  modem_imei: "Modem IMEI",
  modem_operator: "Modem Operator",
  signal_strength: "Signal Strength",
  signal_strength_0: "No Signal",
  signal_strength_1: "Poor",
  signal_strength_2: "Fair",
  signal_strength_3: "Good",
  signal_strength_4: "Excellent",
  conn_type: "Connection Type",
  conn_state: "Connection State",
  connected: "Connected",
  disconnected: "Disconnected",
  conn_uptime: "Connection Uptime",

  // Charts
  signal_strength_chart_title: "Signal Strength Over Time",
  time: "Time",

  // Device status
  value: "Value",
  input: "Input",

  // Front Door
  front_door: "Front Door",
  back_door: "Back Door",

  // Aircon
  serious_alarm: "Serious Alarm Activated",
  air_outlet_temperature: "Air Outlet Temperature",
  AL49_fire_alarm: "AL49 Fire Alarm Activated",
  dc_fan_voltage: "DC Fan Voltage",
  air_return_humidity: "Air Return Humidity",
  running_frequency: "Running Frequency",
  AL50_high_air_return_temp: "AL50 Air Return Temperature Too High",
  AL52_low_air_outlet_temp: "AL52 Air Outlet Temperature Too Low",
  common_alarm: "Common Alarm Activated",
  AL51_high_air_outlet_temp: "AL51 Air Outlet Temperature Too High",
  evaporation_temperature: "Evaporation Temperature",
  suction_pressure: "Suction Pressure",
  target_frequency: "Target Frequency",
  discharge_pressure: "Discharge Pressure",
  "out-coil_temperature": "Out-Coil Temperature",
  AL53_condensation_pump_fail: "AL53 Condensation Pump Failed",
  "in-coil_temperature": "In-Coil Temperature",
  target_evaporation_temperature: "Target Evaporation Temperature",
  discharge_temperature: "Discharge Temperature",
  air_return_temperature: "Air Return Temperature",
  "on/off": "On/Off",
  communication_state: "Communication State",
  temperature_setting: "Temperature Setting",
  indoor_humidity: "Indoor Humidity",
  humidity_setting: "Humidity Setting",
  compressor: "Compressor",
  "1#heater": "1# Heater",
  "2#heater": "2# Heater",
  indoor_temperature: "Indoor Temperature",
  unit_state: "Unit State",
  heater_temp_threshold: "Heater Temperature Threshold",
  evaporator_temp: "Evaporator Temperature",
  condenser_protected_temp_threshold:
    "Condenser Protected Temperature Threshold",
  cooling_start_temp: "Cooling Start Temperature",
  condenser_temp: "Condenser Temperature",
  evaporator_tube_temp: "Evaporator Tube Temperature",
  cooling_threshold: "Cooling Threshold",
  high_temp_threshold: "High Temperature Threshold",
  heating_start_temp: "Heating Start Temperature",
  heater: "Heater",
  debug_temp: "Debug Temperature",
  low_temp_threshold: "Low Temperature Threshold",
  evaporator_anti_frozen_temp_threshold:
    "Evaporator Anti Frozen Temperature Threshold",
  return_temp: "Return Temperature",
  evaporator_anti_frozen_temp: "Evaporator Anti Frozen Temperature",
  condenser_protected_temp: "Condenser Protected Temperature",
  condenser_tube_temp: "Condenser Tube Temperature",
  air_suction_temperature: "Air Suction Temperature",
  liquid_pipe_temperature: "Liquid Pipe Temperature",
  subcooling: "Subcooling",
  indoor_fan_speed: "Indoor Fan Speed",
  compressor_output: "Compressor Output",
  outdoor_fan_speed: "Outdoor Fan Speed",
  superheat: "Super Heat",
  // DTC
  working_status: "Working Status",
  air_supply_temperature: "Air Supply Temperature",
  air_supply_temperature_setting: "Air Supply Temperature Setting",
  air_return_temperature_setting: "Air Return Temperature Setting",
  air_return_humidity_setting: "Air Return Humidity Setting",
  comm_status: "Communication Status",
  unit_operating_status: "Unit Operating Status",
  air_supply_switch: "Air Supply Switch",
  "1#_heating_switch": "1# Heating Switch",
  "2#_heating_switch": "2# Heating Switch",
  compressor_switch: "Compressor Switch",
  condensate_pump_switch: "Condensate Pump Switch",
  humidifying_switch: "Humidifier Switch",

  // Door
  door: "Door",
  door_contact: "Door Contact",

  // Fire Alarm
  fire_alarm: "Fire Alarm",

  // Meter
  pue: "Power Usage Effectiveness",
  total_apparent_power: "Total Apparent Power",
  total_active_power: "Total Active Power",
  total_power_factor: "Total Power Factor",
  total_reactive_power: "Total Reactive Power",
  average_phase_current: "Average Phase Current",
  frequency: "Frequency",
  average_phase_voltage: "Average Phase Voltage",
  import_active_energy: "Import Active Energy",
  total_active_energy: "Total Active Energy",
  power: "Power",
  power_factor: "Power Factor",

  // UPS
  battery_capacity: "Battery Capacity",
  output_current: "Output Current",
  output_frequency: "Output Frequency",
  battery_voltage: "Battery Voltage",
  output_power: "Output Power",
  battery_temperature: "Battery Temperature",
  input_frequency: "Input Frequency",
  input_voltage: "Input Voltage",
  battery_timeRemaining: "Battery Time Remaining",
  output_load: "Output Load",
  input_current: "Input Current",
  output_voltage: "Output Voltage",
  max_temperature: "Max Temperature",
  ups_type: "UPS Type",
  battery_healthVoltage: "Battery Health Voltage",
  input_truePower: "Input True Power",
  output_a_phase_active_power: "Output A Phase Active Power",
  output_b_phase_active_power: "Output B Phase Active Power",
  output_c_phase_active_power: "Output C Phase Active Power",
  output_a_phase_load: "Output A Phase Load",
  output_b_phase_load: "Output B Phase Load",
  output_c_phase_load: "Output C Phase Load",
  output_a_phase_current: "Output A Phase Current",
  output_b_phase_current: "Output B Phase Current",
  output_c_phase_current: "Output C Phase Current",
  output_a_phase_voltage: "Output A Phase Voltage",
  output_b_phase_voltage: "Output B Phase Voltage",
  output_c_phase_voltage: "Output C Phase Voltage",
  output_a_phase_load_peak: "Output A Phase Load Peak",
  output_b_phase_load_peak: "Output B Phase Load Peak",
  output_c_phase_load_peak: "Output C Phase Load Peak",
  input_a_phase_current: "Input A Phase Current",
  input_b_phase_current: "Input B Phase Current",
  input_c_phase_current: "Input C Phase Current",
  input_a_phase_voltage: "Input A Phase Voltage",
  input_b_phase_voltage: "Input B Phase Voltage",
  input_c_phase_voltage: "Input C Phase Voltage",
  input_a_phase_power_factor: "Input A Phase Power Factor",
  input_b_phase_power_factor: "Input B Phase Power Factor",
  input_c_phase_power_factor: "Input C Phase Power Factor",
  bypass_a_phase_voltage: "Bypass A Phase Voltage",
  bypass_b_phase_voltage: "Bypass B Phase Voltage",
  bypass_c_phase_voltage: "Bypass C Phase Voltage",
  bypass_frequency: "Bypass Frequency",
  buzzer: "Buzzer",
  load: "Load",
  utility_state: "Utility State",
  "i/p_fault_voltage": "I/P Fault Voltage",
  fault_state: "Fault State",
  battery_low_voltage: "Battery Low Voltage",
  shutdown_active: "Shutdown Active",
  battery_cell_voltage: "Battery Cell Voltage",
  // DTC
  input_voltage_a: "Input Voltage A",
  input_voltage_b: "Input Voltage B",
  input_voltage_c: "Input Voltage C",
  output_voltage_a: "Output Voltage A",
  output_voltage_b: "Output Voltage B",
  output_voltage_c: "Output Voltage C",
  output_current_a: "Output Current A",
  output_current_b: "Output Current B",
  output_current_c: "Output Current C",
  output_load_a: "Output Load A",
  output_load_b: "Output Load B",
  output_load_c: "Output Load C",
  standby_mode: "Standby Mode",
  bypass_mode: "Bypass Mode",
  online_mode: "Online Mode",
  battery_mode: "Battery Mode",
  battery_test_mode: "Battery Test Mode",
  failure_mode: "Failure Mode",
  eco_mode: "Eco Mode",
  frequency_conversion_mode: "Frequency Conversion Mode",
  shutdown_mode: "Shutdown Mode",

  // Smoke Sensor
  smoke_sensor: "Smoke Sensor",
  smoke: "Smoke",
  // DTC
  smoke_detector: "Smoke Detector",

  // PDU
  l1_power_factor: "L1 Power Factor",
  l2_power_factor: "L2 Power Factor",
  l3_power_factor: "L3 Power Factor",
  temperature_1: "Temperature 1",
  temperature_2: "Temperature 2",
  temperature_3: "Temperature 3",
  l1_current: "L1 Current",
  l2_current: "L2 Current",
  l3_current: "L3 Current",
  l1_energy: "L1 Energy",
  l2_energy: "L2 Energy",
  l3_energy: "L3 Energy",
  l1_voltage: "L1 Voltage",
  l2_voltage: "L2 Voltage",
  l3_voltage: "L3 Voltage",
  humidity_1: "Humidity 1",
  humidity_2: "Humidity 2",
  l1_power: "L1 Power",
  l2_power: "L2 Power",
  l3_power: "L3 Power",
  total_energy: "Total Energy",
  "1#current": "1# Current",
  "2#current": "2# Current",
  "1#voltage": "1# Voltage",
  "2#voltage": "2# Voltage",
  "1#energy": "1# Energy",
  "2#energy": "2# Energy",
  "1#temperature": "1# Temperature",
  "2#temperature": "2# Temperature",
  "1#humidity": "1# humidity",
  "2#humidity": "2# humidity",
  current: "Current",
  voltage: "Voltage",
  energy: "Energy",
  // DTC
  power_frequency: "Power Frequency",
  phase_a_power: "Phase A Power",
  phase_b_power: "Phase B Power",
  phase_c_power: "Phase C Power",
  phase_a_current: "Phase A Current",
  phase_b_current: "Phase B Current",
  phase_c_current: "Phase C Current",

  // Bluenet PDU
  input_phase1_activePower: "Input Phase 1 Active Power",
  input_phase2_activePower: "Input Phase 2 Active Power",
  input_phase3_activePower: "Input Phase 3 Active Power",
  input_phase1_current: "Input Phase 1 Current",
  input_phase2_current: "Input Phase 2 Current",
  input_phase3_current: "Input Phase 3 Current",
  input_phase1_voltage: "Input Phase 1 Voltage",
  input_phase2_voltage: "Input Phase 2 Voltage",
  input_phase3_voltage: "Input Phase 3 Voltage",
  output_outlet1_activePower: "Output Outlet 1 Active Power",
  output_outlet2_activePower: "Output Outlet 2 Active Power",
  output_outlet3_activePower: "Output Outlet 3 Active Power",
  output_outlet4_activePower: "Output Outlet 4 Active Power",
  output_outlet1_current: "Output Outlet 1 Current",
  output_outlet2_current: "Output Outlet 2 Current",
  output_outlet3_current: "Output Outlet 3 Current",
  output_outlet4_current: "Output Outlet 4 Current",
  output_outlet1_voltage: "Output Outlet 1 Voltage",
  output_outlet2_voltage: "Output Outlet 2 Voltage",
  output_outlet3_voltage: "Output Outlet 3 Voltage",
  output_outlet4_voltage: "Output Outlet 4 Voltage",

  // Temp & Humidity
  emergency_fan_start_temp: "Emergency Fan Start Temperature",
  emergency_fan_stop_temp: "Emergency Fan Stop Temperature",
  low_humidity_threshold: "Low Humidity Threshold",
  high_humidity_threshold: "High Humidity Threshold",
  low_temperature_threshold: "Low Temperature Threshold",
  high_temperature_threshold: "High Temperature Threshold",

  // Water Leakage
  water_leakage: "Water Leakage",

  // BLE
  battery: "Battery",

  // Values
  open: "Open",
  close: "Close",
  normal: "Normal",
  alarm: "Alarm",
  on: "On",
  off: "Off",
  stop: "Stop",
  cooling_and_dehumidification: "Cooling and Dehumidification",
  cooling_and_humidification: "Cooling and Humidification",
  heating_and_dehumidification: "Heating and Dehumidification",
  heating_and_humidification: "Heating and Humidification",
  cooling: "Cooling",
  heating: "Heating",
  humidification: "Humidification",
  dehumidification: "Dehumidification",
  air_supply: "Air Supply",
  online: "Online",
  offline: "Offline",
  ok: "Normal",
  low: "Low",
  true: "Yes",
  false: "No",

  // Set Parameter
  set_min_fan_speed: "Min Fan Speed",
  set_air_return_temperature: "Air Return Temperature",
  set_max_humidity: "Max Humidity",
  set_high_air_return_temperature_alarmValue:
    "High Air Return Temperature Alarm Value",
  set_max_fan_speed: "Max Fan Speed",
  set_air_outlet_temperature: "Air Outlet Temperature",
  set_low_air_return_temperature_alarmValue:
    "Low Air Return Temperature Alarm Value",
  set_high_air_outlet_temperature_alarmValue:
    "High Air Outlet Temperature Alarm Value",
  set_min_humidity: "Min Humidity",
  set_modbus_unit: "Modbus Unit",
  set_baudrate: "Baudrate",

  // Actions
  command_sent: "Command sent",
  command_sent_with_delay:
    "Command sent. Settings might take some time to be reflected.",
  confirm_restart:
    "Are you sure you want to restart device? This action cannot be undone.",
  firmware_upload_success: "Firmware file downloaded successfully",
  drag_and_drop_or: "Drag and Drop to upload or",
  choose_file: "Choose File",
  max_file_size: "Maximum file size 100MB",
  upload: "Upload",
  add_new_rule: "Add New Rule",
  update_rule: "Update Alarm Rule",
  enter_alarm_name: "Enter Alarm Name",
  email_address: "Email Address",
  set_attribute: "Set Attribute",
  set_condition: "Set Condition",
  enter_value: "Enter Value",
  select_criticality: "Select Criticality Level",
  value_tooltip:
    "Set value in this box to finish the condition of the rule e.g. 40 degrees celcius",
  days: "days",
  hours: "hours",
  minutes: "minutes",
  seconds: "seconds",
  invalid_ip: "Invalid IP Address",
  select_protocol: "Select Protocol",
  ipv4_address: "IPv4 Address",
  enter_ipv4_netmask: "Enter IPv4 Netmask",
  enter_ipv4_gateway: "Enter IPv4 Gateway",
  enter_ipv4_broadcast: "Enter IPv4 Broadcast",
  dhcp_server_setting: "DHCP Server Setting",
  start_ip: "Start IP",
  end_ip: "End IP",
  enter_lease_time: "Enter Lease Time",

  // Alarm Rules
  modem_temperature: "Temperature",
  modem_signal: "Signal",
  properties: "Properties",
  alarm_rule_add_success: "Alarm rule added",
  alarm_rule_update_success: "Alarm rule Updated",
  alarm_rule_delete_success: "Alarm Rule(s) Deleted",
  del_alarm_rule: "Delete Alarm Rule",

  // others
  others: "Others",
  excel_filename_devices: "Devices Report",
};

export default messages;
