import TenantIdContext from "./context/tenantIdContext";

const TenantIdProvider = (props: any) => {
  const { children, tenantId, isMasqAsSubTenant } = props;

  return (
    <TenantIdContext.Provider
      value={{
        tenantId: tenantId,
        isMasqAsSubTenant: isMasqAsSubTenant,
      }}
    >
      {children}
    </TenantIdContext.Provider>
  );
};

export default TenantIdProvider;
