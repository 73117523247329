import { useState } from "react";
import Box from "@mui/material/Box";
import BasicGoogleMap from "../BasicGoogleMap";
import BasicMapBox from "../BasicMapBox";

// first set isGoogleMapAvail to '',
// if google map fails it will set to 'false'
window.localStorage.setItem("isGoogleMapAvail", "");

const Map = (props: any) => {
  const { locations, mapProps, setSelectedLocation, height } = props;
  const [isGoogleMapAvail, setIsGoogleMapAvail] = useState(false);
  const onGoogleMapFail = () => {
    window.localStorage.setItem("isGoogleMapAvail", "false");
    setIsGoogleMapAvail(false);
  };

  return (
    // Important! Always set the container height explicitly
    <Box sx={{ display: "flex" }}>
      <div style={{ height: height ? height : "100vh", width: "100%" }}>
        {isGoogleMapAvail ? (
          <BasicGoogleMap
            locations={locations}
            mapProps={mapProps}
            setSelectedLocation={setSelectedLocation}
            onFailCallback={onGoogleMapFail}
          />
        ) : (
          <BasicMapBox
            locations={locations}
            mapProps={mapProps}
            setSelectedLocation={setSelectedLocation}
          />
        )}
      </div>
    </Box>
  );
};

export default Map;
