const messages = {
  // General
  error: "Ein Fehler ist aufgetreten",

  // Input errors
  err_input_no_value: "Bitte geben Sie einen Wert ein",

  // Google Map errors
  get_address_fail: "Die Eingabeadresse konnte nicht abgerufen werden",
  get_map_fail: "Fehler beim Abrufen der Adresse",
  map_no_coordinates: "Koordinaten können nicht ermittelt werden",

  // Validation errors
  validation_required: "Dieses Feld ist erforderlich.",
  validation_invalid_ip: "Die Adresse ist ungültig",
  validation_end_ip_smaller:
    "Die End-IP darf nicht kleiner als die Start-IP sein",
  validation_min_latitude: "Der Breitengrad darf nicht kleiner als -90 sein",
  validation_max_latitude: "Der Breitengrad darf nicht größer als 90 sein",
  validation_min_longitude: "Der Längengrad darf nicht kleiner als -180 sein",
  validation_max_longitude: "Der Längengrad darf nicht größer als 180 sein",
  validation_maxlength: "Maximale Länge überschritten",

  // Device errors
  device_delete_fail: "Fehler beim Versuch, das Gerät zu löschen",
  device_add_fail: "Fehler beim Versuch, ein Gerät hinzuzufügen",
  err_file_upload_ext:
    "Datei kann nicht verarbeitet werden. Bitte verwenden Sie ausschließlich die folgenden Dateierweiterungen: ",
  err_file_upload: "Fehler beim Versuch, die Datei hochzuladen",
  update_setting_error:
    "Fehler beim Versuch, Ihre Einstellung zu aktualisieren.",
  no_devices_of_type: "Es sind keine Geräte der ausgewählten Typen vorhanden",
  no_devices_in_site:
    "An den ausgewählten Standorten sind keine Geräte vorhanden",

  // User
  login_failed: "Fehler bei der Anmeldung",
  no_email_or_password:
    "Bitte geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein!",
  load_user_info_error:
    "Ihre Profilinformationen können nicht abgerufen werden.",
  update_user_profile_failed:
    "Ihre Informationen können nicht aktualisiert werden",
  get_users_error: "Fehler beim Abrufen der Benutzerinformationen",

  // Telemetry
  no_filters: "Kein Filter angegeben",
};

export default messages;
