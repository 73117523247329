import { useState, useContext, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DarkModeContext from "../../../providers/context/darkModeContext";
import BasicTextFieldWithoutForm from "./BasicTextFieldWithoutForm";
import { InputAdornment } from "@mui/material";
import { CalendarIcon } from "../../../images/Icons";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { customDayOfWeek } from "../../../utils/dateUtils";

const BasicDatePicker = (props) => {
  const { handleOnChange = () => {}, value = null } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();

  useEffect(() => {
    if (intl.locale) {
      let locale = intl.locale;
      // dayjs does not have fr-fr locale, convert to use fr (switzerland)
      if (locale === "fr-FR") locale = "fr-CH";
      dayjs.locale(locale);
    }
  }, [intl.locale]);

  const customTextField = (textFieldProps) => {
    return (
      <BasicTextFieldWithoutForm
        label={textFieldProps.label}
        type={"text"}
        variant={"outlined"}
        InputProps={{
          ...textFieldProps.InputProps,
          endAdornment: (
            <InputAdornment
              position="start"
              component="div"
              style={{ paddingLeft: "-14px" }}
              disablePointerEvents
            >
              <CalendarIcon fill={colors.iconColor} />
            </InputAdornment>
          ),
        }}
        inputProps={{ ...textFieldProps.inputProps }}
        onClick={(e) => {
          setIsPopupOpen(!isPopupOpen);
        }}
        value={value ? value.format("DD/MM/YYYY") : ""}
        placeholder={"DD/MM/YYYY"}
      />
    );
  };

  const interimOnChange = (newValue) => {
    setIsPopupOpen(false);
    handleOnChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={isPopupOpen}
        closeOnSelect={true}
        value={value}
        onChange={(newValue) => interimOnChange(newValue)}
        dayOfWeekFormatter={(_day, weekday) => customDayOfWeek(_day, weekday)}
        slots={{
          textField: (textFieldProps) => customTextField(textFieldProps),
        }}
        slotProps={{
          popper: {
            sx: {
              "&& .MuiPaper-root": {
                backgroundColor: colors.dateRangeBg,
                color: colors.iconColor,
              },
              "&& .MuiButtonBase-root": {
                color: colors.iconColor,
              },
              "&& .MuiDayCalendar-weekDayLabel": {
                color: colors.fontTitle2,
              },
              "&& .MuiPickersDay-today": {
                border: "1px solid " + colors.dateRangeTodayBorder,
              },
              "&&& .Mui-selected": {
                backgroundColor: colors.datwylerTeal,
                ":hover": {
                  backgroundColor: colors.datwylerTeal,
                },
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default BasicDatePicker;
