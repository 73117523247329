const messages = {
  tenant: "Mieter",
  add_tenant: "Mieter hinzufügen",
  update_tenant: "Mieter aktualisieren",
  deactivate_tenant: "Mieter deaktivieren",

  // Dialogs
  enter_tenant_name: "Geben Sie den Mieternamen ein",
  num_users: "Anzahl der Nutzer",
  num_sites: "Anzahl der Standorte",
  num_devices: "Anzahl der Geräte",

  // Filters
  filter_by_status: "Nach Status filtern",

  // Table
  number_of_licenses: "Anzahl der Lizenzen",
  excel_filename_tenant: "Mieterbericht",
  excel_filename_license: "Lizenzbericht",

  // Sucess messages
  create_new_tenant_success: "Neuer Mandant erstellt",
  tenant_deactivated_success: "Mieter deaktiviert",
  tenant_updated_success: "Mieter aktualisiert",
  create_license_success: "Lizenz erstellt",
  delete_license_success: "Lizenz gelöscht",
  update_license_success: "Lizenz aktualisiert",

  // License
  license: "Lizenz",
  new_license: "Neue Lizenz",
  add_new_license: "Neue Lizenz hinzufügen",
  expiry_date_btw: "Ablaufdatum zwischen",
  update_license: "Lizenz aktualisieren",
  delete_license: "Lizenz löschen",
  license_number: "Amtliches Kennzeichen",
  curr_user_usage: "Aktuelle Benutzernutzung",
  curr_site_usage: "Aktuelle Site-Nutzung",
  curr_device_usage: "Aktuelle Gerätenutzung",
  license_information: "Lizenzinformationen",

  // errors
  license_already_exist:
    "Lizenz existiert bereits. Bitte aktualisieren Sie die aktuelle Lizenz oder löschen Sie sie, bevor Sie sie hinzufügen.",
};

export default messages;
