import { IntlProvider } from "react-intl";
import { messages } from "./translations";
import { LOCALES } from "./locales";

const TranslationProvider = (props: any) => {
  const { children, locale } = props;

  const onIntlError = (err) => {
    //console.log('err', err.message);
  };

  return (
    <IntlProvider
      key={locale}
      messages={messages[locale]}
      locale={locale}
      defaultLocale={LOCALES.ENGLISH}
      onError={onIntlError}
    >
      {children}
    </IntlProvider>
  );
};

export default TranslationProvider;
