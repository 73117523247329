const messages = {
  add_new: "Neue hinzufügen",
  select_country: "Land auswählen",

  // Add Edit Location
  add_location: "Ort hinzufügen",
  enter_location_name: "Geben Sie den Standortnamen ein",
  enter_longitude: "Geben Sie den Längengrad ein",
  enter_latitude: "Geben Sie Latitude ein",
  enter_site_name: "Geben Sie den Site-Namen ein",
  enter_ip_address: "Geben Sie die IP-Adresse ein",
  add_a_site: "Fügen Sie eine Site hinzu",
  confirm_delete_location:
    "Sind Sie sicher, dass Sie diesen Standort löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  delete_location: "Standort löschen",
  save_changes: "Änderungen speichern",
  create_new: "Erstelle neu",
  add_location_success: "Neuer Standort erstellt",
  url: "URL",

  // Location Details
  coordinates: "Koordinaten",
  longitude: "Längengrad",
  latitude: "Breite",
  number_of_devices: "Anzahl der Geräte",
  view: "Sicht",

  // Errors
  error_unable_delete:
    "Löschen nicht möglich. An diesem Standort/Standort sind ein oder mehrere Geräte vorhanden",

  // Attachments
  attachments: "Dateianlagen",
  Attachments: "Dateianlagen",
  attachment_name: "Dateiname",
  add_attachment: "Dateianhang hinzufügen",
  select_format: "Datei Format",
  download: "Herunterladen",
  format: "Datei Format",
  upload_success: "Erfolgreich hochgeladen",
  attachment_info: "Anhangsinformationen",
  size: "Dateigröße",
  uploaded_by: "Hochgeladen von",
  uploaded_on: "Hochgeladenes Datum",
};

export default messages;
