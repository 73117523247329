const messages = {
  tenant: "Tenant",
  add_tenant: "Add Tenant",
  update_tenant: "Update Tenant",
  deactivate_tenant: "Deactivate Tenant",

  // Dialogs
  enter_tenant_name: "Enter Tenant Name",
  num_users: "Number of Users",
  num_sites: "Number of Sites",
  num_devices: "Number of Devices",

  // Filters
  filter_by_status: "Filter By Status",

  // Table
  number_of_licenses: "Number of Licenses",
  excel_filename_tenant: "Tenants Report",
  excel_filename_license: "Licenses Report",

  // Sucess messages
  create_new_tenant_success: "New Tenant Created",
  tenant_deactivated_success: "Tenant Deactivated",
  tenant_updated_success: "Tenant Updated",
  create_license_success: "License Created",
  delete_license_success: "License Deleted",
  update_license_success: "License Updated",

  // License
  license: "License",
  new_license: "New License",
  add_new_license: "Add New License",
  expiry_date_btw: "Expiry date between",
  update_license: "Update License",
  delete_license: "Delete License",
  license_number: "License number",
  curr_user_usage: "Current User Usage",
  curr_site_usage: "Current Site Usage",
  curr_device_usage: "Current Device Usage",
  license_information: "License Information",

  // errors
  license_already_exist:
    "License already exists. Please update the current license or delete before adding.",
};

export default messages;
