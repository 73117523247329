const messages = {
  Analytics: "Analytics",
  menu_analytics: "Analytics",
  add_trend_model: "New Model",
  forecast_model: "Forecast Model",
  date_created_between: "Date Created Between",
  model_name: "Model Name",

  ForecastDetails: "Forecast Details",
  ForecastSetup: "New Model",
  forecast_setup: "Forecast Setup",
  source_configuration: "Source Configuration",
  forecast_model_name: "Forecast Model Name",
  input_name: "Input name",
  parameter_name: "Parameter Name",
  no_data: "No Data Available",
  training_data: "Training Data",
  actual_data: "Actual Data",
  predicted_data: "Predicted Data",
  anomaly_detection_option: "Anomaly Detection Option",
  sampling_frequency: "Sampling Frequency",
  training_date: "Training Date",
  configuration: "Configuration",
  prediction_horizon: "Prediction Horizon",
  lookback_period: "Lookback Period",
  autotune_model: "Autotune Model",
  forecasted_data: "Forecasted Data",
  previous_prediction_select: "Previous prediction On",
  forecast_option: "Forecast Option",
  prediction_performance: "Prediction Performance",
  training_performance: "Training Performance",
  submit: "Submit",
  one_min: "1 min",
  five_min: "5 min",
  ten_min: "10 min",
  thirty_min: "30 min",
  one_hour: "1 hour",
  two_hours: "2 hours",
  idle: "Idle",
  training: "Training",
  predicting: "Predicting",
  queue: "Queue",
  finished: "Finished",
  failed: "Failed",
  revoked: "Revoked",
  rejected: "Rejected",
  analyzing: "Analyzing",

  retrain_dialog_title: "Retrain",
  retrain_dialog_body:
    "Are you sure you want to retrain device? This can not be undo.",
  retrain_dialog_cancel: "Cancel",
  retrain_dialog_confirm: "Retrain",
  train_model_success_msg: "Train Model Task Initiated.",
  predict_model_success_msg: "Predict Model Task Initiated.",
  model_success_msg: "Model successfully created.",
};

export default messages;
