import { ServerError } from "@apollo/client";
import { redirectToLogin } from "../utils/authUtils";

export const handleError = (
  error,
  graphql_uri,
  enqueueSnackbar = null,
  intl
) => {
  const { networkError, graphQLErrors } = error;
  if (networkError) {
    const serverError: ServerError = networkError as ServerError;
    if (serverError?.statusCode === 401) {
      const basePath = graphql_uri.substring(
        0,
        graphql_uri.indexOf("/graphql")
      );
      redirectToLogin(basePath);
    }
  } else if (graphQLErrors) {
    graphQLErrors.map((graphQLError) => {
      enqueueSnackbar(
        intl.formatMessage({ id: "error" }) + ": " + graphQLError.message,
        {
          variant: "error",
        }
      );
    });
  }
};
