const messages = {
  // Widgets
  add_widget: "Widget hinzufügen",
  critical_alarm: "Kritischer Alarm",
  water_leak: "Wasserleck",
  pdu_alarm: "PDU-Alarm",
  online_devices: "Online-Geräte",
  door_alarm: "Türalarm",
  smoke_alarm: "Rauchmelder",
  select_alert_type: "Wählen Sie den Alarmtyp aus*",
  delete_widget: "Widget löschen",
  delete_widget_message:
    "Das Löschen dieses Widgets wirkt sich auf alle anderen Benutzer aus. Sind Sie sicher, dass Sie löschen möchten?",
  add_widget_success:
    "Neues Widget hinzugefügt. Dies wirkt sich auf die Bildschirme aller anderen Benutzer aus.",
  delete_widget_success:
    "Einstellungen aktualisiert. Dies wirkt sich auf die Bildschirme aller anderen Benutzer aus.",

  // Geographic Map
  geographic_map: "Geografische Karte",

  // UPS Device
  ups_device: "USV-Gerät",
  no_alarm: "Kein Alarm",

  // Cooling Unit
  cooling_unit: "Kühleinheit",

  // Temperature and Humidity
  temp_humidity_chart_label: "Temperatur und Luftfeuchtigkeit",
  temp_humidity_chart_y_label: "Temperatur [°C]",
  temp_humidity_chart_y2_label: "Luftfeuchtigkeit",
  temperature: "Temperatur",
  humidity: "Luftfeuchtigkeit",

  // Error Messages
  error_widget_exists: "Widget existiert bereits",
  error_save_widget: "Widget kann nicht gespeichert werden",
};

export default messages;
