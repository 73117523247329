import { useEffect, useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const SET_DEVICE_PARAMETER = gql`
  mutation setDeviceParams($input: SetDeviceParamsInput) {
    setDeviceParams(input: $input) {
      tenantId
      deviceId
      status
    }
  }
`;

const useDeviceActions = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { uri }: any = useContext(graphQLContext);

  const [
    setDeviceParams,
    {
      loading: isSetDeviceParamsLoading,
      error: setDeviceParamsError,
      data: setDeviceParamsResponseData,
    },
  ] = useMutation(SET_DEVICE_PARAMETER);

  useEffect(() => {
    if (setDeviceParamsError) {
      handleError(setDeviceParamsError, uri, enqueueSnackbar, intl);
    }
  }, [setDeviceParamsError]);

  return {
    setDeviceParams,
    isSetDeviceParamsLoading,
    setDeviceParamsError,
    setDeviceParamsResponseData,
  };
};

export default useDeviceActions;
