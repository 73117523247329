import { useEffect, useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { GET_DEVICE_BY_ID } from "./useDevice";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const RESTART_DEVICE = gql`
  mutation restartDevice($input: RestartDeviceInput!) {
    restartDevice(input: $input) {
      tenantId
      deviceId
      status
    }
  }
`;

const UPDATE_LAN_SETTING = gql`
  mutation updateLanSetting($input: UpdateLanSettingInput!) {
    updateLanSetting(input: $input) {
      tenantId
      deviceId
      status
    }
  }
`;

const useGatewayActions = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [
    restartDevice,
    {
      loading: isRestartDeviceLoading,
      error: restartDeviceError,
      data: restartDeviceResponseData,
    },
  ] = useMutation(RESTART_DEVICE);

  const [
    updateLanSetting,
    {
      loading: isUpdateLanSettingLoading,
      error: updateLanSettingError,
      data: updateLanSettingResponseData,
    },
  ] = useMutation(UPDATE_LAN_SETTING, {
    // Then re-run fetch
    refetchQueries: [GET_DEVICE_BY_ID],
  });

  useEffect(() => {
    if (restartDeviceError || updateLanSettingError) {
      const error = restartDeviceError || updateLanSettingError;
      handleError(error, uri, enqueueSnackbar, intl);
    }
  }, [restartDeviceError, updateLanSettingError]);

  return {
    restartDevice,
    isRestartDeviceLoading,
    restartDeviceError,
    restartDeviceResponseData,
    updateLanSetting,
    isUpdateLanSettingLoading,
    updateLanSettingError,
    updateLanSettingResponseData,
  };
};

export default useGatewayActions;
