import { useState, useEffect, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import BasicCheckBox from "./BasicCheckBox";
import { useIntl } from "react-intl";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ArrowRight } from "../../../images/Icons";
import { getScrollBarSx } from "../../../utils/scrollbarUtils";
import {
  menuItemsSort,
  basicSelectDisplayPropsStyle,
  getBasicSelectMenuProps,
  getBasicSelectSx,
} from "../utils";
import DarkModeContext from "../../../providers/context/darkModeContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BasicSelectWithCheckBox = (props) => {
  const {
    menuItems,
    isDisabled,
    error,
    label,
    values = [],
    onChange,
    isLoading,
    menuPaperSx,
    menuPropsSx,
    SelectDisplayProps = {
      display: "flex",
      alignItems: "end",
      minWidth: 300,
    },
  } = props;
  const [sortedMenuItem, setSortedMenuItem] = useState(menuItems);
  const [isDialogOpen, setIsMenuOpen] = useState(false);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    setSortedMenuItem(menuItemsSort(menuItems));
  }, [menuItems]);

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  const loadingIcon = (colors) => (
    <Box>
      <CircularProgress
        size={20}
        sx={{ color: colors.datwylerTeal, marginRight: "16px" }}
      />
    </Box>
  );

  const ArrowDropDownIcon = () => (
    <Box
      sx={{
        transform: isDialogOpen ? "rotate(-90deg)" : "rotate(90deg)",
        position: "absolute",
        right: "16px",
      }}
    >
      <ArrowRight />
    </Box>
  );

  return (
    <FormControl fullWidth disabled={isDisabled} error={error} size="small">
      <Select
        multiple
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return intl.formatMessage({ id: label });
          } else {
            return (
              intl.formatMessage({ id: label }) + " (" + selected.length + ")"
            );
          }
        }}
        onOpen={() => {
          setIsMenuOpen(true);
        }}
        onClose={() => {
          setIsMenuOpen(false);
        }}
        MenuProps={{
          ...MenuProps,
          ...getBasicSelectMenuProps(
            getScrollBarSx(colors),
            menuPaperSx,
            menuPropsSx,
            colors
          ),
        }}
        SelectDisplayProps={{
          style: {
            ...basicSelectDisplayPropsStyle,
            ...SelectDisplayProps,
          },
        }}
        labelId={label}
        id={"select-with-checkbox-" + label}
        value={values}
        onChange={handleChange}
        IconComponent={isLoading ? loadingIcon : ArrowDropDownIcon}
        sx={getBasicSelectSx(colors)}
      >
        {sortedMenuItem.map((menuItem) => (
          <MenuItem key={menuItem.label} value={menuItem.value}>
            <ListItemText primary={menuItem.label} />
            <BasicCheckBox checked={values.indexOf(menuItem.value) > -1} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BasicSelectWithCheckBox;
