import { useEffect, useState, useContext } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { ArrowRight, CheckBlackIcon } from "../../../images/Icons";
import { getScrollBarSx } from "../../../utils/scrollbarUtils";
import CircularProgress from "@mui/material/CircularProgress";
import {
  menuItemsSort,
  basicSelectDisplayPropsStyle,
  getBasicSelectMenuProps,
  getBasicSelectSx,
} from "../utils";
import DarkModeContext from "../../../providers/context/darkModeContext";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const BasicSelectWithoutForm = (props) => {
  const {
    menuItems,
    label = undefined,
    value,
    onChange = () => {},
    onFocus = () => {},
    isDisableMenuTranslations,
    isDisabled,
    error,
    menuItemWidth = "360px",
    SelectDisplayProps = {
      height: "27px",
      display: "flex",
      alignItems: "end",
      minWidth: 300,
    },
    menuPropsSx = {},
    inputLabelSx = {},
    menuPaperSx = {},
    isLoading = false,
    labelComponent = null,
    variant = "outlined",
  } = props;

  // menuPaperSx: sx props for menu paper
  // MenuPropsSx: general sx props for menu

  const intl = useIntl();
  const [sortedMenuItem, setSortedMenuItem] = useState(menuItems);
  const [isFocused, setIsFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const { colors }: any = useContext(DarkModeContext);
  const [isDialogOpen, setIsMenuOpen] = useState(false);

  const basicSelectInputLabelStyle = {
    fontFamily: "NotoSans-Regular",
    color: isHover ? colors.datwylerTeal : colors.selectLabelFont,
    "&&.Mui-focused": {
      color: colors.selectLabelFontFocused,
    },
  };

  useEffect(() => {
    setSortedMenuItem(menuItemsSort(menuItems));
  }, [menuItems]);

  const getMenuLabels = (label) => {
    return isDisableMenuTranslations
      ? label
      : intl.formatMessage({ id: label });
  };

  const loadingIcon = () => (
    <Box>
      <CircularProgress
        size={20}
        sx={{ color: colors.datwylerTeal, marginRight: "16px" }}
      />
    </Box>
  );

  const ArrowDropDownIcon = () => (
    <Box
      sx={{
        transform: isDialogOpen ? "rotate(-90deg)" : "rotate(90deg)",
        position: "absolute",
        right: "16px",
      }}
    >
      <ArrowRight />
    </Box>
  );

  const renderLabel = () => {
    if (label) {
      if (labelComponent && !isFocused) {
        return (
          <InputLabel
            id={label}
            sx={{
              fontFamily: "NotoSans-Regular",
              "&&.Mui-focused": { color: colors.datwylerTeal, width: "200px" },
              ...inputLabelSx,
            }}
          >
            {labelComponent}
          </InputLabel>
        );
      } else {
        return (
          <InputLabel
            id={label}
            sx={{
              ...basicSelectInputLabelStyle,
              ...inputLabelSx,
            }}
          >
            {intl.formatMessage({ id: label })}
          </InputLabel>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <FormControl
      fullWidth
      disabled={isDisabled}
      error={error}
      size="small"
      variant={variant}
    >
      {renderLabel()}
      <ClickAwayListener
        onClickAway={(e) => {
          setIsHover(false);
        }}
      >
        <Select
          onOpen={() => {
            setIsMenuOpen(true);
          }}
          onClose={() => {
            setIsMenuOpen(false);
          }}
          onFocus={() => {
            setIsFocused(true);
            onFocus();
          }}
          onBlur={() => setIsFocused(false)}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          labelId={label}
          id={"select-" + label}
          value={value ? value : ""}
          label={
            (isFocused || !!value) && !!label
              ? intl.formatMessage({ id: label })
              : null
          }
          onChange={onChange}
          displayEmpty={true}
          IconComponent={isLoading ? loadingIcon : ArrowDropDownIcon}
          sx={getBasicSelectSx(colors)}
          SelectDisplayProps={{
            style: {
              ...basicSelectDisplayPropsStyle,
              ...SelectDisplayProps,
            },
          }}
          MenuProps={getBasicSelectMenuProps(
            getScrollBarSx(colors),
            menuPaperSx,
            menuPropsSx,
            colors
          )}
          renderValue={(value) => {
            const item = menuItems.find((item) => {
              return item.value === value;
            });

            if (item) {
              return (
                <Box
                  sx={{
                    fontFamily: "NotoSans-Regular",
                    fontSize: "14px",
                    fontWeight: 500,
                    letterSpacing: 0,
                    lineHeight: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {getMenuLabels(item.label)}
                  </Typography>
                </Box>
              );
            }
          }}
        >
          {sortedMenuItem.map((menuItem, index) => {
            const menuItemLabel = getMenuLabels(menuItem.label);
            const isSelected = menuItem.value === value;
            if (!menuItem.isHide) {
              return (
                <MenuItem
                  id={menuItem.label}
                  disabled={menuItem.isDisabled}
                  key={menuItem.label + "_" + index}
                  value={menuItem.value}
                  sx={{
                    padding: "2px 0px",
                    "&:focus-visible": {
                      display: "none",
                    },
                    fontFamily: "NotoSans-Regular",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: isSelected ? colors.natural100 : "unset",
                      width: menuItemWidth,
                      minWidth: "120px",
                      margin: "0px 8px",
                      borderRadius: "8px",
                      paddingLeft: "16px",
                      paddingTop: "8px",
                      paddingBottom: "16px",
                      height: "16px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: isSelected
                          ? "NotoSans-SemiBold"
                          : "NotoSans-Regular",
                        color: isSelected
                          ? colors.selectWithoutFormFontSelected
                          : colors.selectWithoutFormFont,
                      }}
                    >
                      {menuItemLabel}
                    </Typography>
                    {isSelected && (
                      <Box
                        style={{
                          position: "absolute",
                          right: "16px",
                          top: "8px",
                        }}
                      >
                        <CheckBlackIcon fill={"black"} />
                      </Box>
                    )}
                  </Box>
                </MenuItem>
              );
            }
          })}
        </Select>
      </ClickAwayListener>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};

export default BasicSelectWithoutForm;
