// Theme Colors
const colorGuide = {
  white: "#FFFFFF",
  black: "#1c1c1c",

  // Brand
  brand50: "#E1F8FC",
  brand100: "#B4EEF8",
  brand200: "#83E3F3",
  brand300: "#50D7ED",
  brand500: "#50D7ED",
  brandRed900: "#c40000",

  // BlueGray
  blueGray50: "#F9FAFB",
  blueGray100: "#F2F4F7",
  blueGray200: "#EAECF0",
  blueGray300: "#D0D5DD",
  blueGray400: "#98A2B3",
  blueGray500: "#667085",
  blueGray600: "#475467",
  blueGray700: "#344054",
  blueGray800: "#1D2939",
  blueGray900: "#101828",

  // Material UI
  blueGrayM400: "#78909C",

  // Natural Gray
  natural50: "#FAFAFA",
  natural100: "#F2F4F7",
  natural200: "#EEEEEE",
  natural300: "#E0E0E0",
  natural400: "#BDBDBD",
  natural500: "#9E9E9E",
  natural600: "#757575",
  natural700: "#616161",
  natural800: "#424242",
  natural900: "#212121",

  // Red
  red50: "#FFEBEE",
  red400: "#EF5350",
  red500: "#F44336",
  red700: "#d32f2f",
  red800: "#C62828",

  // Green
  green50: "#E8F5E9",
  green500: "#4CAF50",
  green600: "#43A047",
  green900: "#1B5E20",

  // Gray
  gray500: "#9E9E9E",

  // Blue
  blue50: "#E1F5FE",

  // lightBlue
  lightBlue50: "#E1F5FE",
  lightBlue500: "#03A9F4",
  lightBlue900: "#01579B",

  // Yellow
  yellow100: "#FFF9C4",
  yellow500: "#FFEB3B",

  // Purple
  purple50: "#F9F5FF",
  purple600: "#7F56D9",

  // Teal
  teal50: "#E0F2F1",
  teal600: "#00897B",

  // not in guide
  datwylerTeal: "#009FB4",
  backgroundGray: "#F8F8FA",
  navbarSelected: "rgba(170,172,183,0.2)",
  navBarArrow: "#AAACB7",
  chartBlue: "#36BFFA",
  chartRed: "#F97066",
  bulletNone: "#4185F4",
  bulletNotCritical: "#FFC107",
  bulletCritical: "#FB8C00",
  bulletExtremeCritical: "#F44336",
  snackBarInfoIconBg: "#0BA5EC",
  chipBgOrange: "#FFF8E1",
  chipFontOrange: "#FF8F00",
  chipBgBlue: "#E5FCFF",
  chipFontBlue: "#007D8F",

  // Home page widgets
  homeIconBgMemory1: "#F0F9FF",
  homeIconBgDoor: "#FEF6EE",
  homeIconBgSmoke: "#FEFBE8",
  homeIconBgWaterDrop: "#E5FCFF",
  homeIconBgMemory2: "#F0FDF9",
  homeIconBgNotification: "#FEF3F2",
  memory1Icon: "#0BA5EC",
  doorIcon: "#EF6820",
  smokeIcon: "#EAAA08",
  waterDropIcon: "#39B6C7",
  memory2Icon: "#15B79E",
  notificationIcon: "#F04438",
};

export const colors = {
  light: {
    fontTitle: colorGuide.blueGray800,
    fontTitle2: colorGuide.blueGray900,
    subTitle: colorGuide.blueGray500,

    // UI Components
    cardBg: colorGuide.white,
    dividerColor: "#0000001f",
    scrollBarTrack: colorGuide.natural100,

    // Bullets
    alertBulletNone: colorGuide.bulletNone,
    alertBulletNotCritical: colorGuide.bulletNotCritical,
    alertBulletCritical: colorGuide.bulletCritical,
    alertBulletExtremeCritical: colorGuide.bulletExtremeCritical,

    // Screen Headers
    screenHeaderTitle: colorGuide.blueGray800,

    // Textfield
    textFieldLabel: colorGuide.blueGray600,
    textFieldLabelFocused: "#000000de",
    textFieldBorderFocused: colorGuide.datwylerTeal,
    textFieldBorderFocusedHover: colorGuide.datwylerTeal,
    textFieldLabelHover: colorGuide.datwylerTeal,
    textFieldBorderHover: colorGuide.datwylerTeal,
    textFieldBorder: "#0000003b",
    validationErrorRed: colorGuide.red700,
    formHelperText: "#00000099",
    textFieldUnderlineFocused: colorGuide.datwylerTeal,
    textFieldLabelStandard: colorGuide.blueGray800,

    // Select
    selectFont: colorGuide.blueGray600,
    selectLabelFont: colorGuide.blueGray600,
    selectLabelFontFocused: colorGuide.datwylerTeal,
    selectOutline: colorGuide.blueGray300,
    selectDropDownBg: colorGuide.white,
    selectDropDownBgSelected: colorGuide.white,
    selectDropDownBgActive: colorGuide.white,
    selectDropDownFont: colorGuide.black,
    selectDropDownFontSelected: colorGuide.black,
    selectDropDownArrow: colorGuide.black,
    selectWithoutFormFont: colorGuide.black,
    selectWithoutFormFontSelected: colorGuide.black,
    selectStandardUnderline: colorGuide.blueGray600,
    selectStandardUnderlineFocused: colorGuide.datwylerTeal,

    // Buttons
    buttonFont: colorGuide.blueGray500,
    buttonFontContained: colorGuide.white,
    buttonBg: colorGuide.white,
    buttonBgContained: colorGuide.datwylerTeal,
    disabledButtonBg: colorGuide.blueGray300,
    disabledButtonFont: colorGuide.blueGray100,
    buttonOutline: colorGuide.blueGray300,
    buttonOnHoverBg: colorGuide.white,
    buttonOnHoverBgContained: colorGuide.datwylerTeal,
    buttonOnHoverFont: colorGuide.datwylerTeal,
    buttonOnHoverFontContained: colorGuide.white,
    iconColor: colorGuide.blueGray500,

    // navbar
    navBarBg: colorGuide.white,
    navBarFab: colorGuide.backgroundGray,
    navBarFabHover: colorGuide.natural100,
    contentBg: colorGuide.backgroundGray,
    navBarFont: colorGuide.blueGray400,
    navBarFontSelected: colorGuide.blueGray600,
    navbarSelected: colorGuide.navbarSelected,
    navBarIcon: colorGuide.blueGray400,
    navBarIconSelected: colorGuide.blueGray600,
    versionNumber: colorGuide.blueGray400,

    // Home Screen
    addWidgetBtnBg: colorGuide.white,
    addWidgetBtnBgHover: "#0000000a",
    addWidgetBtnBorder: colorGuide.blueGray300,
    arrowButtonBg: colorGuide.blueGray200 + "bf",
    arrowButtonBgHover: colorGuide.blueGray200,
    widgetBg: colorGuide.white,
    widgetFont: colorGuide.blueGray600,
    widgetTableTitle: colorGuide.blueGray800,
    widgetTableHeader: colorGuide.blueGray400,
    widgetTableFont: colorGuide.blueGray600,
    widgetTableFontSite: colorGuide.blueGray500,
    geographicMapTitle: colorGuide.blueGray900,
    tempHumChartTitle: colorGuide.blueGray900,
    chartBlue: colorGuide.chartBlue,
    chartRed: colorGuide.chartRed,
    chartAxisTick: "#777777",
    chartAxisFont: "#333333",

    // Sites
    sideMenuBg: "#ffffff99",
    sideMenuTitle: colorGuide.blueGray800,
    sideMenuFont: colorGuide.blueGray500,
    locationDetailsSiteBtnBg: colorGuide.blueGray100,
    tooltipBg: colorGuide.natural800,
    tooltipBg2: colorGuide.blueGray500,
    tooltipFont: colorGuide.white,
    siteDetailsFont1: colorGuide.blueGray400,
    siteDetailsFont2: colorGuide.blueGray600,
    siteDetailsTabFont: colorGuide.blueGray400,
    siteDetailsDetailTabLink: colorGuide.blueGray400,
    siteDetailsDeviceName: colorGuide.blueGray700,
    siteListBg: colorGuide.blueGray100,
    siteListTitle: colorGuide.blueGray600,
    siteErrorIcon: colorGuide.red700,

    // Device-Monitoring
    layoutBtnSelected: colorGuide.blueGray200,
    layoutBtnBorder: colorGuide.blueGray300,
    layoutBtnHover: colorGuide.natural800,
    envComponentBg: colorGuide.blueGray100,
    deviceCardBg: colorGuide.white,
    deviceCardBorder: colorGuide.blueGray200,
    deviceCardHeaderFont: colorGuide.blueGray900,
    deviceCardFont: colorGuide.blueGray900,

    // Device-Details
    siteMenuBg: colorGuide.white,
    siteMenuBackFont: colorGuide.blueGray600,
    siteMenuSelected: colorGuide.blueGray200,
    siteMenuFont: colorGuide.black,
    siteMenuSelectedFont: colorGuide.blueGray700,
    siteMenuHover: colorGuide.natural100,
    deviceStatusValue: colorGuide.blueGray700,
    deviceStatusInput: colorGuide.blueGray800,
    deviceInformationImgBg1: colorGuide.blueGray100,
    deviceInformationImgBg2: colorGuide.blueGray50,
    deviceInformationFontKey: colorGuide.blueGray500,
    deviceInformationFontValue: colorGuide.blueGray600,
    updateFirmwareBgDragBg: colorGuide.blueGray50,
    updateFirmwareBgDragFont: colorGuide.blueGray800,
    updateFirmwareBgDragFont2: colorGuide.blueGray500,
    updateFirmwareBgDragBgHover: colorGuide.brand50,
    updateFirmwareFileBg: colorGuide.white,
    updateFirmwareFileIconBg: colorGuide.blueGray100,
    updateLanDefaultIPFont: colorGuide.blueGray400,
    deviceDetailTableBg: colorGuide.white,
    setParameterBorder: colorGuide.blueGray200,

    // Alarms
    reactivateAlarm: colorGuide.blueGray500,

    // BreadCrumbs
    breadCrumbsFont: colorGuide.blueGray400,
    breadCrumbsFontActive: colorGuide.blueGray600,

    // Data Table
    dataTableHeaderFont: colorGuide.blueGray500,
    dataTableHeaderBg: "none",
    dataTableBackground: colorGuide.white,
    dataTableFont: colorGuide.blueGray700,
    dataTableHover: colorGuide.lightBlue50 + "8c",

    // More Button
    moreButtonTooltipBg: colorGuide.blueGray500,
    moreButtonDots: colorGuide.blueGray400,
    moreButtonDialogBg: colorGuide.white,
    moreButtonDialogFont: colorGuide.blueGray500,
    moreButtonDialogFontHover: colorGuide.blueGray700,
    moreButtonDeactivateFont: colorGuide.red800,

    // Date Range Picker
    dateRangeHeaderBg: colorGuide.white,
    dateRangeBg: colorGuide.white,
    dateRangeTodayBorder: colorGuide.black,
    dateRangeHoverBg: colorGuide.brand50 + "cc",
    dateRangeSelectedBg: colorGuide.brand50 + "66",
    dateRangeStartDateHoverBg: colorGuide.brand50 + "cc",

    ...colorGuide,
  },
  dark: {
    fontTitle: colorGuide.white,
    fontTitle2: colorGuide.natural50,
    subTitle: colorGuide.natural300,

    // UI Components
    cardBg: colorGuide.natural800,
    dividerColor: colorGuide.white + "75",
    scrollBarTrack: colorGuide.natural700,

    // Bullets
    alertBulletNone: colorGuide.bulletNone,
    alertBulletNotCritical: colorGuide.bulletNotCritical,
    alertBulletCritical: colorGuide.bulletCritical,
    alertBulletExtremeCritical: colorGuide.bulletExtremeCritical,

    // Screen Headers
    screenHeaderTitle: colorGuide.white,

    // Textfield
    textFieldLabel: colorGuide.white,
    textFieldLabelFocused: colorGuide.white,
    textFieldBorderFocused: colorGuide.white,
    textFieldBorderFocusedHover: colorGuide.datwylerTeal,
    textFieldLabelHover: colorGuide.datwylerTeal,
    textFieldBorderHover: colorGuide.datwylerTeal,
    textFieldBorder: colorGuide.white,
    validationErrorRed: colorGuide.red400,
    formHelperText: colorGuide.white,
    textFieldUnderlineFocused: colorGuide.datwylerTeal,
    textFieldLabelStandard: colorGuide.blueGray800,

    // Select
    selectFont: colorGuide.white,
    selectLabelFont: colorGuide.white,
    selectLabelFontFocused: colorGuide.datwylerTeal,
    selectOutline: colorGuide.white,
    selectDropDownBg: colorGuide.natural800,
    selectDropDownBgSelected: colorGuide.natural800,
    selectDropDownBgActive: colorGuide.natural800,
    selectDropDownFont: colorGuide.white,
    selectDropDownFontSelected: colorGuide.white,
    selectDropDownArrow: colorGuide.white,
    selectWithoutFormFont: colorGuide.white,
    selectWithoutFormFontSelected: colorGuide.black,
    selectStandardUnderline: colorGuide.white,
    selectStandardUnderlineFocused: colorGuide.datwylerTeal,

    // Buttons
    buttonFont: colorGuide.white,
    buttonFontContained: colorGuide.white,
    buttonBg: colorGuide.natural800,
    buttonBgContained: colorGuide.datwylerTeal,
    disabledButtonBg: colorGuide.natural700,
    disabledButtonFont: colorGuide.blueGray700,
    buttonOutline: colorGuide.datwylerTeal,
    buttonOnHoverBg: colorGuide.natural800,
    buttonOnHoverBgContained: colorGuide.datwylerTeal,
    buttonOnHoverFont: colorGuide.datwylerTeal,
    buttonOnHoverFontContained: colorGuide.white,
    iconColor: colorGuide.white,

    // navbar
    navBarBg: colorGuide.blueGray800,
    navBarFab: colorGuide.natural800,
    navBarFabHover: colorGuide.natural700,
    contentBg: colorGuide.natural900,
    navBarFont: colorGuide.blueGray400,
    navBarFontSelected: colorGuide.blueGray200,
    navbarSelected: colorGuide.navbarSelected,
    navBarIcon: colorGuide.blueGray400,
    navBarIconSelected: colorGuide.blueGray200,
    versionNumber: colorGuide.white,

    // Home Screen
    addWidgetBtnBg: colorGuide.natural800,
    addWidgetBtnBgHover: "#0000000a",
    addWidgetBtnBorder: colorGuide.blueGray300,
    arrowButtonBg: colorGuide.natural800,
    arrowButtonBgHover: colorGuide.natural800 + "87",
    widgetBg: colorGuide.natural800,
    widgetFont: colorGuide.white,
    widgetTableTitle: colorGuide.white,
    widgetTableHeader: colorGuide.natural100,
    widgetTableFont: colorGuide.white,
    widgetTableFontSite: colorGuide.natural300,
    geographicMapTitle: colorGuide.white,
    tempHumChartTitle: colorGuide.white,
    chartBlue: colorGuide.chartBlue,
    chartRed: colorGuide.chartRed,
    chartAxisTick: colorGuide.white,
    chartAxisFont: colorGuide.white,

    // Sites
    sideMenuBg: colorGuide.natural600 + "99",
    sideMenuTitle: colorGuide.natural200,
    sideMenuFont: colorGuide.natural300,
    locationDetailsSiteBtnBg: colorGuide.natural700,
    tooltipBg: colorGuide.natural600,
    tooltipBg2: colorGuide.blueGray500,
    tooltipFont: colorGuide.natural50,
    siteDetailsFont1: colorGuide.natural300,
    siteDetailsFont2: colorGuide.natural50,
    siteDetailsDeviceName: colorGuide.white,
    siteDetailsTabFont: colorGuide.white,
    siteDetailsDetailTabLink: colorGuide.natural400,
    siteListBg: colorGuide.natural700,
    siteListTitle: colorGuide.natural200,
    siteErrorIcon: colorGuide.red400,

    // Device-Monitoring
    layoutBtnSelected: colorGuide.blueGray200,
    layoutBtnBorder: colorGuide.white,
    layoutBtnHover: colorGuide.blueGray200,
    envComponentBg: colorGuide.natural700,
    deviceCardBg: colorGuide.natural800,
    deviceCardBorder: colorGuide.natural800,
    deviceCardHeaderFont: colorGuide.natural50,
    deviceCardFont: colorGuide.natural200,

    // Device-Details
    siteMenuBg: colorGuide.natural800,
    siteMenuBackFont: colorGuide.white,
    siteMenuFont: colorGuide.natural100,
    siteMenuSelected: colorGuide.natural100,
    siteMenuSelectedFont: colorGuide.blueGray700,
    siteMenuHover: colorGuide.natural700,
    deviceStatusValue: colorGuide.natural100,
    deviceStatusInput: colorGuide.white,
    deviceInformationImgBg1: colorGuide.natural700,
    deviceInformationImgBg2: colorGuide.natural600,
    deviceInformationFontKey: colorGuide.natural100,
    deviceInformationFontValue: colorGuide.natural200,
    updateFirmwareBgDragBg: colorGuide.natural700,
    updateFirmwareBgDragFont: colorGuide.natural100,
    updateFirmwareBgDragFont2: colorGuide.natural50,
    updateFirmwareBgDragBgHover: colorGuide.natural600,
    updateFirmwareFileBg: colorGuide.natural700,
    updateFirmwareFileIconBg: colorGuide.natural800,
    updateLanDefaultIPFont: colorGuide.natural100,
    deviceDetailTableBg: "unset",
    setParameterBorder: colorGuide.natural600,

    // Alarms
    reactivateAlarm: colorGuide.white,

    // BreadCrumbs
    breadCrumbsFont: colorGuide.blueGray400,
    breadCrumbsFontActive: colorGuide.white,

    // Data Table
    dataTableHeaderFont: colorGuide.white,
    dataTableHeaderBg: colorGuide.natural800,
    dataTableBackground: "none",
    dataTableFont: colorGuide.white,
    dataTableHover: colorGuide.blueGray500 + "8c",

    // More Button
    moreButtonTooltipBg: colorGuide.natural600,
    moreButtonDots: colorGuide.blueGray400,
    moreButtonDialogBg: colorGuide.natural800,
    moreButtonDialogFont: colorGuide.white,
    moreButtonDialogFontHover: colorGuide.white,
    moreButtonDeactivateFont: colorGuide.red500,

    // Date Range Picker
    dateRangeHeaderBg: colorGuide.natural600,
    dateRangeBg: colorGuide.natural700,
    dateRangeTodayBorder: colorGuide.white,
    dateRangeHoverBg: colorGuide.brand50 + "cc",
    dateRangeSelectedBg: colorGuide.brand50 + "66",
    dateRangeStartDateHoverBg: "unset",

    ...colorGuide,
  },
};
