import { useEffect, useContext } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const GET_TELEMETRY = gql`
  query telemetries(
    $tenantId: ID!
    $deviceIds: [ID!]!
    $startTime: Long!
    $endTime: Long
    $page: Int
    $size: Int
    $sort: [String]
    $filter: [String]
  ) {
    telemetries(
      tenantId: $tenantId
      deviceIds: $deviceIds
      startTime: $startTime
      endTime: $endTime
      page: $page
      size: $size
      sort: $sort
      filter: $filter
    ) {
      page {
        size
        number
        totalPages
        totalElements
      }
      telemetries {
        device {
          id
          name
        }
        name
        value
        quality
        time
      }
    }
  }
`;

const AVERAGE_TELEMETRY = gql`
  query averageTelemetries(
    $tenantId: ID!
    $deviceId: ID!
    $name: String
    $startTime: Long!
    $endTime: Long
    $page: Int
    $size: Int
    $sort: [String]
  ) {
    averageTelemetries(
      tenantId: $tenantId
      deviceId: $deviceId
      name: $name
      startTime: $startTime
      endTime: $endTime
      page: $page
      size: $size
      sort: $sort
    ) {
      page {
        size
        number
        totalPages
        totalElements
      }
      telemetries {
        device {
          id
          name
        }
        name
        value
        quality
        time
      }
    }
  }
`;
const useTelemetry = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  /*
   * Examples
   * sort: ["time,asc", "name,desc"] default asc
   *
   * For the filter, you can have multiple filters with AND or OR relationship
   * For OR, you add a ' in front of the parameter name, for example
   * filter: ["name:humidity", "'name:temperature"]
   * this means name contains humidity OR temperature
   *
   * you can also place conditions <, >, :(semi-colon means CONTAINS)
   * example
   * filter: ["humidity>20", "temperature<100", "'site:DSO"]
   * this means (humidity > 20 AND temperature < 100) OR site name CONTAINS DSO
   * AND condition always takes precedence no matter the order
   *
   * page = page number (starts from 0)
   */

  const [
    averageTelemetries,
    {
      loading: averageTelemetriesLoading,
      error: averageTelemetriesError,
      data: averageTelemetriesData,
    },
  ] = useLazyQuery(AVERAGE_TELEMETRY);

  const [
    fetchTelemetry,
    {
      loading: isFetchTelemetryLoading,
      error: fetchTelemetryError,
      data: fetchTelemetryData,
    },
  ] = useLazyQuery(GET_TELEMETRY);

  useEffect(() => {
    if (fetchTelemetryError) {
      handleError(fetchTelemetryError, uri, enqueueSnackbar, intl);
    }
  }, [fetchTelemetryError]);

  return {
    isFetchTelemetryLoading,
    fetchTelemetryError,
    fetchTelemetryData,
    fetchTelemetry,
    averageTelemetries,
    averageTelemetriesLoading,
    averageTelemetriesError,
    averageTelemetriesData,
  };
};

export default useTelemetry;
