import { useContext } from "react";
import { SnackbarProvider } from "notistack";
import { styled, Box } from "@mui/material";
import { InfoIcon, SnackBarSuccessIcon } from "../../images/Icons";
import DarkModeContext from "../../providers/context/darkModeContext";

const SnackBar = (props: any) => {
  const { children } = props;
  const { colors }: any = useContext(DarkModeContext);

  const StyledSnackbarProvider = styled(SnackbarProvider)<{
    $variant?: string;
  }>`
    #notistack-snackbar {
      font-family: "NotoSans-Regular";
    }
    ,
    .go703367398 .MuiButtonBase-root {
      position: absolute;
      right: 24px;
    }
    &.notistack-MuiContent-info {
      background-color: ${colors.lightBlue50};
      color: ${colors.lightBlue900};
      padding-left: 16px;
    }
    &.notistack-MuiContent-success {
      background-color: ${colors.green50};
      color: ${colors.green900};
      padding-left: 16px;
    }
  `;

  return (
    <StyledSnackbarProvider
      maxSnack={3}
      dense={true}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      style={{ width: "100px" }}
      //action={SnackBarAction}
      preventDuplicate={true}
      iconVariant={{
        //success: '✅',
        //error: '✖️',
        //warning: '⚠️',
        info: (
          <Box sx={{ marginRight: "8px" }}>
            <InfoIcon fill={colors.snackBarInfoIconBg} />
          </Box>
        ),
        success: (
          <Box sx={{ marginRight: "8px" }}>
            <SnackBarSuccessIcon />
          </Box>
        ),
      }}
    >
      {children}
    </StyledSnackbarProvider>
  );
};

export default SnackBar;
