import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import BasicTextFieldWithoutForm from "./WithoutForm/BasicTextFieldWithoutForm";

const BasicTextField = (props) => {
  const { form, formItemLabel = "", rules = {}, handleOnChange } = props;

  const {
    formState: { errors },
  } = form;

  return (
    <Controller
      control={form.control}
      name={formItemLabel}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        const handleOnChangeInject = (val) => {
          if (handleOnChange) handleOnChange(val);
          onChange(val);
        };

        return (
          <FormControl fullWidth>
            <BasicTextFieldWithoutForm
              {...props}
              value={value || ""}
              handleOnChange={handleOnChangeInject}
              errors={errors}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default BasicTextField;
