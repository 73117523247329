import { useContext } from "react";
import Button from "@mui/material/Button";
import DarkModeContext from "../../providers/context/darkModeContext";

const BasicButton = (props) => {
  const {
    children,
    variant = "contained",
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    disabled = false,
    sx,
  } = props;
  // variants can be "text", "contained", "outlined"
  // sx for overriding styles

  const { colors }: any = useContext(DarkModeContext);

  return (
    <Button
      variant={variant}
      onClick={onClick}
      sx={{
        fontFamily: "NotoSans-Regular",
        borderRadius: "8px",
        color:
          variant === "text" || variant === "outlined"
            ? colors.buttonFont
            : colors.buttonFontContained,
        textTransform: "unset",
        fontSize: "14px",
        backgroundColor:
          variant === "contained" ? colors.buttonBgContained : colors.buttonBg,
        "&:disabled": {
          backgroundColor: colors.disabledButtonBg,
          color: colors.disabledButtonFont,
        },
        boxShadow: "none",
        border:
          variant === "outlined"
            ? `1px solid ${colors.buttonOutline}`
            : "unset",
        ":hover": {
          bgcolor:
            variant === "contained"
              ? colors.buttonOnHoverBgContained
              : colors.buttonOnHoverBg, // theme.palette.primary.main
          color:
            variant === "text" || variant === "outlined"
              ? colors.datwylerTeal
              : colors.buttonOnHoverFontContained,
          border:
            variant === "outlined"
              ? `1px solid ${colors.datwylerTeal}`
              : "unset",
        },
        ...sx,
      }}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Button>
  );
};

export default BasicButton;
