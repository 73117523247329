import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import BasicTextFieldWithoutForm from "../FormInputComponents/WithoutForm/BasicTextFieldWithoutForm";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { getScrollBarSx } from "../../utils/scrollbarUtils";
import DarkModeContext from "../../providers/context/darkModeContext";

const SearchBox = (props) => {
  const { inputPlaceholder, options, onSelect } = props;
  const [filteredOptions, setFilteredOptions] = useState(options);
  const { colors }: any = useContext(DarkModeContext);

  const handleOnChange = (query) => {
    if (query) {
      const temp = options.filter((option) =>
        option?.label?.toLowerCase().includes(query)
      );
      setFilteredOptions(temp);
    } else {
      setFilteredOptions(options);
    }
  };

  const inputAdornment = (
    <InputAdornment position="end">
      <SearchIcon style={{ color: colors.iconColor }} />
    </InputAdornment>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ marginRight: "4px" }}>
        <BasicTextFieldWithoutForm
          label={inputPlaceholder}
          InputProps={{
            endAdornment: inputAdornment,
          }}
          handleOnChange={handleOnChange}
        />
      </Box>

      <List
        sx={{
          ...getScrollBarSx(colors),
          maxHeight: "360px",
          overflow: "auto",
          marginTop: "8px",
        }}
      >
        {filteredOptions.map((option) => {
          return (
            <ListItem disablePadding key={option.label}>
              <ListItemButton
                onClick={() => {
                  onSelect(option.value);
                }}
              >
                <ListItemText
                  primary={option.label}
                  sx={{ color: colors.fontTitle2 }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default SearchBox;
