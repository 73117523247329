import { useEffect, useContext } from "react";
import { useQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const GET_COUNTRIES = gql`
  {
    countries {
      id
      name
      iso2Code
      phoneCode
      status
    }
  }
`;

const useCountry = () => {
  const { uri }: any = useContext(graphQLContext);
  const { loading, error, data } = useQuery(GET_COUNTRIES);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      handleError(error, uri, enqueueSnackbar, intl);
    }
  }, [error]);

  return { loading, error, data };
};

export default useCountry;
