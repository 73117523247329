import { LOCALES } from "../locales";
import messages_en_GB_all from "./messages_en_GB_all";
import messages_zh_CN_all from "./messages_zh_CN_all";
import messages_fr_FR_all from "./messages_fr_FR_all";
import messages_de_CH_all from "./messages_de_CH_all";

export const messages = {
  [LOCALES.ENGLISH]: messages_en_GB_all,
  [LOCALES.CHINESE]: messages_zh_CN_all,
  [LOCALES.FRENCH]: messages_fr_FR_all,
  [LOCALES.GERMAN]: messages_de_CH_all,
};
