import { useCallback, useState, useContext } from "react";
import { Autocomplete, TextField } from "@mui/material";
import BasicTextFieldWithoutForm from "../FormInputComponents/WithoutForm/BasicTextFieldWithoutForm";
import DarkModeContext from "../../providers/context/darkModeContext";
import { useIntl } from "react-intl";
import { isEmailValid } from "../../utils/generalUtils";

const MultiEmailInput = ({
  optionEmailList = [],
  onChange,
  value = [],
  error,
  limitTags = 10,
}) => {
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = useCallback(
    (event: React.SyntheticEvent, inputValue: string) => {
      setInputValue(inputValue);
    },
    []
  );

  const handleAddTags = useCallback(() => {
    if (isEmailValid(inputValue)) {
      const emailList = new Set(value).add(inputValue); //validated.
      onChange(Array.from(emailList));
      setInputValue("");
    }
  }, [inputValue, value]);

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const { key } = event;
      if (key === "Enter") {
        event.preventDefault();
        handleAddTags();
      }
    },
    [handleAddTags]
  );

  const handleAutoCompleteChange = useCallback(
    (event: React.SyntheticEvent, value: Array<string>, reason: string) => {
      if (reason === "removeOption") {
        onChange([...value]);
      }
    },
    [onchange]
  );

  return (
    <>
      <Autocomplete
        disableClearable
        limitTags={limitTags}
        multiple
        options={optionEmailList}
        freeSolo
        value={Array.from(value)}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleAutoCompleteChange}
        sx={{
          borderRadius: "8px",
          "& .MuiAutocomplete-inputRoot": {
            alignItems: "baseline !important",
          },
        }}
        renderInput={(params) => (
          <BasicTextFieldWithoutForm
            {...params}
            label={intl.formatMessage({ id: "support_email_addresses" })}
            placeholder={intl.formatMessage({ id: "enter_email" })}
            type="email"
            onKeyDown={handleInputKeyDown}
            error={error}
            helperText={error && error.message}
            InputLabelProps={{
              ...params.InputLabelProps,
              sx: {
                color: colors.textFieldLabel,
                fontFamily: "NotoSans-Regular",
                fontSize: "14px",
                letterSpacing: "0.01px",
              },
            }}
            InputProps={{
              ...params.InputProps,
              sx: {
                fontFamily: "NotoSans-Regular",
                borderRadius: "8px",
                minHeight: "100px",
                "& .MuiInputBase-inputAdornedStart": {
                  minHeight: "32px",
                  overflow: "auto",
                },
              },
            }}
            handleOnChange={() => {}}
          />
        )}
      />
    </>
  );
};

export default MultiEmailInput;
