export const getSignalLabel = (tempSignalStrength) => {
  if (tempSignalStrength) {
    // Signal strength table RSSI from
    // https://wiki.teltonika-networks.com/view/Mobile_Signal_Strength_Recommendations
    if (tempSignalStrength > -65) {
      return "signal_strength_4";
    } else if (tempSignalStrength > -75 && tempSignalStrength <= -65) {
      return "signal_strength_3";
    } else if (tempSignalStrength > -85 && tempSignalStrength <= -75) {
      return "signal_strength_2";
    } else if (tempSignalStrength > -95 && tempSignalStrength <= -85) {
      return "signal_strength_1";
    } else if (tempSignalStrength <= -95) {
      return "signal_strength_0";
    }
  } else return "signal_strength_0";
};
