const messages = {
  // Login
  email: "Email",
  login: "Anmeldung",
  password: "Passwort",

  // Profile
  my_profile: "Mein Profil",
  name: "Name",
  company: "Unternehmen",
  contact_number: "Kontakt Nummer",
  role: "Rolle",
  change_password: "Kennwort ändern",
  profile_info: "Profil Information",
  log_out: "Ausloggen",
  confirm_logout: "Möchten Sie sich wirklich abmelden?",

  // Users
  add_user: "Benutzer hinzufügen",
  update_profile_info: "Profilinformationen aktualisieren",
  first_name: "Vorname",
  last_name: "Familienname",
  deactivate_user: "Benutzer deaktivieren",
  excel_filename_user: "Benutzerbericht",
  select_country_code: "Wählen Sie den Ländercode aus",

  // Roles
  ADMIN: "Administrator",
  OPERATOR: "Operator",

  // success messages
  user_deactivated_success: "Benutzer erfolgreich deaktiviert",
  new_user_added: "Neuer Benutzer hinzugefügt",
};

export default messages;
