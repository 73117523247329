import { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AddIcon, DownloadIcon } from "../../images/Icons";
import BasicButton from "../Buttons/BasicButton";
import DarkModeContext from "../../providers/context/darkModeContext";
import BasicCard from "../BasicCard";

const BasicScreenHeader = (props) => {
  const { title, addButton, exportButton, LowerComponent, MoreActions } = props;
  const { colors }: any = useContext(DarkModeContext);
  const [iconColor, setIconColor] = useState(colors.iconColor);

  useEffect(() => {
    setIconColor(colors.iconColor);
  }, [colors]);

  const handleEditButtonOnMouseEnter = () => {
    setIconColor(colors.datwylerTeal);
  };

  const handleEditButtonOnMouseLeave = () => {
    setIconColor(colors.iconColor);
  };

  return (
    <BasicCard
      sx={{
        marginTop: "16px",
        padding: "0px",
        paddingBottom: "25px",
      }}
    >
      <Box
        sx={{
          paddingLeft: "24px",
          paddingTop: "24px",
          height: "44px",
          paddingBottom: "16px",
        }}
      >
        <Box sx={{ display: "inline-flex" }}>
          <Typography
            sx={{
              color: colors.screenHeaderTitle,
              fontFamily: "NotoSans-SemiBold",
              fontSize: "20px",
              fontWeight: "600",
              letterSpacing: "0",
              lineHeight: "22px",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{ display: "inline-flex", paddingRight: "24px", float: "right" }}
        >
          {exportButton && (
            <BasicButton
              onClick={exportButton.onClick}
              variant={"outlined"}
              onMouseEnter={handleEditButtonOnMouseEnter}
              onMouseLeave={handleEditButtonOnMouseLeave}
              sx={{
                maxWidth: "164px",
                height: "44px",
                marginRight: !!addButton ? "24px" : "0px",
              }}
            >
              <DownloadIcon fill={iconColor} />
              <Typography
                sx={{
                  fontFamily: "NotoSans-Medium",
                  fontSize: "14px",
                  fontWeight: "500",
                  letterSpacing: "0",
                  lineHeight: "20px",
                  marginLeft: "10px",
                }}
              >
                {exportButton.label}
              </Typography>
            </BasicButton>
          )}
          {addButton && (
            <BasicButton
              onClick={addButton.onClick}
              variant={"contained"}
              sx={{ width: addButton.width, height: "44px" }}
            >
              <AddIcon />
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "NotoSans-Medium",
                  fontSize: "14px",
                  fontWeight: "500",
                  letterSpacing: "0",
                  lineHeight: "20px",
                  marginLeft: "10px",
                }}
              >
                {addButton.label}
              </Typography>
            </BasicButton>
          )}
          {MoreActions && <MoreActions />}
        </Box>
      </Box>
      {LowerComponent}
    </BasicCard>
  );
};

export default BasicScreenHeader;
