const messages = {
  // Device Monitoring
  devices: "Geräte",
  new_device_added: "Neues Gerät hinzugefügt",
  update_device: "Gerät aktualisieren",
  select_type_of_device: "Wählen Sie den Gerätetyp aus",
  type_of_env: "Art der Umgebung",
  select_model: "Wählen Sie Modell aus",
  enter_device_name: "Geben Sie den Gerätenamen ein",
  env_type: "Umgebungstyp",
  add_an_env: "Fügen Sie eine Umgebung hinzu",
  copy_device_id: "Geräte-ID kopieren",

  // Table headers
  type: "Typ",
  device_id: "Geräte ID",

  // Filters
  select_device: "Wählen Sie Gerät aus",

  // Device Types
  GATEWAY: "Gateway",
  DOOR_CONTACT: "Türkontakt",
  WATER_LEAK: "Wasserleck",
  COOLING_SYSTEM: "Kühlsystem",
  UPS: "UPS",
  PDU: "PDU",
  SMOKE_DETECTOR: "Rauchmelder",
  TEMP_HUMIDITY: "Temperatur und Luftfeuchtigkeit",
  env: "Umfeld",

  // Device Details
  device: "Gerät",
  device_updated: "Gerät aktualisiert",
  back_to_device: "Zurück zum Gerät",
  restart: "Neu starten",
  update_firmware: "Firmware aktualisieren",
  delete_device: "Gerät löschen",
  alarm_rule: "Alarmregel",
  update_lan_setting: "LAN-Einstellung aktualisieren",
  download_installer_sh: "Installationsprogramm .sh",
  device_id_copy_success: "Geräte-ID kopiert",

  // menu tabs
  device_detail: "Gerätedetails",
  set_parameter: "Parameter festlegen",

  // Device Cards
  device_power_status: "Status",
  device_on: "Online",
  device_off: "Offline",
  sim_card: "SIM Karte",
  sim_inserted: "Eingefügt",
  sim_not_inserted: "Nicht eingefügt",
  last_connection: "Letzte Verbindung",
  firmware_version: "Firmware Version",
  device_information: "Geräteinformation",
  connection: "Verbindung",
  device_type: "Gerätetyp",
  serial_number: "Seriennummer",
  modem_imei: "Modem-IMEI",
  modem_operator: "Modembetreiber",
  signal_strength: "Signalstärke",
  signal_strength_0: "Kein Signal",
  signal_strength_1: "Arm",
  signal_strength_2: "Ziemlich",
  signal_strength_3: "Gut",
  signal_strength_4: "Exzellent",
  conn_type: "Verbindungstyp",
  conn_state: "Verbindungsstatus",
  connected: "In Verbindung gebracht",
  disconnected: "Getrennt",
  conn_uptime: "Verbindungsverfügbarkeit",

  // Charts
  signal_strength_chart_title: "Signalstärke im Zeitverlauf",
  time: "Zeit",

  // Device status
  value: "Wert",
  input: "Eingang",

  // Front Door
  front_door: "Haustür",
  back_door: "Hintertür",

  // Aircon
  serious_alarm: "Schwerer Alarm aktiviert",
  air_outlet_temperature: "Luftauslasstemperatur",
  AL49_fire_alarm: "AL49 Feueralarm aktiviert",
  dc_fan_voltage: "DC-Lüfterspannung",
  air_return_humidity: "Luftrückführungsfeuchtigkeit",
  running_frequency: "Lauffrequenz",
  AL50_high_air_return_temp: "AL50 Luftrücklauftemperatur zu hoch",
  AL52_low_air_outlet_temp: "AL52 Luftauslasstemperatur zu niedrig",
  common_alarm: "Allgemeiner Alarm aktiviert",
  AL51_high_air_outlet_temp: "AL51 Luftauslasstemperatur zu hoch",
  evaporation_temperature: "Verdampfungstemperatur",
  suction_pressure: "Saugdruck",
  target_frequency: "Zielfrequenz",
  discharge_pressure: "Abgabedruck",
  "out-coil_temperature": "Out-Coil Temperatur",
  AL53_condensation_pump_fail: "AL53 Kondensationspumpe ausgefallen",
  "in-coil_temperature": "In-Coil Temperatur",
  target_evaporation_temperature: "Zielverdampfungstemperatur",
  discharge_temperature: "Entladungstemperatur",
  air_return_temperature: "Luftrücklauftemperatur",
  "on/off": "An/Aus",
  communication_state: "Kommunikationsstatus",
  temperature_setting: "Temperatureinstellung",
  indoor_humidity: "Luftfeuchtigkeit in Innenräumen",
  humidity_setting: "Luftfeuchtigkeitseinstellung",
  compressor: "Kompressor",
  "1#heater": "1# Heizung",
  "2#heater": "2# Heizung",
  indoor_temperature: "Innentemperatur",
  unit_state: "Einheitsstaat",
  heater_temp_threshold: "Schwellenwert für die Heiztemperatur",
  evaporator_temp: "Verdampfertemperatur",
  condenser_protected_temp_threshold:
    "Kondensatorgeschützte Temperaturschwelle",
  cooling_start_temp: "Kühlstarttemperatur",
  condenser_temp: "Kondensatortemperatur",
  evaporator_tube_temp: "Temperatur des Verdampferrohrs",
  cooling_threshold: "Kühlschwelle",
  high_temp_threshold: "Hohe Temperaturschwelle",
  heating_start_temp: "Heizstarttemperatur",
  heater: "Heizung",
  debug_temp: "Debug-Temperatur",
  low_temp_threshold: "Untertemperaturschwelle",
  evaporator_anti_frozen_temp_threshold:
    "Verdampfer-Anti-Frost-Temperaturschwelle",
  return_temp: "Rücklauftemperatur",
  evaporator_anti_frozen_temp: "Verdampfer-Anti-Frost-Temperatur",
  condenser_protected_temp: "Kondensatorgeschützte Temperatur",
  condenser_tube_temp: "Kondensatorrohrtemperatur",
  air_suction_temperature: "Luftansaugtemperatur",
  liquid_pipe_temperature: "Flüssigkeitsrohrtemperatur",
  subcooling: "Unterkühlung",
  indoor_fan_speed: "Geschwindigkeit des Innenventilators",
  compressor_output: "Kompressorausgang",
  outdoor_fan_speed: "Geschwindigkeit des Außenventilators",
  superheat: "Super Hitze",
  // DTC
  working_status: "Arbeits status",
  air_supply_temperature: "Luftzufuhrtemperatur",
  abstractir_supply_temperature_setting: "Einstellung der Luftzufuhrtemperatur",
  air_return_temperature_setting: "Einstellung der Luftrücklauftemperatur",
  air_return_humidity_setting: "Einstellung der Luftrückführungsfeuchtigkeit",
  comm_status: "Kommunikationsstatus",
  unit_operating_status: "Betriebsstatus der Einheit",
  air_supply_switch: "Luftzufuhrschalter",
  "1#_heating_switch": "1# Heizungsschalter",
  "2#_heating_switch": "2# Heizungsschalter",
  compressor_switch: "Kompressorschalter",
  condensate_pump_switch: "Kondensatpumpenschalter",
  humidifying_switch: "Luftbefeuchterschalter",

  // Door
  door: "Tür",
  door_contact: "Türkontakt",

  // Fire Alarm
  fire_alarm: "Feueralarm",

  // Meter
  pue: "Effektivität der Stromverbrauchsnutzung",
  total_apparent_power: "Gesamtscheinleistung",
  total_active_power: "Gesamtwirkleistung",
  total_power_factor: "Gesamtleistungsfaktor",
  total_reactive_power: "Gesamtblindleistung",
  average_phase_current: "Durchschnittlicher Phasenstrom",
  frequency: "Frequenz",
  average_phase_voltage: "Durchschnittliche Phasenspannung",
  import_active_energy: "Wirkenergie importieren",
  total_active_energy: "Gesamte aktive Energie",
  power: "Leistung",
  power_factor: "Leistungsfaktor",

  // UPS
  battery_capacity: "Batteriekapazität",
  output_current: "Ausgangsstrom",
  output_frequency: "Ausgangsfrequenz",
  battery_voltage: "Batteriespannung",
  output_power: "Ausgangsleistung",
  battery_temperature: "Batterietemperatur",
  input_frequency: "Eingangsfrequenz",
  input_voltage: "Eingangsspannung",
  battery_timeRemaining: "Verbleibende Batteriezeit",
  output_load: "Ausgangslast",
  input_current: "Eingangsstrom",
  output_voltage: "Ausgangsspannung",
  max_temperature: "Maximale Temperatur",
  ups_type: "UPS-Typ",
  battery_healthVoltage: "Batteriezustandsspannung",
  input_truePower: "Geben Sie wahre Kraft ein",
  output_a_phase_active_power: "Ausgang A-Phase Wirkleistung",
  output_b_phase_active_power: "Ausgang B-Phase Wirkleistung",
  output_c_phase_active_power: "Ausgang C-Phase Wirkleistung",
  output_a_phase_load: "Ausgang A-Phasenlast",
  output_b_phase_load: "Ausgang B-Phasenlast",
  output_c_phase_load: "Ausgang C-Phasenlast",
  output_a_phase_current: "Ausgang A-Phasenstrom",
  output_b_phase_current: "Ausgang B-Phasenstrom",
  output_c_phase_current: "Ausgang C-Phasenstrom",
  output_a_phase_voltage: "Ausgang A-Phasenspannung",
  output_b_phase_voltage: "Ausgang B-Phasenspannung",
  output_c_phase_voltage: "Ausgang C-Phasenspannung",
  output_a_phase_load_peak: "Ausgangs-A-Phasenlastspitze",
  output_b_phase_load_peak: "Ausgangs-B-Phasenlastspitze",
  output_c_phase_load_peak: "Ausgangs-C-Phasenlastspitze",
  input_a_phase_current: "Eingang A-Phasenstrom",
  input_b_phase_current: "Eingang B-Phasenstrom",
  input_c_phase_current: "Eingang C-Phasenstrom",
  input_a_phase_voltage: "Eingang A-Phasenspannung",
  input_b_phase_voltage: "Eingang B-Phasenspannung",
  input_c_phase_voltage: "Eingang C-Phasenspannung",
  input_a_phase_power_factor: "Eingangs-A-Phasen-Leistungsfaktor",
  input_b_phase_power_factor: "Eingangs-B-Phasen-Leistungsfaktor",
  input_c_phase_power_factor: "Eingangs-C-Phasen-Leistungsfaktor",
  bypass_a_phase_voltage: "Bypass-A-Phasenspannung",
  bypass_b_phase_voltage: "Bypass-B-Phasenspannung",
  bypass_c_phase_voltage: "Bypass-C-Phasenspannung",
  bypass_frequency: "Bypass-Frequenz",
  buzzer: "Summer",
  load: "Belastung",
  utility_state: "Versorgungsstatus",
  "i/p_fault_voltage": "I/P-Fehlerspannung",
  fault_state: "Fehlerzustand",
  battery_low_voltage: "Batteriespannung Niedrig",
  shutdown_active: "Herunterfahren Aktiv",
  battery_cell_voltage: "Batteriezellenspannung",
  // DTC
  input_voltage_a: "Eingangsspannung A",
  input_voltage_b: "Eingangsspannung B",
  input_voltage_c: "Eingangsspannung C",
  output_voltage_a: "Ausgangsspannung A",
  output_voltage_b: "Ausgangsspannung B",
  output_voltage_c: "Ausgangsspannung C",
  output_current_a: "Ausgangsstrom A",
  output_current_b: "Ausgangsstrom B",
  output_current_c: "Ausgangsstrom C",
  output_load_a: "Ausgangslast A",
  output_load_b: "Ausgangslast B",
  output_load_c: "Ausgangslast C",
  standby_mode: "Standby Modus",
  bypass_mode: "Bypass-Modus",
  online_mode: "Onlinemodus",
  battery_mode: "Batteriemodus",
  battery_test_mode: "Batterietestmodus",
  failure_mode: "Fehlermodus",
  eco_mode: "Energiesparmodus",
  frequency_conversion_mode: "Frequenzumwandlungsmodus",
  shutdown_mode: "Shutdown-Modus",

  // Smoke Sensor
  smoke_sensor: "Rauchsensor",
  smoke: "Rauch",
  // DTC
  smoke_detector: "Rauchmelder",

  // PDU
  l1_power_factor: "L1-Leistungsfaktor",
  l2_power_factor: "L2-Leistungsfaktor",
  l3_power_factor: "L3-Leistungsfaktor",
  temperature_1: "Temperatur 1",
  temperature_2: "Temperatur 2",
  temperature_3: "Temperatur 3",
  l1_current: "L1-Strom",
  l2_current: "L2-Strom",
  l3_current: "L3-Strom",
  l1_energy: "L1-Energie",
  l2_energy: "L2-Energie",
  l3_energy: "L3-Energie",
  l1_voltage: "L1-Spannung",
  l2_voltage: "L2-Spannung",
  l3_voltage: "L3-Spannung",
  humidity_1: "Luftfeuchtigkeit 1",
  humidity_2: "Luftfeuchtigkeit 2",
  l1_power: "L1-Leistung",
  l2_power: "L2-Leistung",
  l3_power: "L3-Leistung",
  total_energy: "Gesamtenergie",
  "1#current": "1# Strom",
  "2#current": "2# Strom",
  "1#voltage": "1# Stromspannung",
  "2#voltage": "2# Stromspannung",
  "1#energy": "1# Energie",
  "2#energy": "2# Energie",
  "1#temperature": "1# Temperatur",
  "2#temperature": "2# Temperatur",
  "1#humidity": "1# Luftfeuchtigkeit",
  "2#humidity": "2# Luftfeuchtigkeit",
  current: "Strom",
  voltage: "Stromspannung",
  energy: "Energie",
  // DTC
  power_frequency: "Stromfrequenz",
  phase_a_power: "Phase-A-Leistungs",
  phase_b_power: "Phase-B-Leistungs",
  phase_c_power: "Phase-C-Leistungs",
  phase_a_current: "Phase-A-Strom",
  phase_b_current: "Phase-B-Strom",
  phase_c_current: "Phase-C-Strom",

  // Bluenet PDU
  input_phase1_activePower: "Wirkleistung der Eingangsphase 1",
  input_phase2_activePower: "Wirkleistung der Eingangsphase 2",
  input_phase3_activePower: "Wirkleistung der Eingangsphase 3",
  input_phase1_current: "Eingangsstrom der Phase 1",
  input_phase2_current: "Eingangsstrom der Phase 2",
  input_phase3_current: "Eingangsstrom der Phase 3",
  input_phase1_voltage: "Eingangsspannung Phase 1",
  input_phase2_voltage: "Eingangsspannung Phase 2",
  input_phase3_voltage: "Eingangsspannung Phase 3",
  output_outlet1_activePower: "Ausgang 1 Wirkleistung",
  output_outlet2_activePower: "Ausgang 2 Wirkleistung",
  output_outlet3_activePower: "Ausgang 3 Wirkleistung",
  output_outlet4_activePower: "Ausgang 4 Wirkleistung",
  output_outlet1_current: "Ausgang 1 Strom",
  output_outlet2_current: "Ausgang 2 Strom",
  output_outlet3_current: "Ausgang 3 Strom",
  output_outlet4_current: "Ausgang 4 Strom",
  output_outlet1_voltage: "Spannung am Ausgang 1",
  output_outlet2_voltage: "Spannung am Ausgang 2",
  output_outlet3_voltage: "Spannung am Ausgang 3",
  output_outlet4_voltage: "Spannung am Ausgang 4",

  // Temp & Humidity
  emergency_fan_start_temp: "Starttemperatur des Notlüfters",
  emergency_fan_stop_temp: "Notfall-Lüfter-Stopp-Temperatur",
  low_humidity_threshold: "Niedrige Luftfeuchtigkeitsschwelle",
  high_humidity_threshold: "Hoher Luftfeuchtigkeitsgrenzwert",
  low_temperature_threshold: "Untertemperaturschwelle",
  high_temperature_threshold: "Hohe Temperaturschwelle",

  // Water Leakage
  water_leakage: "Wasserleck",

  // BLE
  battery: "Batterie",

  // Values
  open: "Offen",
  close: "Verschließen",
  normal: "Normal",
  alarm: "Alarm",
  on: "An",
  off: "Aus",
  stop: "Stoppen",
  cooling_and_dehumidification: "Kühlung und Entfeuchtung",
  cooling_and_humidification: "Kühlung und Befeuchtung",
  heating_and_dehumidification: "Heizung und Entfeuchtung",
  heating_and_humidification: "Heizung und Befeuchtung",
  cooling: "Kühlung",
  heating: "Heizung",
  humidification: "Befeuchtung",
  dehumidification: "Entfeuchtung",
  air_supply: "Luftversorgung",
  online: "Online",
  offline: "Offline",
  ok: "Normal",
  low: "Akku fast leer",
  true: "Ja",
  false: "Nein",

  // Set Parameter
  set_min_fan_speed: "Min. Lüftergeschwindigkeit",
  set_air_return_temperature: "Luftrücklauftemperatur",
  set_max_humidity: "Maximale Luftfeuchtigkeit",
  set_high_air_return_temperature_alarmValue:
    "Alarmwert für hohe Luftrücklauftemperatur",
  set_max_fan_speed: "Maximale Lüftergeschwindigkeit",
  set_air_outlet_temperature: "Luftauslasstemperatur",
  set_low_air_return_temperature_alarmValue:
    "Alarmwert für niedrige Luftrücklauftemperatur",
  set_high_air_outlet_temperature_alarmValue:
    "Alarmwert für hohe Luftauslasstemperatur",
  set_min_humidity: "Minimale Luftfeuchtigkeit",
  set_modbus_unit: "Modbus-Einheit",
  set_baudrate: "Baudrate",

  // Actions
  command_sent: "Befehl gesendet",
  confirm_restart:
    "Möchten Sie das Gerät wirklich neu starten? Diese Aktion kann nicht rückgängig gemacht werden.",
  command_sent_with_delay:
    "Befehl gesendet. Es kann einige Zeit dauern, bis die Einstellungen übernommen werden.",
  firmware_upload_success: "Firmware-Datei erfolgreich heruntergeladen",
  drag_and_drop_or: "Drag & Drop zum Hochladen oder",
  choose_file: "Datei wählen",
  max_file_size: "Maximale Dateigröße 100 MB",
  upload: "Hochladen",
  add_new_rule: "Neue Regel hinzufügen",
  update_rule: "Alarmregel aktualisieren",
  enter_alarm_name: "Geben Sie den Alarmnamen ein",
  email_address: "E-Mail-Adresse",
  set_attribute: "Attribut festlegen",
  set_condition: "Bedingung festlegen",
  enter_value: "Geben Sie den Wert ein",
  select_criticality: "Wählen Sie die Kritikalitätsstufe aus",
  value_tooltip:
    "Legen Sie in diesem Feld einen Wert fest, um die Bedingung der Regel abzuschließen, z. B. 40 Grad Celsius",
  days: "Tage",
  hours: "Stunden",
  minutes: "Minuten",
  seconds: "Sekunden",
  invalid_ip: "Ungültige IP-Adresse",
  select_protocol: "Wählen Sie Protokoll",
  ipv4_address: "IPv4-Adresse",
  enter_ipv4_netmask: "Geben Sie die IPv4-Netzmaske ein",
  enter_ipv4_gateway: "Geben Sie IPv4-Gateway ein",
  enter_ipv4_broadcast: "Geben Sie IPv4-Broadcast ein",
  dhcp_server_setting: "DHCP-Servereinstellung",
  start_ip: "IP starten",
  end_ip: "End-IP",
  enter_lease_time: "Geben Sie die Mietdauer ein",

  // Alarm Rules
  modem_temperature: "Temperatur",
  modem_signal: "Signal",
  properties: "Eigenschaften",
  alarm_rule_add_success: "Alarmregel hinzugefügt",
  alarm_rule_update_success: "Alarmregel aktualisiert",
  alarm_rule_delete_success: "Alarmregel(n) gelöscht",
  del_alarm_rule: "Alarmregel löschen",

  // others
  others: "Andere",
  excel_filename_devices: "Gerätebericht",
};

export default messages;
