const messages = {
  // Languages
  "en-GB": "English",
  "zh-CN": "Chinese (中文)",
  "fr-FR": "French (française)",
  "de-CH": "German (Deutsch)",

  // menu
  menu_home: "Startseite",
  menu_site: "Standorte",
  "menu_device-monitoring": "Geräteüberwachung",
  menu_alarm: "Alarm",
  menu_user: "Benutzer",
  menu_profile: "Profil",
  menu_tenant: "Mieter",
  menu_language: "Sprache",
  menu_license: "Lizenzen",
  menu_select_tenant: "Wählen Sie Mieter aus",
  menu_telemetry: "Telemetrien",
  menu_attachments: "Dateianlagen",
  menu_gateway: "Tor",
};

export default messages;
