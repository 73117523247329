import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  DarkModeContext,
  FileIcon,
  CloseIcon24p,
} from "../../Datwyler-shared-components";

const ExistingFileContent = (props) => {
  const { file, setFile, inputRef } = props;
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Box
      sx={{
        fontSize: 14,
        padding: "24px",
        width: 376,
        border: "1px dashed " + colors.blueGray300,
        borderRadius: "8px",
        backgroundColor: colors.updateFirmwareFileBg,
        marginTop: "16px",
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          borderRadius: "16px",
          backgroundColor: colors.updateFirmwareFileIconBg,
          width: "32px",
          height: "32px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FileIcon width={"20px"} height={"20px"} fill={colors.iconColor} />
      </Box>
      <Box
        sx={{
          display: "inline-flex",
          marginLeft: "12px",
          width: "calc(100% - 32px - 40px)",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: colors.updateFirmwareBgDragFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "12px",
              fontWeight: 500,
              letterSpacing: "0.01px",
              lineHeight: "15px",
            }}
          >
            {file.name}
          </Typography>
          <Typography
            sx={{
              color: colors.updateFirmwareBgDragFont2,
              fontFamily: "NotoSans-Regular",
              fontSize: "12px",
              letterSpacing: "0.01px",
              lineHeight: "15px",
            }}
          >
            {(file.size / 1000000).toFixed(3) + " MB"}
          </Typography>
        </Box>
      </Box>

      <IconButton
        onClick={() => {
          setFile(null);
          inputRef.current.value = null;
        }}
        sx={{ padding: "4px" }}
      >
        <CloseIcon24p fill={colors.iconColor} />
      </IconButton>
    </Box>
  );
};

export default ExistingFileContent;
