import Teltonika_TRB140 from "../images/device/teltonika_TRB140.png";
import Teltonika_RUTX11 from "../images/device/teltonika_RUTX11.png";
import GENERIC_COOLING_SYSTEM from "../images/device/GENERIC_COOLING_SYSTEM.png";
import GENERIC_UPS from "../images/device/GENERIC_UPS.png";
import GENERIC_SMOKE_DETECTOR from "../images/device/GENERIC_SMOKE_DETECTOR.png";
import GENERIC_GATEWAY from "../images/device/GENERIC_GATEWAY.png";
import GENERIC_DOOR_CONTACT from "../images/device/GENERIC_DOOR_CONTACT.png";
import GENERIC_PDU from "../images/device/GENERIC_PDU.png";
import GENERIC_WATER_LEAK from "../images/device/GENERIC_WATER_LEAK.png";
import GENERIC_TEMP_HUMIDITY from "../images/device/GENERIC_TEMP_HUMIDITY.png";

import NO_IMAGE from "../images/No_Image.png";

export const getDeviceImage = (modelId: String, type: String) => {
  let img = null;

  // first check if model is catered for
  switch (modelId) {
    case "46ec17f0-38a0-4fee-93cb-733b5c7cf917":
      img = Teltonika_RUTX11;
      break;
    case "aa9059af-cf2e-465d-b0f2-91616a817cf9":
      img = Teltonika_TRB140;
      break;
    default:
      break;
  }

  // generic images for models not available on frontend
  if (!img) {
    switch (type) {
      case "COOLING_SYSTEM":
        // https://www.seekpng.com/ima/u2w7y3w7o0i1a9o0/
        img = GENERIC_COOLING_SYSTEM;
        break;
      case "UPS":
        //https://www.seekpng.com/ipng/u2q8e6a9t4e6a9o0_a-cremation-diamond-takes-several-weeks-to-grow/
        img = GENERIC_UPS;
        break;
      case "SMOKE_DETECTOR":
        //https://www.seekpng.com/idown/u2t4y3q8q8a9u2e6_fire-alarm-icon-free-download-png-icon-white/
        img = GENERIC_SMOKE_DETECTOR;
        break;
      case "GATEWAY":
        //https://www.seekpng.com/idown/u2w7a9o0r5a9q8q8_global-networking-vector-network-flat-icon-png/
        img = GENERIC_GATEWAY;
        break;
      case "DOOR_CONTACT":
        //https://www.seekpng.com/idown/u2q8a9w7e6i1a9q8_door-vector-open-open-door-icon-vector-png/
        img = GENERIC_DOOR_CONTACT;
        break;
      case "PDU":
        //https://www.seekpng.com/ipng/u2r5e6q8q8o0q8u2_png-file-svg-power-board-icon/
        img = GENERIC_PDU;
        break;
      case "WATER_LEAK":
        //https://www.seekpng.com/idown/u2e6w7t4w7i1u2o0_blood-blood-comments-water-drop-icon-free/
        img = GENERIC_WATER_LEAK;
        break;
      case "TEMP_HUMIDITY":
        //https://www.seekpng.com/idown/u2q8q8a9w7a9t4i1_clipart-thermometer-svg-temperature-and-humidity-icon/
        img = GENERIC_TEMP_HUMIDITY;
        break;
      default:
        img = NO_IMAGE;
    }
  }

  return img;
};
