import { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getRequiredDateFormat } from "../../../utils/dateUtils";

const BasicCustomTooltip = (props) => {
  const { slice } = props;
  const { points } = slice;

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ backgroundColor: "#EAECF0" }}>
        <Box sx={{ position: "relative", height: "100%", padding: "8px" }}>
          <Typography
            key={points[0].serieId + "time"}
            sx={{ fontFamily: "NotoSans-Regular", fontSize: 10 }}
          >
            {getRequiredDateFormat(points[0]?.data?.x, "DD/MM/YY HH:mm:ss")}
          </Typography>
          {points?.map((point, index) => {
            return (
              <Fragment key={index}>
                <Typography
                  key={point.serieId + index}
                  sx={{ fontFamily: "NotoSans-Regular", fontSize: 10 }}
                >
                  {point.serieId + ": " + point.data?.y}
                </Typography>
              </Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default BasicCustomTooltip;
