import { useContext } from "react";
import Chip from "@mui/material/Chip";
import DarkModeContext from "../../providers/context/darkModeContext";

const BasicChip = (props: any) => {
  const { label, onDelete = null, backgroundColor, labelSx } = props;
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Chip
      label={label}
      onDelete={onDelete}
      sx={{
        backgroundColor: backgroundColor || colors.blueGray100,
        "& .MuiChip-label": {
          color: colors.blueGray700,
          fontFamily: "NotoSans-Medium",
          fontSize: "14px",
          fontWeight: 500,
          letterSpacing: "0.01px",
          lineHeight: "20px",
          paddingRight: "14px",
          paddingLeft: "14px",
          ...labelSx,
        },
      }}
    />
  );
};

export default BasicChip;
