import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BasicCheckBox from "../FormInputComponents/WithoutForm/BasicCheckBox";
import MoreButton from "../MoreButton";
import { useIntl } from "react-intl";
import DarkModeContext from "../../providers/context/darkModeContext";

const BasicDataTable = (props) => {
  const {
    headers,
    data,
    handleCheckAll,
    handleOnCheck = () => {},
    isHideCheckbox,
    columnConfigs = [],
    handleClickRow = () => {},
    isHideActions = false,
  } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);

  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    const isScrollBarVisible =
      document.body.scrollHeight > document.body.clientHeight;
    if (isScrollBarVisible) {
      window.dispatchEvent(new Event("changeWidthWithScrollBar"));
    }

    if (!data.every((row) => row.isChecked)) setIsCheckAll(false);
  }, [data]);

  const checkboxSpan = isHideCheckbox ? 0 : 1;
  const checkboxStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const colspan = (12 - checkboxSpan) / headers.length;
  const colStyle = {
    height: "72px",
    display: "flex",
    alignItems: "center",
  };

  const headerColStyle = {
    alignItems: "center",
  };

  const injectCheckAll = (e) => {
    setIsCheckAll(e.target.checked);
    handleCheckAll(e);
  };

  return (
    <Box sx={{ paddingBottom: "24px" }}>
      <Grid container style={{ alignItems: "center" }}>
        <Grid
          container
          style={{
            minHeight: "48px",
            alignItems: "center",
            backgroundColor: colors.dataTableHeaderBg,
            ...(isHideCheckbox && { paddingLeft: "48px" }),
          }}
        >
          {!isHideCheckbox && (
            <Grid
              item
              xs={columnConfigs["checkbox"]?.colspan || checkboxSpan}
              sx={{ ...checkboxStyle, ...headerColStyle }}
              key={"checkall"}
            >
              <BasicCheckBox
                sx={{ height: "24px", width: "24px" }}
                onChange={injectCheckAll}
                checked={isCheckAll}
              />
            </Grid>
          )}
          {headers.map((header, index) => {
            return (
              <Grid
                item
                xs={columnConfigs[header]?.colspan || colspan}
                key={header + "_" + index}
                sx={headerColStyle}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: columnConfigs[header]?.isCentralized
                      ? "center"
                      : "flex-start",
                  }}
                >
                  <Box sx={{ display: "inline-flex" }}>
                    <Typography
                      sx={{
                        color: colors.dataTableHeaderFont,
                        fontFamily: "NotoSans-Medium",
                        fontSize: "14px",
                        fontWeight: "500",
                        letterSpacing: "0.02px",
                        lineHeight: "20px",
                        textTransform: "capitalize",
                        overflowWrap: "anywhere",
                      }}
                    >
                      {header !== "actions"
                        ? intl.formatMessage({ id: header })
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {data.length > 0 ? (
          data.map((row, index) => {
            if (row) {
              const moreButtonMenuItems = row.moreButtonMenuItems || [];
              return (
                <Grid
                  id={"datatable_row_" + index}
                  container
                  key={"row_" + index}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: colors.dataTableBackground,
                    "&:hover": {
                      backgroundColor: colors.dataTableHover,
                    },
                    ...(isHideCheckbox && { paddingLeft: "48px" }),
                  }}
                  onClick={(e) => {
                    const target = e.target as HTMLInputElement;
                    if (
                      target.ariaLabel === "checkbox_" + index ||
                      target.id?.substring(0, 11) === "more-button"
                    ) {
                      return;
                    } else {
                      handleClickRow(row.additionalInfo);
                    }
                  }}
                >
                  {!isHideCheckbox && (
                    <Grid
                      item
                      xs={columnConfigs["checkbox"]?.colspan || checkboxSpan}
                      sx={{ ...colStyle, ...checkboxStyle }}
                      id={"checkbox_" + index}
                    >
                      <BasicCheckBox
                        ariaLabel={"checkbox_" + index}
                        id={"checkbox_" + index}
                        sx={{ height: "24px", width: "24px" }}
                        checked={row.isChecked}
                        onChange={() => {
                          handleOnCheck(row.additionalInfo);
                        }}
                      />
                    </Grid>
                  )}
                  {headers.map((key, index) => {
                    const d = key === "actions" ? null : row[key] || "-";

                    if (d) {
                      return (
                        <Grid
                          item
                          xs={columnConfigs[key]?.colspan || colspan}
                          key={d + "_" + index}
                          sx={colStyle}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: columnConfigs[key]?.isCentralized
                                ? "center"
                                : "flex-start",
                            }}
                          >
                            <Box sx={{ display: "inline-flex" }}>
                              {typeof d === "string" ? (
                                <Typography
                                  sx={{
                                    color: colors.dataTableFont,
                                    fontFamily: "NotoSans-Regular",
                                    fontSize: "14px",
                                    letterSpacing: "0.02px",
                                    lineHeight: "20px",
                                    overflowWrap: "anywhere",
                                  }}
                                >
                                  {intl.formatMessage({ id: d })}
                                </Typography>
                              ) : (
                                d
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }
                  })}
                  {!isHideActions && (
                    <Grid
                      item
                      xs={columnConfigs["actions"]?.colspan || colspan}
                      sx={colStyle}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: columnConfigs["actions"]
                            ?.isCentralized
                            ? "center"
                            : "flex-start",
                        }}
                      >
                        <MoreButton
                          id={"more-button"}
                          options={moreButtonMenuItems}
                          paperProps={{ style: { minWidth: "180px" } }}
                          transformOrigin={{
                            vertical: -8,
                            horizontal: 132,
                          }}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              );
            }
          })
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "84px",
            }}
          >
            <Typography
              sx={{
                color: colors.dataTableHeaderFont,
                fontFamily: "NotoSans-Medium",
                fontSize: "16px",
                fontWeight: "500",
                letterSpacing: "0.02px",
                lineHeight: "20px",
                textTransform: "capitalize",
                overflowWrap: "anywhere",
              }}
            >
              {intl.formatMessage({ id: "no_data" })}
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default BasicDataTable;
