import { Controller } from "react-hook-form";
import BasicSelectWithoutForm from "./WithoutForm/BasicSelectWithoutForm";

const BasicSelectWithCheckMark = (props) => {
  const { form, formItemLabel = "", rules = {} } = props;
  const {
    formState: { errors },
  } = form;

  return (
    <Controller
      control={form.control}
      name={formItemLabel}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <BasicSelectWithoutForm
            value={value}
            onChange={onChange}
            error={errors[formItemLabel]}
            {...props}
          />
        );
      }}
    />
  );
};

export default BasicSelectWithCheckMark;
