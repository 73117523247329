const messages = {
  // General
  error: "发生错误",

  // Input errors
  err_input_no_value: "请输入一个值",

  // Google Map errors
  get_address_fail: "无法检索输入地址",
  get_map_fail: "检索地址时出错",
  map_no_coordinates: "无法确定坐标",

  // Validation errors
  validation_required: "此字段是必需的",
  validation_invalid_ip: "地址无效",
  validation_end_ip_smaller: "结束IP不能小于起始IP",
  validation_min_latitude: "纬度不能小于-90",
  validation_max_latitude: "纬度不能超过 90",
  validation_min_longitude: "经度不能小于-180",
  validation_max_longitude: "经度不能超过 180",
  validation_maxlength: "超出最大字符数",

  // Device errors
  device_delete_fail: "尝试删除设备时出错",
  device_add_fail: "尝试添加设备时出错",
  err_file_upload_ext: "无法处理文件。请仅使用以下文件扩展名：",
  err_file_upload: "尝试上传文件时出错",
  update_setting_error: "尝试更新您的设置时出错。",
  no_devices_of_type: "没有选定类型的设备",
  no_devices_in_site: "所选站点没有设备",

  // User
  login_failed: "登录失败",
  no_email_or_password: "请输入您的电子邮件和密码！",
  load_user_info_error: "无法检索您的个人资料信息。",
  update_user_profile_failed: "无法更新您的信息",
  get_users_error: "获取账户信息时出错",

  // Telemetry
  no_filters: "没有指定过滤器",
};

export default messages;
