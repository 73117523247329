import common_messages from "./common/en_GB";
import error_messages from "./error/en_GB";
import navbar_messages from "./navbar/en_GB";
import homepage_messages from "./homepage/en_GB";
import alarm_messages from "./alarm/en_GB";
import sites_messages from "./sites/en_GB";
import device_monitoring_messages from "./device-monitoring/en_GB";
import user_messages from "./user/en_GB";
import tenant_messages from "./tenant/en_GB";
import telemetry_messages from "./telemetry/en_GB";
import analytics_messages from "./analytics/en_GB";
import gateway_messages from "./gateway/en_GB";
const messages = {
  ...common_messages,
  ...error_messages,
  ...navbar_messages,
  ...homepage_messages,
  ...alarm_messages,
  ...sites_messages,
  ...device_monitoring_messages,
  ...user_messages,
  ...tenant_messages,
  ...telemetry_messages,
  ...analytics_messages,
  ...gateway_messages,
};

export default messages;
