const messages = {
  gateway_header_title: "Gateway verwalten",
  gateway_label: "Tor",
  gateway_status: "Status",
  gateway_modem_operator: "SIM Karte",
  gateway_last_connection: "Letzte Verbindung",
  gateway_device_firmware_version: "Firmware Version",

  gateway_details: "Einzelheiten",
  //gateway table headers
  gateway_table_name: "Name",
  gateway_table_type: "Typ",
  gateway_table_id: "Identifikation",
  gateway_table_location: "Standort",
  gateway_table_site: "Site",
  gateway_table_status: "Status",

  // gateway tabs
  gateway_information_tab: "Information",
  gateway_alarms_tab: "Alarmregeln",
  gateway_configuration_tab: "Aufbau",

  gateway_details_name: "Name",
  gateway_details_deviceId: "Geräte ID",
  gateway_details_group: "Gruppe",
  gateway_details_ip_address: "IP Adresse",
  gateway_details_port: "Hafen",
  gateway_details_read_community: "Community lesen",
  gateway_details_write_community: "Community schreiben",
  gateway_details_activated: "Aktiviert",
  gateway_details_interval: "Intervall",
  gateway_details_port_unit_id: "Einheiten-ID",
  gateway_details_serial_number: "Seriennummer",
  //dynamic tab
  gateway_dynamic_key: "Schlüssel",
  gateway_dynamic_oid: "OID",
  gateway_dynamic_unit: "Einheit",
  gateway_dynamic_multiplier: "Multiplikator",
  gateway_dynamic_type: "Typ",
  gateway_dynamic_writable: "Schreibbar",
  gateway_dynamic_logged: "Angemeldet",
  gateway_dynamic_function: "Funktion",
  gateway_dynamic_fc: "FC",
  gateway_dynamic_address: "Adresse",
  gateway_dynamic_length: "Länge",
  gateway_dynamic_modbus_type: "Modbus-Typ",
  gateway_dynamic_channel: "Kanal",
  gateway_dynamic_sensor_type: "Sensor Type",

  //static tab
  gateway_statics_key: "Schlüssel",
  gateway_statics_oid: "OID",
  gateway_statics_unit: "Einheit",
  gateway_statics_multiplier: "Multiplikator",
  gateway_statics_type: "Typ",
  gateway_statics_writable: "Schreibbar",
  gateway_statics_logged: "Angemeldet",
  gateway_statics_function: "Funktion",
  gateway_statics_fc: "FC",
  gateway_statics_address: "Adresse",
  gateway_statics_length: "Länge",
  gateway_statics_modbus_type: "Modbus-Typ",
  gateway_statics_channel: "Kanal",
  gateway_statics_sensor_type: "Sensor Type",

  //trap tab
  gateway_trap_oid: "OID",
  gateway_trap_key: "Attributname",

  //gateway alarms
  gateway_alarms_name_tab: "Name",
  gateway_alarms_description_tab: "Beschreibung",
  gateway_alarms_email: "Email",
  gateway_alarms_level: "Information",
  gateway_alarms_status: "Status",
  gateway_alarms_attribute_name: "Attributname",
  gateway_alarms_operator_name: "Operator",
  gateway_alarms_operator_value: "Wert",

  gateway_alarm_info: "Information",
  gateway_alarm_warn: "Warnung",
  gateway_alarm_crictical: "Kritisch",

  gateway_int: "Ganze Zahl",
  gateway_double: "Doppelt",
  gateway_string: "Zeichenfolge",
  gateway_boolean: "Boolescher Wert",

  gateway_alarm_activated: "Aktiviert",
  gateway_alarm_deactivated: "Deaktiviert",

  gateway_operator_eq: "EQ",
  gateway_operator_ne: "NE",
  gateway_operator_gt: "GT",
  gateway_operator_ge: "GE",
  gateway_operator_lt: "LT",
  gateway_operator_le: "LE",

  //gateway alarms table
  gateway_alarms_name: "Name",
  gateway_alarms_description: "Beschreibung",
  gateway_alarms_properties: "Eigenschaften",
  gateway_alarms_severity: "Schwere",
  gateway_alarms_date: "Datum",
  gateway_alarms_actions: "Actions",

  //protocols
  snmpv2c: "SNMP Agent",
  efento: "Efento BLE",
  modbustcp: "Modbus TCP",
  modbusrtu: "Modbus RTU",

  snmpv2c_address: "SNMP Agent",
  efento_address: "Efento Seriennummer",
  modbustcp_address: "Modbus TCP Einheit",
  modbusrtu_address: "Modbus RTU Einheit",
  gateway_deviceId: "Geräte ID",

  gateway_device_online: "Online",
  gateway_device_offline: "Offline",

  gateway_na: "Nicht verfügbar",
  gateway_delete: "Delete",
  gateway_setting: "Einstellung",

  gateway_dynamics: "Dynamik",
  gateway_statics: "Statik",
  gateway_trap: "Fangen",
  gateway_alarms: "Alarm",

  gateway_true: "WAHR",
  gateway_false: "FALSCH",

  gateway_public: "Öffentlich",
  gateway_private: "Privat",

  gateway_config_create: "Neu",
  gateway_config_update: "Aktualisieren",

  gateway_delete_device: "Konfiguration löschen",

  gateway_cancel_config_btn: "Zurück",
  gateway_submit_config_btn: "Einreichen",
  gateway_update_config_btn: "Aktualisieren",

  gateway_success_message: "Konfiguration erfolgreich abgeschlossen.",
  gateway_reinitialize_message: "Gateway erfolgreich neu initialisiert.",
  gateway_device_delete_message: "Gerätekonfiguration erfolgreich gelöscht.",
};

export default messages;
