import { useState, useEffect, MouseEvent, useContext } from "react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/de-ch";
import "dayjs/locale/en-gb";
import "dayjs/locale/fr-ch";
import "dayjs/locale/zh-cn";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import BasicTextFieldWithoutForm from "./BasicTextFieldWithoutForm";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { ArrowRight } from "../../../images/Icons";
import { customDayOfWeek, formatTimeStamp } from "../../../utils/dateUtils";
import { useIntl } from "react-intl";
import DarkModeContext from "../../../providers/context/darkModeContext";

let isStartDateSelected = false;

const BasicDateRangePickerWithoutForm = (props) => {
  const {
    inputProps = {},
    InputProps = {},
    InputLabelProps = {},
    handleOnChange = () => {},
    label,
    errors,
    defaultStartDate = null, // defaultStartDate, defaultStartDate takes in Unix timestamp
    defaultEndDate = null,
    transformOrigin = {
      horizontal: "left",
      vertical: "top",
    },
    anchorOrigin = {
      vertical: "bottom",
      horizontal: "left",
    },
    popoverSx = {
      marginTop: "10px",
    },
    textfieldVariant = "outlined",
    disableFuture = false,
  } = props;
  const intl = useIntl();
  const today = new Date();
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const { colors }: any = useContext(DarkModeContext);

  const [startDate, setStartDate] = useState<Dayjs | null>(
    defaultStartDate ? dayjs(formatTimeStamp(defaultStartDate)) : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    defaultEndDate ? dayjs(formatTimeStamp(defaultEndDate)) : null
  );
  const [hoverDate, setHoverDate] = useState<Dayjs | null>(null);

  const [referencedStartDate, setReferencedStartDate] = useState<Dayjs | null>(
    dayjs(today)
  );
  const [referencedEndDate, setReferencedEndDate] = useState<Dayjs | null>(
    dayjs(new Date().setMonth(today.getMonth() + 1))
  );

  useEffect(() => {
    if (intl.locale) {
      let locale = intl.locale;
      // dayjs does not have fr-fr locale, convert to use fr (switzerland)
      if (locale === "fr-FR") locale = "fr-CH";
      dayjs.locale(locale);
    }
  }, [intl.locale]);

  useEffect(() => {
    setStartDate(
      defaultStartDate ? dayjs(formatTimeStamp(defaultStartDate)) : null
    );
    setEndDate(defaultEndDate ? dayjs(formatTimeStamp(defaultEndDate)) : null);
  }, [defaultStartDate, defaultEndDate]);

  useEffect(() => {
    if (!!startDate && !!endDate) {
      handleOnChange(startDate, endDate);
    }
  }, [startDate, endDate]);

  const handleClick = (event: MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setHoverDate(null);
  };

  const open = Boolean(anchorEl);

  const handleOnSelectDate = (val) => {
    const selectedDate = dayjs(val["$d"]);
    if (!isStartDateSelected) {
      setEndDate(null);
      setStartDate(selectedDate);
      isStartDateSelected = true;
    } else {
      // if the second selection is a date before the first selection
      if (selectedDate.isBefore(startDate)) {
        setEndDate(startDate);
        setStartDate(selectedDate);
      } else {
        setEndDate(selectedDate);
      }

      handleClose();
      isStartDateSelected = false;
    }
  };

  const customDayRenderer = (props: PickersDayProps<Dayjs>) => {
    const pickersDayProps = Object.assign({}, props);
    pickersDayProps.selected = false;
    const date = pickersDayProps.day || null;
    const withinRangeStyle = {
      backgroundColor: colors.dateRangeSelectedBg,
      borderRadius: 0,
      marginLeft: 0,
      marginRight: 0,
      width: "40px",
      color: colors.fontTitle2,
    };

    const commonProps = {
      onMouseEnter: () => onHoverDate(date),
      sx: {
        "&:hover": {
          backgroundColor: colors.dateRangeHoverBg,
        },
        "&&.Mui-selected": {
          borderRadius: "4px",
          backgroundColor: colors.datwylerTeal,
        },
        "&&.MuiPickersDay-today": {
          border: "1px solid " + colors.dateRangeTodayBorder,
        },
        color: colors.fontTitle2,
      },
    };

    if (pickersDayProps.outsideCurrentMonth) {
      return <PickersDay {...pickersDayProps} {...commonProps} />;
    }

    if (
      date.isSame(dayjs(today), "day") &&
      ((!!startDate &&
        !!endDate &&
        date.isAfter(startDate, "day") &&
        date.isBefore(endDate, "day")) ||
        (!!hoverDate &&
          !!startDate &&
          !hoverDate.isSame(startDate, "day") &&
          !hoverDate.isSame(endDate, "day") &&
          !(
            date.isBefore(startDate, "day") &&
            hoverDate.isAfter(startDate, "day")
          ) &&
          ((hoverDate.isAfter(startDate, "day") &&
            hoverDate.isAfter(date, "day")) ||
            (hoverDate.isBefore(startDate, "day") &&
              hoverDate.isBefore(date, "day") &&
              date.isBefore(startDate, "day")))))
    ) {
      return (
        <Box>
          <Box
            sx={{
              height: "36px",
              width: "40px",
              position: "absolute",
              backgroundColor:
                !(
                  date.isAfter(startDate, "day") &&
                  date.isBefore(endDate, "day")
                ) &&
                ((date.isAfter(endDate, "day") &&
                  date.isBefore(hoverDate, "day")) ||
                  (date.isAfter(startDate, "day") &&
                    date.isBefore(hoverDate, "day")) ||
                  (date.isBefore(startDate, "day") &&
                    date.isAfter(hoverDate, "day")))
                  ? colors.dateRangeHoverBg
                  : colors.dateRangeSelectedBg,
            }}
          />
          <PickersDay {...pickersDayProps} {...commonProps} />
        </Box>
      );
    } else if (date.isSame(startDate, "day") || date.isSame(endDate, "day")) {
      return (
        <Box>
          <Box
            sx={{
              height: "36px",
              width:
                !!hoverDate &&
                date.isSame(endDate, "day") &&
                date.isBefore(hoverDate, "day")
                  ? "40px"
                  : "20px",
              position: "absolute",
              backgroundColor:
                (!!startDate && !!hoverDate && !hoverDate.isSame(startDate)) ||
                !!endDate
                  ? colors.dateRangeStartDateHoverBg
                  : "unset",
              marginLeft:
                (!hoverDate && date.isSame(startDate, "day")) ||
                (date.isSame(startDate, "day") &&
                  date.isBefore(hoverDate, "day"))
                  ? "20px"
                  : "0px",
            }}
          />
          <PickersDay {...pickersDayProps} selected={true} {...commonProps} />
        </Box>
      );
    } else if (
      date.isAfter(startDate, "day") &&
      date.isBefore(endDate, "day")
    ) {
      return (
        <PickersDay
          {...pickersDayProps}
          {...commonProps}
          sx={withinRangeStyle}
        />
      );
    } else if (
      (date.isBefore(startDate, "day") && date.isAfter(hoverDate, "day")) ||
      (date.isAfter(startDate, "day") && date.isBefore(hoverDate, "day"))
    ) {
      return (
        <PickersDay
          {...pickersDayProps}
          {...commonProps}
          sx={{ ...withinRangeStyle, backgroundColor: colors.dateRangeHoverBg }}
        />
      );
    } else if (date.isSame(hoverDate, "day")) {
      return (
        <Box>
          <Box
            sx={{
              height: "36px",
              width: "20px",
              position: "absolute",
              backgroundColor:
                (!!startDate && !!hoverDate && !hoverDate.isSame(startDate)) ||
                !!endDate
                  ? colors.dateRangeHoverBg
                  : "unset",
              marginLeft: date.isBefore(startDate, "day") ? "20px" : "0px",
            }}
          />
          <PickersDay {...pickersDayProps} {...commonProps} />
        </Box>
      );
    }
    return <PickersDay {...pickersDayProps} {...commonProps} />;
  };

  const handleClickNextMonth = () => {
    const start = referencedStartDate.add(1, "month");
    const end = referencedEndDate.add(1, "month");
    setReferencedEndDate(end);
    setReferencedStartDate(start);
  };

  const handleClickPrevMonth = () => {
    const start = referencedStartDate.subtract(1, "month");
    const end = referencedEndDate.subtract(1, "month");
    setReferencedEndDate(end);
    setReferencedStartDate(start);
  };

  const customCalRenderer = (calendarHeaderProps, calNum) => {
    // recreate using dayjs because locale changes not reflected
    const tempDate = new Date(calendarHeaderProps.currentMonth);
    const currDate = dayjs(tempDate);

    // disabled: blueGray300
    // enabled: blueGray500
    return (
      <Box
        sx={{
          paddingTop: "16px",
          paddingRight: calNum === 2 ? "16px" : "0px",
          paddingLeft: calNum === 1 ? "16px" : "0px",
          justifyContent: calNum === 1 ? "start" : "end",
          display: "flex",
        }}
      >
        <Box
          sx={{
            height: "36px",
            width: "280px",
            border: "1px solid " + colors.blueGray200,
            borderRadius: "6px",
            backgroundColor: colors.dateRangeHeaderBg,
          }}
        >
          {calNum === 1 && (
            <Box
              sx={{
                transform: "rotate(180deg)",
                display: "inline-flex",
                width: "32px",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={handleClickPrevMonth}>
                <ArrowRight fill={colors.iconColor} />
              </IconButton>
            </Box>
          )}
          <Box
            sx={{
              display: "inline-flex",
              width: "248px",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: colors.fontTitle2,
                fontFamily: "NotoSans-Regular",
                fontSize: "14px",
                letterSpacing: 0,
                lineHeight: "20px",
              }}
            >
              {currDate.format("MMM YYYY")}
            </Typography>
          </Box>
          {calNum === 2 && (
            <Box
              sx={{
                display: "inline-flex",
                width: "32px",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={handleClickNextMonth}>
                <ArrowRight fill={colors.iconColor} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const onHoverDate = (date) => {
    // do not highlight the dates in between after the user already select the start date and end date
    if (!!startDate && !!endDate) return;
    setHoverDate(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BasicTextFieldWithoutForm
        variant={textfieldVariant}
        onClick={handleClick}
        handleOnChange={() => {}}
        value={
          startDate && endDate
            ? `${startDate.format("DD.MM.YYYY")} - ${endDate.format(
                "DD.MM.YYYY"
              )}`
            : label
            ? label
            : intl.formatMessage({ id: "date_range_label" })
        }
        InputProps={InputProps}
        inputProps={inputProps}
        InputLabelProps={InputLabelProps}
        formItemLabel={"dateRange"}
        errors={{ dateRange: errors }}
      />
      <Popover
        id={"calendar"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        sx={popoverSx}
        PaperProps={{
          sx: {
            boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
            borderRadius: "8px",
          },
        }}
      >
        <Box sx={{ display: "inline-flex" }}>
          <DateCalendar
            disableFuture={disableFuture}
            onChange={handleOnSelectDate}
            slots={{
              day: (pickersDayProps) => customDayRenderer(pickersDayProps),
              calendarHeader: (calendarHeaderProps) =>
                customCalRenderer(calendarHeaderProps, 1),
            }}
            dayOfWeekFormatter={(_day, weekday) =>
              customDayOfWeek(_day, weekday)
            }
            value={referencedStartDate}
            sx={{
              backgroundColor: colors.dateRangeBg,
              "&& .MuiDayCalendar-weekDayLabel": {
                color: colors.fontTitle2,
              },
            }}
          />
        </Box>
        <Box sx={{ display: "inline-flex" }}>
          <DateCalendar
            disableFuture={disableFuture}
            onChange={handleOnSelectDate}
            slots={{
              day: (pickersDayProps) => customDayRenderer(pickersDayProps),
              calendarHeader: (calendarHeaderProps) =>
                customCalRenderer(calendarHeaderProps, 2),
            }}
            dayOfWeekFormatter={(_day, weekday) =>
              customDayOfWeek(_day, weekday)
            }
            value={referencedEndDate}
            sx={{
              backgroundColor: colors.dateRangeBg,
              "&& .MuiDayCalendar-weekDayLabel": {
                color: colors.fontTitle2,
              },
            }}
          />
        </Box>
      </Popover>
    </LocalizationProvider>
  );
};

export default BasicDateRangePickerWithoutForm;
