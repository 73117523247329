const messages = {
  Analytics: "Analytik",
  menu_analytics: "Analytik",
  add_trend_model: "Neues Modell",
  forecast_model: "Prognosemodell",
  date_created_between: "Erstellungsdatum zwischen",
  model_name: "Modellname",

  ForecastDetails: "Prognosedetails",
  ForecastSetup: "Neues Modell",
  forecast_setup: "Prognose-Setup",
  source_configuration: "Quellkonfiguration",
  forecast_model_name: "Name des Prognosemodells",
  input_name: "Name eingeben",
  parameter_name: "Parametername",
  no_data: "Keine Daten",
  training_data: "Trainingsdaten",
  actual_data: "Wirkliche Daten",
  predicted_data: "Vorhergesagte Daten",
  anomaly_detection_option: "Option zur Anomalieerkennung",
  sampling_frequency: "Abtastfrequenz",
  training_date: "Schulungsdatum",
  configuration: "Aufbau",
  prediction_horizon: "Vorhersagehorizont",
  lookback_period: "Lookback-Zeitraum",
  autotune_model: "Autotune-Modell",
  forecasted_data: "Prognostizierte Daten",
  previous_prediction_select: "Vorherige Vorhersage Ein",
  forecast_option: "Prognoseoption",
  prediction_performance: "Vorhersageleistung",
  training_performance: "Trainingsleistung",
  submit: "Einreichen",
  one_min: "1 Minute",
  five_min: "5 Minuten",
  ten_min: "10 Minuten",
  thirty_min: "30 Minuten",
  one_hour: "1 Stunde",
  two_hours: "2 Stunden",
  idle: "Leerlauf",
  training: "Ausbildung",
  predicting: "Vorhersagen",
  queue: "Warteschlange",
  finished: "Fertig",
  failed: "Fehlgeschlagen",
  revoked: "Widerrufen",
  rejected: "Abgelehnt",
  analyzing: "Analysieren",

  retrain_dialog_title: "Umschulen",
  retrain_dialog_body:
    "Sind Sie sicher, dass Sie das Gerät neu trainieren möchten? Dies kann nicht rückgängig gemacht werden.",
  retrain_dialog_cancel: "Stornieren",
  retrain_dialog_confirm: "Umschulen",
  train_model_success_msg: "Aufgabe „Modell trainieren“ initiiert.",
  predict_model_success_msg: "Vorhersagemodellaufgabe initiiert",
  model_success_msg: "Modell erfolgreich erstellt.",
};

export default messages;
