import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import FileUploadContent from "./FileUploadContent";
import ExistingFileContent from "./ExistingFileContent";

// Wrap this component over the child component you want to set for file upload
// fileTypes = accepted file types seperated by comma eg ".doc,.docx"
const DragAndDropFileUpload = (props) => {
  const {
    children,
    isMultiple = false,
    onFileUpload,
    fileTypes = "",
    inputRef,
    file,
    setFile,
  } = props;
  const intl = useIntl();
  const [isDragActive, setIsDragActive] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragActive(true);
    } else if (e.type === "dragleave") {
      setIsDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    if (e?.dataTransfer?.files && e?.dataTransfer?.files[0]) {
      // at least one file has been dropped so do something
      onFileUpload(isMultiple ? e.dataTransfer.files : e.dataTransfer.files[0]);
    } else {
      enqueueSnackbar(intl.formatMessage({ id: "err_file_upload_ext" }), {
        variant: "error",
      });
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      // at least one file has been selected so do something
      onFileUpload(isMultiple ? e.target.files : e.target.files[0]);
    } else {
      enqueueSnackbar(intl.formatMessage({ id: "err_file_upload_ext" }), {
        variant: "error",
      });
    }
  };

  const content = children ? (
    children
  ) : (
    <FileUploadContent isDragActive={isDragActive} />
  );

  return (
    <Box
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <Button
        sx={{
          visibility: "none",
          padding: 0,
          textTransform: "none",
          "&:hover": { background: "inherit" },
        }}
        component="label"
      >
        <input
          ref={inputRef}
          type="file"
          multiple={isMultiple}
          hidden
          onChange={handleChange}
          accept={fileTypes}
        />
        {content}
      </Button>
      {file && (
        <ExistingFileContent
          file={file}
          setFile={setFile}
          inputRef={inputRef}
        />
      )}
    </Box>
  );
};

export default DragAndDropFileUpload;
