import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DarkModeContext from "../../providers/context/darkModeContext";
import { useIntl } from "react-intl";
import { FileUploadIcon } from "../../images/Icons";

const FileUploadContent = (props) => {
  const { isDragActive } = props;
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();

  const dragAndDropFont = {
    fontFamily: "NotoSans-Regular",
    fontSize: 14,
    color: colors.updateFirmwareBgDragFont,
    letterSpacing: "0.01px",
    lineHeight: "16px",
    display: "inline-flex",
  };

  return (
    <Box
      sx={{
        fontSize: 14,
        padding: "24px",
        width: 376,
        border: "1px dashed " + colors.blueGray300,
        borderRadius: "8px",
        backgroundColor: isDragActive
          ? colors.updateFirmwareBgDragBgHover
          : colors.updateFirmwareBgDragBg,
        "&:hover": {
          backgroundColor: colors.updateFirmwareBgDragBgHover,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "16px",
        }}
      >
        <FileUploadIcon />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography sx={dragAndDropFont}>
          {intl.formatMessage({ id: "drag_and_drop_or" })}&nbsp;
        </Typography>
        <Typography
          sx={{
            ...dragAndDropFont,
            fontFamily: "NotoSans-Medium",
            color: colors.datwylerTeal,
          }}
        >
          {intl.formatMessage({ id: "choose_file" })}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "3px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: colors.updateFirmwareBgDragFont2,
            fontFamily: "NotoSans-Regular",
            fontSize: "12px",
            letterSpacing: "0.01px",
            lineHeight: "13px",
          }}
        >
          {intl.formatMessage({ id: "max_file_size" })}
        </Typography>
      </Box>
    </Box>
  );
};

export default FileUploadContent;
