const messages = {
  // Login
  email: "Email",
  login: "Login",
  password: "Password",

  // Profile
  my_profile: "My Profile",
  name: "Name",
  company: "Company",
  contact_number: "Contact Number",
  role: "Role",
  change_password: "Change password",
  profile_info: "Profile Information",
  log_out: "Log out",
  confirm_logout: "Are you sure you want to log out?",

  // Users
  add_user: "Add User",
  update_profile_info: "Update Profile Information",
  first_name: "First Name",
  last_name: "Last Name",
  deactivate_user: "Deactivate User",
  excel_filename_user: "Users Report",
  select_country_code: "Select Country Code",

  // Roles
  ADMIN: "Admin",
  OPERATOR: "Operator",

  // success messages
  user_deactivated_success: "User(s) Deactivated Successfully",
  new_user_added: "New User Added",
};

export default messages;
