const messages = {
  gateway_header_title: "Gérer les passerelles",
  gateway_label: "passerelle",
  gateway_status: "Statut",
  gateway_modem_operator: "Carte SIM",
  gateway_last_connection: "Dernière connexion",
  gateway_device_firmware_version: "Version du firmware",

  gateway_details: "Détails",
  //gateway table headers
  gateway_table_name: "Nom",
  gateway_table_type: "Taper",
  gateway_table_id: "IDENTIFIANT",
  gateway_table_location: "emplacement",
  gateway_table_site: "Site",
  gateway_table_status: "Statut",

  // gateway tabs
  gateway_information_tab: "Information",
  gateway_alarms_tab: "Règles d'alarme",
  gateway_configuration_tab: "Configuration",

  gateway_details_name: "Nom",
  gateway_details_deviceId: "Reference de l'appareil",
  gateway_details_group: "Groupe",
  gateway_details_ip_address: "Adresse IP",
  gateway_details_port: "Port",
  gateway_details_read_community: "Lire la communauté",
  gateway_details_write_community: "Communauté d'écriture",
  gateway_details_activated: "Activé",
  gateway_details_interval: "Intervalle",
  gateway_details_port_unit_id: "ID de l'unité",
  gateway_details_serial_number: "Numéro de série",
  //dynamic tab
  gateway_dynamic_key: "Clé",
  gateway_dynamic_oid: "OID",
  gateway_dynamic_unit: "Unité",
  gateway_dynamic_multiplier: "Multiplicateur",
  gateway_dynamic_type: "Taper",
  gateway_dynamic_writable: "Inscriptible",
  gateway_dynamic_logged: "Connecté",
  gateway_dynamic_function: "Fonction",
  gateway_dynamic_fc: "FC",
  gateway_dynamic_address: "Adresse",
  gateway_dynamic_length: "Longueur",
  gateway_dynamic_modbus_type: "Type de Modbus",
  gateway_dynamic_channel: "Canal",
  gateway_dynamic_sensor_type: "Type de capteur",

  //static tab
  gateway_statics_key: "Clé",
  gateway_statics_oid: "OID",
  gateway_statics_unit: "Unité",
  gateway_statics_multiplier: "Multiplicateur",
  gateway_statics_type: "Taper",
  gateway_statics_writable: "Inscriptible",
  gateway_statics_logged: "Connecté",
  gateway_statics_function: "Fonction",
  gateway_statics_fc: "FC",
  gateway_statics_address: "Adresse",
  gateway_statics_length: "Longueur",
  gateway_statics_modbus_type: "Modbus Type",
  gateway_statics_channel: "Canal",
  gateway_statics_sensor_type: "Type de capteur",

  //trap tab
  gateway_trap_oid: "OID",
  gateway_trap_key: "Nom d'attribut",

  //gateway alarms
  gateway_alarms_name_tab: "Nom",
  gateway_alarms_description_tab: "Description",
  gateway_alarms_email: "E-mail",
  gateway_alarms_level: "Information",
  gateway_alarms_status: "Statut",
  gateway_alarms_attribute_name: "Nom d'attribut",
  gateway_alarms_operator_name: "Opératrice",
  gateway_alarms_operator_value: "Valeur",

  gateway_alarm_info: "Information",
  gateway_alarm_warn: "Avertissement",
  gateway_alarm_crictical: "Critique",

  gateway_int: "Entier",
  gateway_double: "Double",
  gateway_string: "Chaîne",
  gateway_boolean: "Booléenne",

  gateway_alarm_activated: "Activé",
  gateway_alarm_deactivated: "Désactivé",

  gateway_operator_eq: "EQ",
  gateway_operator_ne: "NE",
  gateway_operator_gt: "GT",
  gateway_operator_ge: "GE",
  gateway_operator_lt: "LT",
  gateway_operator_le: "LE",

  //gateway alarms table
  gateway_alarms_name: "Nom",
  gateway_alarms_description: "Description",
  gateway_alarms_properties: "Propriétés",
  gateway_alarms_severity: "Gravité",
  gateway_alarms_date: "Date",
  gateway_alarms_actions: "Actions",

  //protocols
  snmpv2c: "SNMP Agent",
  efento: "Efento BLE",
  modbustcp: "Modbus TCP",
  modbusrtu: "Modbus RTU",

  snmpv2c_address: "SNMP Agent",
  efento_address: "Efento Serial Number",
  modbustcp_address: "Modbus TCP Unit",
  modbusrtu_address: "Modbus RTU Unit",
  gateway_deviceId: "Reference de l'appareil",

  gateway_device_online: "En ligne",
  gateway_device_offline: "Hors ligne",

  gateway_na: "Pas disponible",
  gateway_delete: "Supprimer",
  gateway_setting: "Paramètre",

  gateway_dynamics: "Dynamique",
  gateway_statics: "Statique",
  gateway_trap: "Piège",
  gateway_alarms: "Alarmes",

  gateway_true: "Vrai",
  gateway_false: "FAUX",

  gateway_public: "Publique",
  gateway_private: "Privée",

  gateway_config_create: "Nouvelle",
  gateway_config_update: "Mise à jour",

  gateway_delete_device: "Supprimer la configuration",

  gateway_cancel_config_btn: "Dos",
  gateway_submit_config_btn: "Soumettre",
  gateway_update_config_btn: "Mise à jour",

  gateway_success_message: "Configuration effectuée avec succès.",
  gateway_reinitialize_message: "Passerelle réinitialisée avec succès.",
  gateway_device_delete_message:
    "La configuration de l'appareil a été supprimée avec succès.",
};

export default messages;
