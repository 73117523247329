const messages = {
  title_alarm: "Alarm",
  alarm_tbl_col_header_device: "Gerät",
  alarm_tbl_col_header_site: "Grundstück",
  alarm_tbl_col_header_attribute: "Attribut",
  alarm_tbl_col_header_description: "Beschreibung",
  alarm_tbl_col_header_severity: "das Niveau",
  alarm_tbl_col_header_assigned_to: "Zugewiesen",
  excel_filename_alarm: "Alarmbericht",
  alarm_tbl_assign_to: "Zuweisen",
  alarm_tble_col_id: "AUSWEIS",

  // Filters
  sites: "Grundstück",
  device_types: "Gerätetypen",
  alarm_levels: "Alarmstufen",
  date_range: "Datumsbereich",

  // Table More button
  go_to_device: "Gehen Sie zu Gerät",
  acknowledge: "Anerkennen",
  deactivate: "Deaktivieren",
  reactivate: "Reaktivieren",

  // Rules Types
  EQ: "Gleich",
  NE: "Nicht gleich",
  GT: "Größer als",
  GTE: "Größer als oder gleich wie",
  LT: "Weniger als",
  LTE: "Gleich oder kleiner als",

  // success messages
  alarms_deactivated: "Alarme deaktiviert",
  issue_escalation: "Problemeskalation",
  close: "schließen",

  //escalation logs table
  escalation_logs: "Eskalationsprotokolle",
  escalated_id: "AUSWEIS",
  escalated_message: "Nachricht",
  escalated_by: "eskaliert durch",
  escalated_on: "Eskaliert weiter",

  unassign_alarm: "Alarmzuweisung aufheben",
};

export default messages;
