const messages = {
  tenant: "Locataire",
  add_tenant: "Ajouter un locataire",
  update_tenant: "Mettre à jour le locataire",
  deactivate_tenant: "Désactiver le locataire",

  // Dialogs
  enter_tenant_name: "Entrez le nom du locataire",
  num_users: "Nombre d'utilisateurs",
  num_sites: "Nombre de sites",
  num_devices: "Nombre d'appareils",

  // Filters
  filter_by_status: "Filtrer par statut",

  // Table
  number_of_licenses: "Nombre de licences",
  excel_filename_tenant: "Rapport des locataires",
  excel_filename_license: "Rapport sur les licences",

  // Sucess messages
  create_new_tenant_success: "Nouveau locataire créé",
  tenant_deactivated_success: "Locataire désactivé",
  tenant_updated_success: "Locataire mis à jour",
  create_license_success: "Licence créée",
  delete_license_success: "Licence supprimée",
  update_license_success: "Licence mise à jour",

  // License
  license: "Licence",
  new_license: "Nouvelle licence",
  add_new_license: "Ajouter une nouvelle licence",
  expiry_date_btw: "Date d'expiration entre",
  update_license: "Mettre à jour la licence",
  delete_license: "Supprimer la licence",
  license_number: "Numéro de licence",
  curr_user_usage: "Utilisation actuelle de l'utilisateur",
  curr_site_usage: "Utilisation actuelle du site",
  curr_device_usage: "Utilisation actuelle de l'appareil",
  license_information: "Informations sur la licence",

  // errors
  license_already_exist:
    "La licence existe déjà. Veuillez mettre à jour la licence actuelle ou la supprimer avant de l'ajouter.",
};

export default messages;
