import common_messages from "./common/zh_CN";
import error_messages from "./error/zh_CN";
import navbar_messages from "./navbar/zh_CN";
import homepage_messages from "./homepage/zh_CN";
import alarm_messages from "./alarm/zh_CN";
import sites_messages from "./sites/zh_CN";
import device_monitoring_messages from "./device-monitoring/zh_CN";
import user_messages from "./user/zh_CN";
import tenant_messages from "./tenant/zh_CN";
import telemetry_messages from "./telemetry/zh_CN";
import analytics_messages from "./analytics/zh_CN";
import gateway_messages from "./gateway/zh_CN";

const messages = {
  ...common_messages,
  ...error_messages,
  ...navbar_messages,
  ...homepage_messages,
  ...alarm_messages,
  ...sites_messages,
  ...device_monitoring_messages,
  ...user_messages,
  ...tenant_messages,
  ...telemetry_messages,
  ...analytics_messages,
  ...gateway_messages,
};

export default messages;
