import { useContext } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import DarkModeContext from "../../../providers/context/darkModeContext";

const maxCharHelperText = (max, val, intl) => {
  return (
    <Box>
      <Box sx={{ display: "inline-flex" }}>
        {intl.formatMessage({ id: "max_char" }) + ": " + max}
      </Box>
      <Box sx={{ display: "inline-flex", float: "right" }}>
        {val.length + "/" + max}
      </Box>
    </Box>
  );
};

const getHelperText = (error, max, val, intl) => {
  if (error?.message) return error?.message;
  else if (max) return maxCharHelperText(max, val, intl);
  else return null;
};

const BasicTextFieldWithoutForm = (props) => {
  const {
    label,
    type,
    formItemLabel = "",
    multiline,
    rows = 0,
    inputProps = {},
    InputProps = {},
    variant = "outlined",
    isDisabled = false,
    size = "normal",
    InputLabelProps = {},
    handleOnChange,
    errors = {},
    value,
    maxChars = null,
    onClick = () => {},
    onBlur = () => {},
    onMouseOver = () => {},
    placeholder,
    onKeyDown = () => {},
    inputRef = {},
  } = props;

  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  return (
    <TextField
      placeholder={placeholder}
      onClick={onClick}
      onBlur={onBlur}
      onMouseOver={onMouseOver}
      onKeyDown={onKeyDown}
      id={props.id}
      disabled={isDisabled}
      fullWidth
      label={label}
      variant={variant}
      type={type}
      inputRef={inputRef}
      InputProps={{
        ...InputProps,
        sx: {
          fontFamily: "NotoSans-Regular",
          borderRadius: "8px",
          ...InputProps.sx,
        },
      }}
      inputProps={inputProps}
      value={value}
      onChange={(e) => handleOnChange(e.target.value)}
      multiline={multiline}
      rows={rows}
      error={!!errors[formItemLabel]}
      helperText={getHelperText(errors[formItemLabel], maxChars, value, intl)}
      size={size}
      InputLabelProps={{
        ...InputLabelProps,
        sx: {
          color: colors.textFieldLabel,
          fontFamily: "NotoSans-Regular",
          ...InputLabelProps.sx,
        },
      }}
      FormHelperTextProps={{
        style: {
          marginTop: "0px",
          fontFamily: "NotoSans-Regular",
          fontSize: 12,
          color:
            maxChars && !errors[formItemLabel]?.message
              ? colors.formHelperText
              : colors.validationErrorRed,
        },
      }}
      sx={{
        "& .MuiInput-root": {
          color: colors.textFieldLabel,
        },
        " && .MuiInput-root::before": {
          borderColor: colors.textFieldLabel + "6b",
        },
        " && .MuiInput-root:hover::before": {
          borderColor: colors.textFieldUnderlineFocused,
        },
        "& .MuiInput-root::after": {
          color: colors.textFieldBorderFocused,
          borderBottomColor: colors.textFieldUnderlineFocused,
        },
        "& label.Mui-error": {
          color: colors.validationErrorRed,
        },
        "& label.Mui-focused": {
          color: errors[formItemLabel]
            ? colors.validationErrorRed
            : variant === "standard"
            ? colors.textFieldBorderFocused
            : colors.textFieldLabelFocused,
        },
        "& .MuiOutlinedInput-root": {
          color: errors[formItemLabel]
            ? colors.validationErrorRed
            : colors.textFieldLabel,
          borderColor: colors.textFieldBorder,
          "&.Mui-focused": {
            color: colors.textFieldLabelFocused,
          },
          "&.Mui-focused fieldset": {
            borderColor: errors[formItemLabel]
              ? colors.validationErrorRed
              : colors.textFieldBorderFocusedHover,
          },
        },
        "&:hover": {
          "&& fieldset": {
            borderColor: errors[formItemLabel]
              ? colors.validationErrorRed
              : colors.textFieldBorderHover,
          },
          "& .MuiOutlinedInput-root": {
            color: errors[formItemLabel]
              ? colors.validationErrorRed
              : colors.textFieldLabelHover,
          },
          "& label": {
            color: errors[formItemLabel]
              ? colors.validationErrorRed
              : colors.textFieldLabelHover,
          },
          "& .Mui-focused": {
            color: errors[formItemLabel]
              ? colors.validationErrorRed
              : variant === "standard"
              ? colors.textFieldBorderFocused
              : colors.textFieldLabelFocused,
            borderColor: errors[formItemLabel]
              ? colors.validationErrorRed
              : colors.textFieldBorderFocused,
            "&.MuiInput-root": {
              color: colors.textFieldLabelFocused,
            },
          },
        },
        "&& fieldset": {
          borderColor: colors.textFieldBorder,
        },
        borderRadius: "8px",
      }}
    />
  );
};

export default BasicTextFieldWithoutForm;
