import { useState } from "react";
import DarkModeContext from "./context/darkModeContext";
import { colors as colorsLib } from "../constants/colors";

export const useDarkModeListener = (callback) => {
  window.addEventListener("darkModeChanged", () => {
    callback();
  });
};

const DarkModeProvider = (props: any) => {
  const { children } = props;
  const existingDarkMode =
    window.localStorage?.getItem("darkMode") === "true" || false;
  const [darkMode, setDarkMode] = useState(existingDarkMode);
  const [colors, setColors] = useState(
    existingDarkMode ? colorsLib.dark : colorsLib.light
  );

  useDarkModeListener(() => {
    const isDarkMode = window.localStorage?.getItem("darkMode") === "true";
    setColors(isDarkMode ? colorsLib.dark : colorsLib.light);
    setDarkMode(isDarkMode);
  });

  return (
    <DarkModeContext.Provider
      value={{
        darkMode: darkMode,
        colors: colors,
      }}
    >
      {children}
    </DarkModeContext.Provider>
  );
};

export default DarkModeProvider;
