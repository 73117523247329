const messages = {
  // Device
  model: "Modèle",
  add_device: "Ajouter un appareil",
  device_name: "Nom de l'appareil",
  description: "Description",

  // Location
  location: "Emplacement",
  site: "Site",

  // Buttons
  edit: "Modifier",
  delete: "Supprimer",
  save: "Enregistrer",
  update: "mettre à jour",
  cancel: "Annuler",
  export: "Télécharger",
  filters: "Sélectionner",
  apply: "Appliquer",
  reset: "Réinitialiser",

  // Common headers
  settings: "Paramètres",
  detail: "Détail",
  details: "Détails",
  status: "l' état",
  alert: "Alerte",
  id: "ID",
  actions: "Actions",

  // Table Headers
  last_updated_by: "Dernière mise à jour par",
  last_updated_time: "Heure de la dernière mise à jour",

  // Confirm messages
  confirm_delete: "Etes-vous sûr que vous voulez supprimer?",
  confirm_deactivate: "Êtes-vous sûr de vouloir désactiver ?",

  // Success messages
  success_del: "Supprimé avec succès",
  device_restart_success: "La commande de redémarrage a été envoyée",
  update_setting_success:
    "Vos paramètres ont été mis à jour avec succès. Les paramètres peuvent mettre un certain temps à être pris en compte.",
  login_success: "Connexion réussie",
  update_user_profile_success: "Vos informations ont bien été mises à jour.",
  update_user_password_success: "Votre mot de passe a été modifié avec succès.",

  // Select Input
  all: "Tout",
  none: "Aucun",
  select_site: "Sélectionnez un site",
  select_period: "Sélectionnez la période",
  sort_by: "Trier par",

  // Text input
  max_char: "Caractères maximum",

  // BreadCrumbs
  Home: "Accueil",
  Device: "Appareils",
  Alarm: "Alarmes",
  User: "Utilisateurs",
  Tenants: "Locataires",
  Telemetry: "Télémétries",

  // Others
  not_available: "Pas disponible",
  date: "Date",
  remove: "Retirer",
  search: "Recherche",
  rows_per_page: "Lignes par page",

  // Severities
  information: "Information",
  warning: "Avertissement",
  critical: "Crucial",

  // Status
  active: "Actif",
  deactivated: "Désactivé",
  acknowledged: "Reconnu",
  escalated: "escaladé",
  in_progress: "en cours",
  resolved: "Résolue",

  // TenantInfo
  tenant_information: "Informations sur le locataire",
  tenant_id: "ID du locataire",
  tenant_name: "Nom du locataire",

  // Date Range input
  date_range_label: "Date de début - Date de fin",

  // Dates
  start_date: "Date de début",
  end_date: "Date de fin",

  // Dark Mode
  dark_mode: "Mode sombre",

  //timeperiod
  hour: "Heure",
  day: "Jour",
  week: "semaine",

  //email input
  support_email_addresses: "Entrez les adresses e-mail d'assistance",
  enter_email: "Entrez l'e-mail",
  manage_support_email_btn: "Gérer l'e-mail d'assistance",
};

export default messages;
