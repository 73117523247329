const messages = {
  Analytics: "Analytique",
  menu_analytics: "Analytique",
  add_trend_model: "Nouveau modèle",
  forecast_model: "Modèle de prévision",
  date_created_between: "Date de création entre",
  model_name: "Nom du modèle",

  ForecastDetails: "Détails des prévisions",
  ForecastSetup: "Nouveau modèle",
  forecast_setup: "Configuration des prévisions",
  source_configuration: "Configuration des sources",
  forecast_model_name: "Nom du modèle de prévision",
  input_name: "Nom d'entrée",
  parameter_name: "Le nom du paramètre",
  no_data: "Pas de données",
  training_data: "Données d'entraînement",
  actual_data: "Les données réelles",
  predicted_data: "Données prévues",
  anomaly_detection_option: "Option de détection d'anomalies",
  sampling_frequency: "Fréquence d'échantillonnage",
  training_date: "Date de formation",
  configuration: "Configuration",
  prediction_horizon: "Horizon de prédiction",
  lookback_period: "Période d'analyse",
  autotune_model: "Modèle de réglage automatique",
  forecasted_data: "Données prévues",
  previous_prediction_select: "Prédiction précédente Activé",
  forecast_option: "Option de prévision",
  prediction_performance: "Performances de prédiction",
  training_performance: "Performances d'entraînement",
  submit: "Soumettre",
  one_min: "1 minute",
  five_min: "5 minutes",
  ten_min: "10 minutes",
  thirty_min: "30 minutes",
  one_hour: "1 heure",
  two_hours: "2 heures",
  idle: "Inactif",
  training: "Entraînement",
  predicting: "Prédire",
  queue: "File d'attente",
  finished: "Fini",
  failed: "Échoué",
  revoked: "Révoqué",
  rejected: "Rejeté",
  analyzing: "en cours d'analyse",

  retrain_dialog_title: "Recycler",
  retrain_dialog_body:
    "Êtes-vous sûr de vouloir recycler l'appareil ? Cela ne peut pas être annulé",
  retrain_dialog_cancel: "Annuler",
  retrain_dialog_confirm: "Recycler",
  train_model_success_msg: "Tâche de formation du modèle lancée",
  predict_model_success_msg: "Prédire la tâche du modèle lancée",
  model_success_msg: "Modèle créé avec succès",
};

export default messages;
