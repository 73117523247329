import { useEffect, useContext } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const GET_ALARM_RULES = gql`
  query alarmRules(
    $tenantId: ID!
    $page: Int
    $size: Int
    $sort: [String]
    $filter: [String]
  ) {
    alarmRules(
      tenantId: $tenantId
      page: $page
      size: $size
      sort: $sort
      filter: $filter
    ) {
      page {
        size
        number
        totalPages
        totalElements
      }
      alarmRules {
        id
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        device {
          id
          name
          model {
            id
            name
            deviceType
            status
          }
          type
          site {
            id
            name
            status
          }
          status
        }
        name
        description
        emails
        properties
        severity
        updatedOn
        updatedBy {
          id
          firstName
          lastName
          email
          role
          status
        }
        status
      }
    }
  }
`;

const ADD_ALARM_RULE = gql`
  mutation addAlarmRule($input: AddAlarmRuleInput!) {
    addAlarmRule(input: $input) {
      alarmRule {
        id
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        device {
          id
          name
          model {
            id
            name
            deviceType
            status
          }
          type
          site {
            id
            name
            status
          }
          status
        }
        name
        description
        emails
        properties
        severity
        updatedOn
        updatedBy {
          id
          firstName
          lastName
          email
          role
          status
        }
        status
      }
    }
  }
`;

const UPDATE_ALARM_RULE = gql`
  mutation updateAlarmRule($input: UpdateAlarmRuleInput!) {
    updateAlarmRule(input: $input) {
      alarmRule {
        id
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        device {
          id
          name
          model {
            id
            name
            deviceType
            status
          }
          type
          site {
            id
            name
            status
          }
          status
        }
        name
        description
        emails
        properties
        severity
        updatedOn
        updatedBy {
          id
          firstName
          lastName
          email
          role
          status
        }
        status
      }
    }
  }
`;

const useAlarmRules = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [
    fetchAlarmRules,
    {
      loading: isFetchAlarmRulesLoading,
      error: fetchAlarmRulesError,
      data: fetchAlarmRulesData,
    },
  ] = useLazyQuery(GET_ALARM_RULES, {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const [
    addAlarmRule,
    {
      loading: isAddAlarmRuleLoading,
      error: addAlarmRuleError,
      data: addAlarmRuleResponseData,
      reset: resetAddAlarmRuleData,
    },
  ] = useMutation(ADD_ALARM_RULE);

  const [
    updateAlarmRule,
    {
      loading: isUpdateAlarmRuleLoading,
      error: updateAlarmRuleError,
      data: updateAlarmRuleResponseData,
      reset: resetUpdateAlarmRuleData,
    },
  ] = useMutation(UPDATE_ALARM_RULE);

  useEffect(() => {
    if (addAlarmRuleError || fetchAlarmRulesError || updateAlarmRuleError) {
      const error =
        addAlarmRuleError || fetchAlarmRulesError || updateAlarmRuleError;
      handleError(error, uri, enqueueSnackbar, intl);
    }
  }, [fetchAlarmRulesError, addAlarmRuleError, updateAlarmRuleError]);

  return {
    fetchAlarmRules,
    isFetchAlarmRulesLoading,
    fetchAlarmRulesData,
    fetchAlarmRulesError,
    addAlarmRule,
    isAddAlarmRuleLoading,
    addAlarmRuleError,
    addAlarmRuleResponseData,
    resetAddAlarmRuleData,
    updateAlarmRule,
    isUpdateAlarmRuleLoading,
    updateAlarmRuleError,
    updateAlarmRuleResponseData,
    resetUpdateAlarmRuleData,
  };
};

export default useAlarmRules;
