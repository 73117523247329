export default function useLocale() {
  const getDefaultLocale = () => {
    return window.localStorage.getItem("locale") || "en-GB";
  };

  const setLocale = (loc: string) => {
    window.localStorage.setItem("locale", loc);
    window.dispatchEvent(new Event("localeChanged"));
  };

  const useLocaleListener = (setLocale: Function) => {
    window.addEventListener("localeChanged", () => {
      setLocale(window.localStorage.getItem("locale"));
    });
  };

  // todo : change this to api calls to update locale in Userprofile

  return { getDefaultLocale, setLocale, useLocaleListener };
}
