export const getScrollBarSx = (colors) => {
  return {
    /* width */
    "::-webkit-scrollbar": {
      width: "4px",
    },
    /* Track */
    "::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 5px ${colors.scrollBarTrack}`,
      borderRadius: "2px",
    },
    /* Handle */
    "::-webkit-scrollbar-thumb": {
      background: colors.blueGray400,
      borderRadius: "2px",
      minHeight: "20px",
    },
    /* Handle on hover */
    "::-webkit-scrollbar-thumb:hover": {
      background: colors.widgetTableFont,
    },
  };
};
