import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { useSwipeable } from "react-swipeable";
import { ArrowRight } from "../../images/Icons";
import DarkModeContext from "../../providers/context/darkModeContext";

export const CarouselItem = (props: any) => {
  const { children, style } = props;

  return (
    <Box
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        height: 104,
        width: 240,
        userSelect: "none",
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

const SwipeableCarouselSlider = (props: any) => {
  // maxSwipes = max number of times user can swipe,
  // default screen set to show max of 5 child items
  // can be overriden
  const { children, maxSwipes = Math.floor(children.length / 5) } = props;
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    setActiveIndex(0);
  }, [maxSwipes]);

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => handleRight(),
    onSwipedRight: () => handleLeft(),
    onTouchStartOrOnMouseDown: () => {
      setIsMouseDown(true);
    },
    onTouchEndOrOnMouseUp: () => {
      setIsMouseDown(false);
    },
  });

  const handleLeft = () => {
    if (activeIndex > 0) setActiveIndex(activeIndex - 1);
  };

  const handleRight = () => {
    if (activeIndex < maxSwipes) setActiveIndex(activeIndex + 1);
  };

  const arrowButtonStyle = {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.arrowButtonBg,
    width: "30px",
    height: "30px",
    cursor: "pointer",
    marginRight: "16px",
    borderRadius: "8px",
    ":hover": {
      backgroundColor: colors.arrowButtonBgHover,
    },
  };

  return (
    <Box sx={{}}>
      {maxSwipes > 0 && (
        <Box sx={{ textAlign: "right", marginBottom: 2 }}>
          <Box
            sx={{ ...arrowButtonStyle, transform: "rotate(180deg)" }}
            onClick={handleLeft}
          >
            <ArrowRight />
          </Box>
          <Box sx={arrowButtonStyle} onClick={handleRight}>
            <ArrowRight />
          </Box>
        </Box>
      )}

      <Box
        id="carousel"
        {...swipeHandlers}
        sx={{
          overflow: "hidden",
          width: "100%",
          cursor: isMouseDown ? "grabbing" : "grab",
          paddingBottom: 1,
        }}
      >
        <Box
          id="inner"
          style={{
            whiteSpace: "nowrap",
            transform: `translateX(-${activeIndex * 100}%)`,
            transition: "transform 0.3s",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default SwipeableCarouselSlider;
