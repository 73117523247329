const messages = {
  tenant: "用户",
  add_tenant: "添加用户",
  update_tenant: "更新用户",
  deactivate_tenant: "停用用户",

  // Dialogs
  enter_tenant_name: "输入用户名称",
  num_users: "账户数量",
  num_sites: "站点数量",
  num_devices: "设备数量",

  // Filters
  filter_by_status: "按状态过滤",

  // Table
  number_of_licenses: "许可证书数量",
  excel_filename_tenant: "用户报告",
  excel_filename_license: "许可证书报告",

  // Sucess messages
  create_new_tenant_success: "创建新用户",
  tenant_deactivated_success: "用户已停用",
  tenant_updated_success: "用户已更新",
  create_license_success: "许可证书已创建",
  delete_license_success: "许可证书已删除",
  update_license_success: "许可证书已更新",

  // License
  license: "许可证书",
  new_license: "新许可证书",
  add_new_license: "添加新",
  expiry_date_btw: "到期日之间",
  update_license: "更新许可证书",
  delete_license: "删除许可证书",
  license_number: "许可证书号码",
  curr_user_usage: "当前账户使用情况",
  curr_site_usage: "当前站点使用情况",
  curr_device_usage: "当前设备使用情况",
  license_information: "许可证书信息",

  // errors
  license_already_exist: "许可证书已存在。请在添加之前更新当前许可证书或删除。",
};

export default messages;
