import { useState, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import DarkModeContext from "../../providers/context/darkModeContext";

const MoreButton = (props) => {
  const {
    id,
    options = [],
    paperProps,
    transformOrigin = {},
    buttonSx,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { colors }: any = useContext(DarkModeContext);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        handleClose(e);
      }}
    >
      <IconButton
        sx={{
          borderRadius: "8px",
          ...buttonSx,
        }}
        onClick={handleClick}
      >
        <MoreVertIcon
          id={id + "-icon"}
          fontSize="large"
          sx={{ width: 24, height: 24, color: colors.moreButtonDots }}
        />
        {options.length > 0 && (
          <Menu
            id="more-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={transformOrigin}
            PaperProps={{
              sx: {
                width: "20ch",
                backgroundColor: "unset",
              },
              ...paperProps,
            }}
            MenuListProps={{
              sx: {
                backgroundColor: colors.moreButtonDialogBg,
                borderRadius: "8px",
              },
            }}
          >
            {options.map((option, index) => {
              if (option.label === "seperator") {
                return <Divider key={"divider_" + index} />;
              } else {
                return (
                  <MenuItem
                    key={"option_" + option.label}
                    onClick={(event) => {
                      event.stopPropagation();
                      option.onClick(option.value);
                      handleClose(event);
                    }}
                    sx={{
                      "& :hover": {
                        color: colors.moreButtonDialogFontHover,
                        fontFamily: "NotoSans-SemiBold",
                        fontSize: "14px",
                        fontWeight: "600",
                        letterSpacing: 0,
                        lineHeight: "20px",
                        ...option.menuItemHoverStyle,
                      },
                    }}
                    disabled={option.isDisabled}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        color: colors.moreButtonDialogFont,
                        fontFamily: "NotoSans-Regular",
                        fontSize: "14px",
                        letterSpacing: 0,
                        lineHeight: "20px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        ...option.menuItemFontStyle,
                      }}
                    >
                      {option.label}
                    </Typography>
                  </MenuItem>
                );
              }
            })}
          </Menu>
        )}
      </IconButton>
    </ClickAwayListener>
  );
};

export default MoreButton;
