const messages = {
  // Device
  model: "型号",
  add_device: "添加设备",
  device_name: "设备名称",
  description: "描述",

  // Location
  location: "地点",
  site: "站点",

  // Buttons
  edit: "编辑",
  delete: "删除",
  save: "保存",
  update: "更新",
  cancel: "取消",
  export: "下载",
  filters: "筛选",
  apply: "应用",
  reset: "重置",

  // Common headers
  settings: "设置",
  detail: "细节",
  details: "细节",
  status: "状态",
  alert: "警报",
  id: "ID",
  actions: "行动",

  // Table Headers
  last_updated_by: "最后更新者",
  last_updated_time: "最后更新时间",

  // Confirm messages
  confirm_delete: "您确定你要删除？",
  confirm_deactivate: "您确定要停用吗？",

  // Success messages
  success_del: "删除成功",
  device_restart_success: "重启命令已发送",
  update_setting_success: "已成功更新您的设置。设置可能需要一些时间才能反映。",
  login_success: "登录成功",
  update_user_profile_success: "已成功更新您的信息。",
  update_user_password_success: "已成功更改密码。",

  // Select Input
  all: "全部",
  none: "没有任何",
  select_site: "选择站点",
  select_period: "选择期间",
  sort_by: "排序方式",

  // Text input
  max_char: "最大字符数",

  // BreadCrumbs
  Home: "主页",
  Device: "设备",
  Alarm: "警报",
  User: "账户管理",
  Tenants: "用户",
  Telemetry: "遥测",

  // Others
  not_available: "无法使用",
  date: "日期",
  remove: "消除",
  search: "搜索",
  rows_per_page: "每页行数",

  // Severities
  information: "信息",
  warning: "注意",
  critical: "严重",

  // Status
  active: "有效",
  deactivated: "已删除",
  acknowledged: "已确认",
  escalated: "升级处理",
  in_progress: "处理中",
  resolved: "已处理",

  // TenantInfo
  tenant_information: "用户信息",
  tenant_id: "用户ID",
  tenant_name: "用户名称",

  // Date Range input
  date_range_label: "开始日期 - 结束日期",

  // Dates
  start_date: "开始日期",
  end_date: "结束日期",

  // Dark Mode
  dark_mode: "深色模式",

  //timeperiod
  hour: "小时",
  day: "天",
  week: "星期",

  //email input
  support_email_addresses: "输入支持电子邮件地址",
  enter_email: "输入电子邮件",
  manage_support_email_btn: "管理支持电子邮件",
};

export default messages;
