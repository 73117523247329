const messages = {
  // Languages
  "en-GB": "English",
  "zh-CN": "Chinese (中文)",
  "fr-FR": "French (française)",
  "de-CH": "German (Deutsch)",

  // menu
  menu_home: "Accueil",
  menu_site: "Emplacements",
  "menu_device-monitoring": "Appareils",
  menu_alarm: "Alarmes",
  menu_user: "Utilisateurs",
  menu_profile: "Profil",
  menu_tenant: "Locataires",
  menu_language: "Langue",
  menu_license: "Licences",
  menu_select_tenant: "Sélectionner un locataire",
  menu_telemetry: "Télémétries",
  menu_attachments: "Pièce jointes",
  menu_gateway: "passerelle",
};

export default messages;
