import { useContext } from "react";
import { ResponsiveLine } from "@nivo/line";
import { CHARTDEFAULTS } from "./defaults";
import DarkModeContext from "../../providers/context/darkModeContext";

const BasicLineChart = (props) => {
  const { colors: libraryColors }: any = useContext(DarkModeContext);
  const {
    data,
    axisBottom = CHARTDEFAULTS.axisBottom, // set to null if do not want to display
    axisLeft = CHARTDEFAULTS.axisLeft, // set to null if do not want to display
    axisRight = CHARTDEFAULTS.axisRight,
    colors = { scheme: "nivo" },
    legends = CHARTDEFAULTS.legends,
    yScale = CHARTDEFAULTS.yScale,
    enablePoints = true,
    tooltip = null,
    enableCrosshair = true, // dotted lines from bottom and left
    onMouseEnter = null,
    enableSlices = false,
    sliceTooltip = undefined,
    margin = { top: 50, right: 110, bottom: 50, left: 60 },
    gridXValues = undefined,
    gridYValues = undefined,
    theme = {},
    curve = "natural",
  } = props;

  const defaultTheme = {
    axis: {
      ticks: {
        line: {
          stroke: libraryColors.chartAxisTick,
        },
        text: {
          fill: libraryColors.chartAxisFont,
        },
      },
      legend: {
        text: {
          fill: libraryColors.chartAxisFont,
        },
      },
    },
  };

  return (
    // make sure parent container have a defined height when using
    // responsive component, otherwise height will be 0 and
    // no chart will be rendered.
    // website examples showcase many properties,
    // you'll often use just a few of them.
    <ResponsiveLine
      data={data}
      enablePoints={enablePoints}
      colors={colors}
      margin={margin}
      xScale={{ type: "point" }}
      yScale={yScale}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={axisRight}
      axisBottom={axisBottom}
      axisLeft={axisLeft}
      enableGridX={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      tooltip={tooltip ? tooltip : undefined}
      legends={legends}
      enableCrosshair={enableCrosshair}
      onMouseEnter={onMouseEnter}
      enableSlices={enableSlices}
      sliceTooltip={sliceTooltip}
      gridXValues={gridXValues}
      gridYValues={gridYValues}
      theme={{ ...defaultTheme, ...theme }}
      curve={curve}
    />
  );
};

export default BasicLineChart;
