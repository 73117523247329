import common_messages from "./common/fr_FR";
import error_messages from "./error/fr_FR";
import navbar_messages from "./navbar/fr_FR";
import homepage_messages from "./homepage/fr_FR";
import alarm_messages from "./alarm/fr_FR";
import sites_messages from "./sites/fr_FR";
import device_monitoring_messages from "./device-monitoring/fr_FR";
import user_messages from "./user/fr_FR";
import tenant_messages from "./tenant/fr_FR";
import telemetry_messages from "./telemetry/fr_FR";
import analytics_messages from "./analytics/fr_FR";
import gateway_messages from "./gateway/fr_FR";

const messages = {
  ...common_messages,
  ...error_messages,
  ...navbar_messages,
  ...homepage_messages,
  ...alarm_messages,
  ...sites_messages,
  ...device_monitoring_messages,
  ...user_messages,
  ...tenant_messages,
  ...telemetry_messages,
  ...analytics_messages,
  ...gateway_messages,
};

export default messages;
