import { useEffect, useContext } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const GET_SUBTENANTS = gql`
  query subTenants(
    $tenantId: ID!
    $page: Int
    $size: Int
    $sort: [String]
    $filter: [String]
  ) {
    subTenants(
      tenantId: $tenantId
      page: $page
      size: $size
      sort: $sort
      filter: $filter
    ) {
      page {
        size
        number
        totalPages
        totalElements
      }
      tenants {
        id
        name
        license {
          id
          startDate
          endDate
          users {
            current
            total
          }
          sites {
            current
            total
          }
          devices {
            current
            total
          }
          tenant {
            id
            name
            status
            createdOn
            updatedOn
          }
          status
        }
        devices {
          total
          online
        }
        level
        status
        createdOn
        updatedOn
        managed
        updatedBy {
          id
          firstName
          lastName
          email
          role
          status
        }
      }
    }
  }
`;

const useSubTenant = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [
    fetchSubTenant,
    {
      loading: isfetchSubTenantLoading,
      error: fetchSubTenantError,
      data: fetchSubTenantData,
    },
  ] = useLazyQuery(GET_SUBTENANTS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (fetchSubTenantError) {
      const error = fetchSubTenantError;
      handleError(error, uri, enqueueSnackbar, intl);
    }
  }, [fetchSubTenantError]);

  return {
    fetchSubTenant,
    isfetchSubTenantLoading,
    fetchSubTenantData,
    fetchSubTenantError,
  };
};

export default useSubTenant;
