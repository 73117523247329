const messages = {
  Analytics: "分析",
  menu_analytics: "分析",
  add_trend_model: "新模型",
  forecast_model: "预测模型",
  date_created_between: "创建日期",
  model_name: "型号名称",

  ForecastDetails: "预测详细信息",
  ForecastSetup: "新模型",
  forecast_setup: "预测设置",
  source_configuration: "源配置",
  Forecast_model_name: "预测模型名称",
  input_name: "输入名称",
  parameter_name: "参数名称",
  no_data: "无数据",
  training_data: "训练数据",
  actual_data: "实际数据",
  predicted_data: "预测数据",
  anomaly_detection_option: "异常检测选项",
  sampling_frequency: "采样频率",
  training_date: "培训日期",
  configuration: "配置",
  prediction_horizon: "预测范围",
  lookback_period: "回溯期",
  autotune_model: "自动调谐模型",
  Forecasted_data: "预测数据",
  previous_prediction_select: "上一个预测开启",
  Forecast_option: "预测选项",
  Prediction_performance: "预测性能",
  training_performance: "训练表现",
  submit: "提交",
  one_min: "1 分钟",
  five_min: "5 分钟",
  ten_min: "10 分钟",
  thirty_min: "30分钟",
  one_hour: "1 小时",
  two_hours: "2 小时",
  idle: "空闲",
  training: "培训",
  predicting: "预测",
  queue: "队列",
  finished: "完成",
  failed: "失败",
  revoked: "撤销",
  rejected: "拒绝",
  analyzing: "分析",

  retrain_dialog_title: "重新训练",
  retrain_dialog_body: "您确定要重新训练设备吗？此操作无法撤消。",
  retrain_dialog_cancel: "取消",
  retrain_dialog_confirm: "重新训练",
  train_model_success_msg: "训练模型任务已启动。",
  Predict_model_success_msg: "预测模型任务已启动。",
  model_success_msg: "模型创建成功。",
};

export default messages;
