import { useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import DarkModeContext from "../../../providers/context/darkModeContext";

const BasicCheckBox = (props) => {
  const { id, checked, sx, onChange } = props;
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Checkbox
      inputProps={{ "aria-label": id }}
      sx={{
        color: colors.blueGray300,
        "&.Mui-checked": {
          color: colors.datwylerTeal,
        },
        ...sx,
      }}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default BasicCheckBox;
