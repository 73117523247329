const messages = {
  title_alarm: "警报",
  alarm_tbl_col_header_device: "设备",
  alarm_tbl_col_header_site: "站点",
  alarm_tbl_col_header_attribute: "属性",
  alarm_tbl_col_header_description: "描述",
  alarm_tbl_col_header_severity: "级别",
  alarm_tbl_col_header_assigned_to: "分配",
  excel_filename_alarm: "警报报告",
  alarm_tbl_assign_to: "分配给",
  alarm_tble_col_id: "ID",

  // Filters
  sites: "站点",
  device_types: "设备类型",
  alarm_levels: "警报级别",
  date_range: "日期范围",

  // Table More button
  go_to_device: "转到设备",
  acknowledge: "确认",
  deactivate: "停用",
  reactivate: "重新激活",

  // Rules Types
  EQ: "等于",
  NE: "不等于",
  GT: "大于",
  GTE: "大于或等于",
  LT: "少于",
  LTE: "小于或等于",

  // success messages
  alarms_deactivated: "警报已停用",
  issue_escalation: "问题升级",
  close: "关闭",

  //escalation logs table
  escalation_logs: "升级日志",
  escalated_id: "确认",
  escalated_message: "信息",
  escalated_by: "升级为",
  escalated_on: "升级为",

  unassign_alarm: "取消分配警报",
};

export default messages;
