import { useContext } from "react";
import Box from "@mui/material/Box";
import BasicCard from "../BasicCard";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import DarkModeContext from "../../providers/context/darkModeContext";

const TenantInfo = (props: any) => {
  const { tenant } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const labelStyle = {
    color: colors.subTitle,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.13px",
    lineHeight: "20px",
  };

  const infoStyle = {
    color: colors.fontTitle,
    fontFamily: "NotoSans-Regular",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "20px",
    marginTop: "5px",
  };

  return (
    <Box>
      <BasicCard
        sx={{
          marginTop: "16px",
          padding: "24px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "flex-start",
            verticalAlign: "top",
            height: "44px",
          }}
        >
          <Typography
            sx={{
              color: colors.fontTitle2,
              fontFamily: "NotoSans-SemiBold",
              fontSize: "18px",
              fontWeight: 600,
              letterSpacing: 0,
              lineHeight: "20px",
            }}
          >
            {intl.formatMessage({ id: "tenant_information" })}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "12px" }}>
          <Box sx={{ display: "inline-block", width: "312px" }}>
            <Box>
              <Typography sx={labelStyle}>
                {intl.formatMessage({ id: "tenant_name" })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={infoStyle}>{tenant?.name}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <Box>
              <Typography sx={labelStyle}>
                {intl.formatMessage({ id: "tenant_id" })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={infoStyle}>{tenant?.id}</Typography>
            </Box>
          </Box>
        </Box>
      </BasicCard>
    </Box>
  );
};

export default TenantInfo;
