import { useContext } from "react";
import Card from "@mui/material/Card";
import DarkModeContext from "../../providers/context/darkModeContext";

const BasicCard = (props) => {
  const { children, sx, onClick = () => {} } = props;
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Card
      onClick={onClick}
      sx={{
        padding: "24px",
        boxShadow: "none",
        borderRadius: "8px",
        backgroundColor: colors.cardBg,
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};

export default BasicCard;
