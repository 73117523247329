import { useContext } from "react";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import DarkModeContext from "../../providers/context/darkModeContext";

const AlarmBullet = (props) => {
  const { severity, height, width } = props;
  const { colors }: any = useContext(DarkModeContext);

  const getBulletColor = (severity) => {
    // INFORMATION, WARNING and CRITICAL
    switch (severity) {
      case "INFORMATION":
        return colors.alertBulletNotCritical;
      case "WARNING":
        return colors.alertBulletCritical;
      case "CRITICAL":
        return colors.alertBulletExtremeCritical;
      default:
        return colors.alertBulletNone;
    }
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        position: "relative",
        height: "100%",
        marginRight: "8px",
        alignItems: "center",
      }}
    >
      <CircleIcon
        sx={{
          color: getBulletColor(severity),
          width: width || 6,
          height: height || 6,
        }}
      />
    </Box>
  );
};

export default AlarmBullet;
