import { useEffect, useContext } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const GET_USERS = gql`
  query users(
    $tenantId: ID!
    $page: Int
    $size: Int
    $sort: [String]
    $filter: [String]
  ) {
    users(
      tenantId: $tenantId
      page: $page
      size: $size
      sort: $sort
      filter: $filter
    ) {
      page {
        size
        number
        totalPages
        totalElements
      }
      users {
        id
        firstName
        lastName
        email
        role
        company
        country {
          id
          name
          phoneCode
          status
        }
        contact
        status
      }
    }
  }
`;

const ADD_USER = gql`
  mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        role
        company
        country {
          id
          name
          phoneCode
          status
        }
        contact
        status
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        role
        company
        country {
          id
          name
          phoneCode
          status
        }
        contact
        status
      }
    }
  }
`;

const useUser = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [
    fetchUsers,
    {
      loading: isFetchUsersLoading,
      error: fetchUsersError,
      data: fetchUsersData,
    },
  ] = useLazyQuery(GET_USERS);

  const [
    addUser,
    {
      loading: isAddUserLoading,
      error: addUserError,
      data: addUserResponseData,
      reset: resetAddUserData,
    },
  ] = useMutation(ADD_USER, {
    // Then re-run fetch
    refetchQueries: [GET_USERS],
  });

  const [
    updateUser,
    {
      loading: isUpdateUserLoading,
      error: updateUserError,
      data: updateUserResponseData,
      reset: resetUpdateUserData,
    },
  ] = useMutation(UPDATE_USER, {
    // Then re-run fetch
    refetchQueries: [GET_USERS],
  });

  useEffect(() => {
    if (fetchUsersError || updateUserError || addUserError) {
      const error = fetchUsersError || updateUserError || addUserError;
      handleError(error, uri, enqueueSnackbar, intl);
    }
  }, [fetchUsersError, updateUserError, addUserError]);

  return {
    isFetchUsersLoading,
    fetchUsersError,
    fetchUsersData,
    fetchUsers,
    addUser,
    isAddUserLoading,
    addUserError,
    addUserResponseData,
    resetAddUserData,
    updateUser,
    isUpdateUserLoading,
    updateUserError,
    updateUserResponseData,
    resetUpdateUserData,
  };
};

export default useUser;
