import { useContext, useState } from "react";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import DarkModeContext from "../../providers/context/darkModeContext";

const BasicRadialBarChart = (props) => {
  // theme for styling, can be overwritten by parent component
  const { data, colors, maxValue, theme } = props;
  const [startAngle, setStartAngle] = useState(-90);
  const { colors: libraryColors }: any = useContext(DarkModeContext);

  // for animation
  setTimeout(() => {
    setStartAngle(90);
  }, 1000);

  const defaultTheme = {
    axis: {
      ticks: {
        line: {
          stroke: libraryColors.chartAxisTick,
        },
        text: {
          fill: libraryColors.chartAxisFont,
        },
      },
    },
  };

  return (
    <ResponsiveRadialBar
      data={data}
      valueFormat=" >-.2f"
      startAngle={startAngle}
      endAngle={-90}
      padding={0}
      cornerRadius={0}
      margin={{ top: 40, right: 0, bottom: 0, left: 0 }}
      radialAxisStart={null}
      innerRadius={0.6}
      colors={colors}
      circularAxisOuter={{ tickSize: 10, tickPadding: 12, tickRotation: 0 }}
      isInteractive={false}
      tracksColor="#009FB4"
      maxValue={maxValue}
      theme={{ ...defaultTheme, ...theme }}
    />
  );
};

export default BasicRadialBarChart;
