export const getDevicesBySiteId = (allDevices, siteId) => {
  // filter the devices and return array of devices in the same site
  const devicesInSameSite = allDevices
    .filter((device) => device?.site?.id === siteId)
    .sort(orderByName);

  return devicesInSameSite;
};

function orderByName(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
