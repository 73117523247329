export const redirectToLogin = (basePath) => {
  const path = basePath + "/login?postLoginRedirectUri=" + window.location.href;
  setTimeout(() => {
    window.location.replace(path);
  }, 2000);
};

export const redirectToLogout = (basePath) => {
  const path =
    basePath + "/logout?postLogoutRedirectUri=" + window.location.origin;
  window.location.replace(path);
};

export const openChangePassword = (basePath, username) => {
  const path = basePath + `/change-pwd?username=${username}`;
  window.open(path, "_blank").focus();
};
