import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import BasicSelectWithoutForm from "../FormInputComponents/WithoutForm/BasicSelectWithoutForm";
import { ArrowRight } from "../../images/Icons";
import { useIntl } from "react-intl";
import DarkModeContext from "../../providers/context/darkModeContext";

const BasicPagination = (props) => {
  const { rowsPerPage, setRowsPerPage, page, setPage, totalRows } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const rowsPerPageOptions = [
    {
      priority: 0,
      label: "10",
      value: 10,
    },
    {
      priority: 1,
      label: "25",
      value: 25,
    },
    {
      priority: 2,
      label: "50",
      value: 50,
    },
  ];

  const handleOnChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(e.target.value);
  };

  const handleLeft = () => {
    if (page > 0) setPage(page - 1);
  };

  const handleRight = () => {
    const maxPages = Math.floor((totalRows - 1) / rowsPerPage) + 1 || 1;
    if (page + 1 < maxPages) setPage(page + 1);
  };

  const style = {
    display: "inline-flex",
    height: "36px",
  };

  const fontRegularStyle = {
    color: colors.blueGray400,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "-0.2px",
    lineHeight: "20px",
  };

  const arrowButtonStyle = {
    height: "38px",
    width: "38px",
  };

  return (
    <Box sx={{ paddingBottom: "24px", width: "100%", textAlign: "right" }}>
      <Box sx={style}>
        <Typography sx={fontRegularStyle}>
          {intl.formatMessage({ id: "rows_per_page" }) + ": "}
        </Typography>
      </Box>
      <Box sx={{ ...style, width: "75px", marginLeft: "6px" }}>
        <BasicSelectWithoutForm
          menuItems={rowsPerPageOptions}
          onChange={handleOnChangeRowsPerPage}
          value={rowsPerPage}
          menuItemWidth={"unset"}
          SelectDisplayProps={{
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            width: "20px",
          }}
          inputLabelSx={{
            fontFamily: "NotoSans-Regular",
            fontSize: "14px",
            letterSpacing: "0px",
            lineHeight: "20px",
          }}
          menuPropsSx={{
            marginTop: "-36px",
          }}
        />
      </Box>
      <Box sx={{ ...style, width: "108px", marginLeft: "10px" }}>
        <Typography sx={fontRegularStyle}>
          {page * rowsPerPage + 1}
          {" - "}
          {page * rowsPerPage + rowsPerPage > totalRows
            ? totalRows
            : page * rowsPerPage + rowsPerPage}
          {" / "}
          {totalRows}
        </Typography>
      </Box>
      <Box sx={{ ...style }}>
        <IconButton
          sx={{ ...arrowButtonStyle, transform: "rotate(180deg)" }}
          onClick={handleLeft}
        >
          <ArrowRight />
        </IconButton>
        <IconButton sx={arrowButtonStyle} onClick={handleRight}>
          <ArrowRight />
        </IconButton>
      </Box>
    </Box>
  );
};

export default BasicPagination;
