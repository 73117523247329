const messages = {
  // Device Monitoring
  devices: "Appareils",
  new_device_added: "Nouvel appareil ajouté",
  update_device: "Mettre à jour l'appareil",
  select_type_of_device: "Sélectionnez le type d'appareil",
  type_of_env: "Type d'environnement",
  select_model: "Sélectionnez le modèle",
  enter_device_name: "Entrez le nom de l'appareil",
  env_type: "Type d'environnement",
  add_an_env: "Ajouter un environnement",
  copy_device_id: "Copier l'ID de l'appareil",

  // Table headers
  type: "la catégorie",
  device_id: "Reference de l'appareil",

  // Filters
  select_device: "Choisir un appareil",

  // Device Types
  GATEWAY: "Gateway",
  DOOR_CONTACT: "Contact de porte",
  WATER_LEAK: "Fuite d'eau",
  COOLING_SYSTEM: "Système de refroidissement",
  UPS: "UPS",
  PDU: "PDU",
  SMOKE_DETECTOR: "Détecteur de fumée",
  TEMP_HUMIDITY: "Température et humidité",
  env: "Environnement",

  // Device Details
  device: "Appareil",
  device_updated: "Appareil mis à jour",
  back_to_device: "Retour à l'appareil",
  restart: "recommencer",
  update_firmware: "Mise à jour du firmware",
  delete_device: "Supprimer l'appareil",
  alarm_rule: "Règle d'alarme",
  update_lan_setting: "Mettre à jour les paramètres LAN",
  download_installer_sh: "Téléchargez le programme d'installation .sh",
  device_id_copy_success: "ID de l'appareil copié",

  // menu tabs
  device_detail: "Détails de l'appareil",
  set_parameter: "Définir le paramètre",

  // Device Cards
  device_power_status: "l' état",
  device_on: "Allumer",
  device_off: "Éteindre",
  sim_card: "Carte SIM",
  sim_inserted: "Inséré",
  sim_not_inserted: "Non inséré",
  last_connection: "Dernière connexion",
  firmware_version: "Version du firmware",
  device_information: "Informations sur l'appareil",
  connection: "Connexion",
  device_type: "Type d'appareil",
  serial_number: "Numéro de série",
  modem_imei: "Modem IMEI",
  modem_operator: "Opérateur de modem",
  signal_strength: "Force du signal",
  signal_strength_0: "Pas de signal",
  signal_strength_1: "Pauvre",
  signal_strength_2: "Passable",
  signal_strength_3: "Bien",
  signal_strength_4: "Excellent",
  conn_type: "Type de connexion",
  conn_state: "État de connexion",
  connected: "Connecté",
  disconnected: "Débranché",
  conn_uptime: "Temps de disponibilité de la connexion",

  // Charts
  signal_strength_chart_title: "Force du signal au fil du temps",
  time: "Temps",

  // Device status
  value: "Valeur",
  input: "Saisir",

  // Front Door
  front_door: "Porte d'entrée",
  back_door: "Porte arrière",

  // Aircon
  serious_alarm: "Alarme sérieuse activée",
  air_outlet_temperature: "Température de sortie d'air",
  AL49_fire_alarm: "AL49 Alarme incendie activée",
  dc_fan_voltage: "Tension du ventilateur CC",
  air_return_humidity: "Humidité de retour d'air",
  running_frequency: "Fréquence de course",
  AL50_high_air_return_temp: "AL50 Température de retour d’air trop élevée",
  AL52_low_air_outlet_temp: "AL52 Température de sortie d’air trop basse",
  common_alarm: "Alarme commune activée",
  AL51_high_air_outlet_temp: "AL51 Température de sortie d’air trop élevée",
  evaporation_temperature: "Température d'évaporation",
  suction_pressure: "Pression d'aspiration",
  target_frequency: "Fréquence cible",
  discharge_pressure: "Décompresser",
  "out-coil_temperature": "Out-Coil Température",
  AL53_condensation_pump_fail: "Échec de la pompe à condensation AL53",
  "in-coil_temperature": "In-Coil Température",
  target_evaporation_temperature: "Température d'évaporation cible",
  discharge_temperature: "Température de décharge",
  air_return_temperature: "Température de retour d'air",
  "on/off": "Allumé ou éteint",
  communication_state: "État des communications",
  temperature_setting: "Réglage de la température",
  indoor_humidity: "Humidité intérieure",
  humidity_setting: "Réglage de l'humidité",
  compressor: "Compresseur",
  "1#heater": "1# Chauffage",
  "2#heater": "2# Chauffage",
  indoor_temperature: "Température intérieure",
  unit_state: "État de l'unité",
  heater_temp_threshold: "Seuil de température du chauffage",
  evaporator_temp: "Température de l'évaporateur",
  condenser_protected_temp_threshold:
    "Seuil de température protégé par le condenseur",
  cooling_start_temp: "Température de démarrage du refroidissement",
  condenser_temp: "Température du condenseur",
  evaporator_tube_temp: "Température du tube de l'évaporateur",
  cooling_threshold: "Seuil de refroidissement",
  high_temp_threshold: "Seuil de température élevée",
  heating_start_temp: "Température de démarrage du chauffage",
  heater: "Chauffage",
  debug_temp: "Température de débogage",
  low_temp_threshold: "Seuil de température basse",
  evaporator_anti_frozen_temp_threshold:
    "Seuil de température antigel de l'évaporateur",
  return_temp: "Température de retour",
  evaporator_anti_frozen_temp: "Température antigel de l'évaporateur",
  condenser_protected_temp: "Température protégée du condenseur",
  condenser_tube_temp: "Température du tube du condenseur",
  air_suction_temperature: "Température d'aspiration de l'air",
  liquid_pipe_temperature: "Température du tuyau de liquide",
  subcooling: "Sous-refroidissement",
  indoor_fan_speed: "Vitesse du ventilateur intérieur",
  compressor_output: "Sortie du compresseur",
  outdoor_fan_speed: "Vitesse du ventilateur extérieur",
  superheat: "Super chaleur",
  // DTC
  working_status: "Statut de travail",
  air_supply_temperature: "Température d'alimentation en air",
  air_supply_temperature_setting:
    "Réglage de la température d'alimentation en air",
  air_return_temperature_setting: "Réglage de la température de retour d'air",
  air_return_humidity_setting: "Réglage de l'humidité de retour d'air",
  comm_status: "État des communications",
  unit_operating_status: "État de fonctionnement de l'unité",
  air_supply_switch: "Commutateur d'alimentation en air",
  "1#_heating_switch": "1# Interrupteur de chauffage",
  "2#_heating_switch": "2# Interrupteur de chauffage",
  compressor_switch: "Commutateur de compresseur",
  condensate_pump_switch: "Commutateur de pompe à condensats",
  humidifying_switch: "Commutateur d'humidificateur",

  // Door
  door: "Porte",
  door_contact: "Contact de porte",

  // Fire Alarm
  fire_alarm: "Alarme incendie",

  // Meter
  pue: "Efficacité de l'utilisation de l'énergie",
  total_apparent_power: "Puissance apparente totale",
  total_active_power: "Puissance active totale",
  total_power_factor: "Facteur de puissance total",
  total_reactive_power: "Puissance réactive totale",
  average_phase_current: "Courant de phase moyen",
  frequency: "Fréquence",
  average_phase_voltage: "Tension de phase moyenne",
  import_active_energy: "Importer de l'énergie active",
  total_active_energy: "Énergie active totale",
  power: "Puissance",
  power_factor: "Facteur de puissance",

  // UPS
  battery_capacity: "Capacité de la batterie",
  output_current: "Courant de sortie",
  output_frequency: "Fréquence de sortie",
  battery_voltage: "Voltage de batterie",
  output_power: "Puissance de sortie",
  battery_temperature: "Température de la batterie",
  input_frequency: "Fréquence d'entrée",
  input_voltage: "Tension d'entrée",
  battery_timeRemaining: "Autonomie restante de la batterie",
  output_load: "Charge de sortie",
  input_current: "Courant d'entrée",
  output_voltage: "Tension de sortie",
  max_temperature: "Température maximale",
  ups_type: "Type UPS",
  battery_healthVoltage: "Tension de santé de la batterie",
  input_truePower: "Entrée de puissance réelle",
  output_a_phase_active_power: "Puissance active de phase de sortie A",
  output_b_phase_active_power: "Puissance active de phase de sortie B",
  output_c_phase_active_power: "Puissance active de phase de sortie C",
  output_a_phase_load: "Charge de phase de sortie A",
  output_b_phase_load: "Charge de phase de sortie B",
  output_c_phase_load: "Charge de phase de sortie C",
  output_a_phase_current: "Courant de phase de sortie A",
  output_b_phase_current: "Courant de phase de sortie B",
  output_c_phase_current: "Courant de phase de sortie C",
  output_a_phase_voltage: "Tension de phase de sortie A",
  output_b_phase_voltage: "Tension de phase de sortie B",
  output_c_phase_voltage: "Tension de phase de sortie C",
  output_a_phase_load_peak: "Pic de charge de phase de sortie A",
  output_b_phase_load_peak: "Pic de charge de phase de sortie B",
  output_c_phase_load_peak: "Pic de charge de phase de sortie C",
  input_a_phase_current: "Courant de phase d'entrée A",
  input_b_phase_current: "Courant de phase d'entrée B",
  input_c_phase_current: "Courant de phase d'entrée C",
  input_a_phase_voltage: "Tension de phase d'entrée A",
  input_b_phase_voltage: "Tension de phase d'entrée B",
  input_c_phase_voltage: "Tension de phase d'entrée C",
  input_a_phase_power_factor: "Facteur de puissance de la phase A d’entrée",
  input_b_phase_power_factor: "Facteur de puissance de la phase B d’entrée",
  input_c_phase_power_factor: "Facteur de puissance de la phase C d’entrée",
  bypass_a_phase_voltage: "Tension de phase A de dérivation",
  bypass_b_phase_voltage: "Tension de phase B de dérivation",
  bypass_c_phase_voltage: "Tension de phase C de dérivation",
  bypass_frequency: "Fréquence de contournement",
  buzzer: "Avertisseur Sonore",
  load: "Charger",
  utility_state: "État de l'utilitaire",
  "i/p_fault_voltage": "Tension de défaut I/P",
  fault_state: "État de défaut",
  battery_low_voltage: "Batterie Basse Tension",
  shutdown_active: "Arrêt Actif",
  battery_cell_voltage: "Tension des cellules de la batterie",
  // DTC
  input_voltage_a: "Tension d'entrée A",
  input_voltage_b: "Tension d'entrée B",
  input_voltage_c: "Tension d'entrée C",
  output_voltage_a: "Tension de sortie A",
  output_voltage_b: "Tension de sortie B",
  output_voltage_c: "Tension de sortie C",
  output_current_a: "Courant de sortie A",
  output_current_b: "Courant de sortie B",
  output_current_c: "Courant de sortie C",
  output_load_a: "Charge de sortie A",
  output_load_b: "Charge de sortie B",
  output_load_c: "Charge de sortie C",
  standby_mode: "Mode veille",
  bypass_mode: "Mode contournement",
  online_mode: "Mode en ligne",
  battery_mode: "Mode batterie",
  battery_test_mode: "Mode test de batterie",
  failure_mode: "Mode de défaillance",
  eco_mode: "Mode écologique",
  frequency_conversion_mode: "Mode de conversion de fréquence",
  shutdown_mode: "Mode d'arrêt",

  // Smoke Sensor
  smoke_sensor: "Capteur de fumée",
  smoke: "Fumée",
  // DTC
  smoke_detector: "Détecteur de fumée",

  // PDU
  l1_power_factor: "Facteur de puissance L1",
  l2_power_factor: "Facteur de puissance L2",
  l3_power_factor: "Facteur de puissance L3",
  temperature_1: "Température 1",
  temperature_2: "Température 2",
  temperature_3: "Température 3",
  l1_current: "Courant L1",
  l2_current: "Courant L2",
  l3_current: "Courant L3",
  l1_energy: "L1 Énergie",
  l2_energy: "L2 Énergie",
  l3_energy: "L3 Énergie",
  l1_voltage: "Tension L1",
  l2_voltage: "Tension L2",
  l3_voltage: "Tension L3",
  humidity_1: "Humidité 1",
  humidity_2: "Humidité 2",
  l1_power: "Puissance L1",
  l2_power: "Puissance L2",
  l3_power: "Puissance L3",
  total_energy: "Énergie totale",
  "1#current": "1# Courant",
  "2#current": "2# Courant",
  "1#voltage": "1# Tension",
  "2#voltage": "2# Tension",
  "1#energy": "1# Énergie",
  "2#energy": "2# Énergie",
  "1#temperature": "1# Température",
  "2#temperature": "2# Température",
  "1#humidity": "1# Humidité",
  "2#humidity": "2# Humidité",
  current: "Courant",
  voltage: "Tension",
  energy: "Énergie",
  // DTC
  power_frequency: "Fréquence d'alimentation",
  phase_a_power: "Puissance phase A",
  phase_b_power: "Puissance phase B",
  phase_c_power: "Puissance phase C",
  phase_a_current: "Courant de phase A",
  phase_b_current: "Courant de phase B",
  phase_c_current: "Courant de phase C",

  // Bluenet PDU
  input_phase1_activePower: "Puissance active de la phase 1 d'entrée",
  input_phase2_activePower: "Puissance active de la phase 1 d'entrée",
  input_phase3_activePower: "Puissance active de la phase 1 d'entrée",
  input_phase1_current: "Courant d'entrée phase 1",
  input_phase2_current: "Courant d'entrée phase 2",
  input_phase3_current: "Courant d'entrée phase 3",
  input_phase1_voltage: "Tension d'entrée phase 1",
  input_phase2_voltage: "Tension d'entrée phase 2",
  input_phase3_voltage: "Tension d'entrée phase 3",
  output_outlet1_activePower: "Sortie1 Puissance active",
  output_outlet2_activePower: "Sortie2 Puissance active",
  output_outlet3_activePower: "Sortie3 Puissance active",
  output_outlet4_activePower: "Sortie4 Puissance active",
  output_outlet1_current: "Courant de sortie1",
  output_outlet2_current: "Courant de sortie2",
  output_outlet3_current: "Courant de sortie3",
  output_outlet4_current: "OCourant de sortie4",
  output_outlet1_voltage: "Sortie 1 Tension",
  output_outlet2_voltage: "Sortie 2 Tension",
  output_outlet3_voltage: "Sortie 3 Tension",
  output_outlet4_voltage: "Sortie 4 Tension",

  // Temp & Humidity
  emergency_fan_start_temp: "Température de démarrage du ventilateur d'urgence",
  emergency_fan_stop_temp: "Température d'arrêt d'urgence du ventilateur",
  low_humidity_threshold: "Seuil d'humidité faible",
  high_humidity_threshold: "Seuil d'humidité élevé",
  low_temperature_threshold: "Seuil de température basse",
  high_temperature_threshold: "Seuil de température élevée",

  // Water Leakage
  water_leakage: "Fuite d'eau",

  // BLE
  battery: "Batterie",

  // Values
  open: "Ouvrir",
  close: "Fermer",
  normal: "Normale",
  alarm: "Alarme",
  on: "Sur",
  off: "Désactivé",
  stop: "Arrêt",
  cooling_and_dehumidification: "Refroidissement et déshumidification",
  cooling_and_humidification: "Refroidissement et humidification",
  heating_and_dehumidification: "Chauffage et déshumidification",
  heating_and_humidification: "Chauffage et humidification",
  cooling: "Refroidissement",
  heating: "Chauffage",
  humidification: "Humidification",
  dehumidification: "Déshumidification",
  air_supply: "Arrivée d'air",
  online: "En ligne",
  offline: "Hors ligne",
  ok: "Normale",
  low: "Faible",
  true: "Oui",
  false: "Non",

  // Set Parameter
  set_min_fan_speed: "Vitesse minimale du ventilateur",
  set_air_return_temperature: "Température de retour d'air",
  set_max_humidity: "Humidité maximale",
  set_high_air_return_temperature_alarmValue:
    "Valeur d'alarme de température de retour d'air élevée",
  set_max_fan_speed: "Vitesse maximale du ventilateur",
  set_air_outlet_temperature: "Température de sortie d'air",
  set_low_air_return_temperature_alarmValue:
    "Valeur d’alarme de température de retour d’air basse",
  set_high_air_outlet_temperature_alarmValue:
    "Valeur d'alarme de température de sortie d'air élevée",
  set_min_humidity: "Humidité minimale",
  set_modbus_unit: "Unité Modbus",
  set_baudrate: "Débit en bauds",

  // Actions
  command_sent: "Commande envoyée",
  confirm_restart:
    "Êtes-vous sûr de vouloir redémarrer l'appareil ? Cette action ne peut pas être annulée.",
  command_sent_with_delay:
    "Commande envoyée. Les paramètres peuvent mettre un certain temps à être pris en compte.",
  firmware_upload_success: "Fichier du micrologiciel téléchargé avec succès",
  drag_and_drop_or: "Faites glisser et déposez pour télécharger ou",
  choose_file: "Choisir le fichier",
  max_file_size: "Taille maximale du fichier 100 Mo",
  upload: "Télécharger",
  add_new_rule: "Ajouter une nouvelle règle",
  update_rule: "Mettre à jour la règle d'alarme",
  enter_alarm_name: "Entrez le nom de l'alarme",
  email_address: "Adresse e-mail",
  set_attribute: "Définir l'attribut",
  set_condition: "Définir la condition",
  enter_value: "Entrez la valeur",
  select_criticality: "Sélectionnez le niveau de criticité",
  value_tooltip:
    "Définissez la valeur dans cette case pour terminer la condition de la règle, par ex. 40 degrés Celsius",
  days: "jours",
  hours: "heures",
  minutes: "minutes",
  seconds: "secondes",
  invalid_ip: "Adresse IP invalide",
  select_protocol: "Sélectionnez le protocole",
  ipv4_address: "Adresse IPv4",
  enter_ipv4_netmask: "Entrez le masque de réseau IPv4",
  enter_ipv4_gateway: "Entrez la passerelle IPv4",
  enter_ipv4_broadcast: "Entrez la diffusion IPv4",
  dhcp_server_setting: "Paramètres du serveur DHCP",
  start_ip: "Démarrer l'adresse IP",
  end_ip: "Fin de la IP",
  enter_lease_time: "Entrez la durée du bail",

  // Alarm Rules
  modem_temperature: "Température",
  modem_signal: "Signal",
  properties: "Caractéristique",
  alarm_rule_add_success: "Règle d'alarme ajoutée",
  alarm_rule_update_success: "Règle d'alarme mise à jour",
  alarm_rule_delete_success: "Règle(s) d'alarme supprimée(s)",
  del_alarm_rule: "Supprimer la règle d'alarme",

  // others
  others: "Autres",
  excel_filename_devices: "Rapport sur les appareils",
};

export default messages;
