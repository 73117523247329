const messages = {
  add_new: "添新",
  select_country: "选择国家",

  // Add Edit Location
  add_location: "添加地点",
  enter_location_name: "输入位置名称",
  enter_longitude: "输入经度",
  enter_latitude: "输入纬度",
  enter_site_name: "输入站点名称",
  enter_ip_address: "输入IP地址",
  add_a_site: "添加站点",
  confirm_delete_location: "你确定要删除这个地点？此操作无法撤消。",
  delete_location: "删除地点",
  save_changes: "保存更改",
  create_new: "创建新的",
  add_location_success: "成功创建新地点",
  url: "网址",

  // Location Details
  coordinates: "坐标",
  longitude: "经度",
  latitude: "纬度",
  number_of_devices: "设备数量",
  view: "打开",

  // Errors
  error_unable_delete: "无法删除。此位置/站点中存在一台或多台设备",

  // Attachments
  attachments: "附件",
  Attachments: "附件",
  attachment_name: "文件名",
  add_attachment: "添加附件",
  select_format: "文件格式",
  download: "下载",
  format: "文件格式",
  upload_success: "上传成功",
  attachment_info: "附件信息",
  size: "文件大小",
  uploaded_by: "上传者",
  uploaded_on: "上传日期",
};

export default messages;
