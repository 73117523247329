const messages = {
  gateway_header_title: "Manage Gateway",
  gateway_label: "Gateway",
  gateway_status: "Status",
  gateway_modem_operator: "Sim Card",
  gateway_last_connection: "Last Connection",
  gateway_device_firmware_version: "Firmware Version",

  gateway_details: "Details",
  //gateway table headers
  gateway_table_name: "Name",
  gateway_table_type: "Type",
  gateway_table_id: "ID",
  gateway_table_location: "location",
  gateway_table_site: "Site",
  gateway_table_status: "Status",

  // gateway tabs
  gateway_information_tab: "Information",
  gateway_alarms_tab: "Alarm Rules",
  gateway_configuration_tab: "Configuration",

  gateway_details_name: "Name",
  gateway_details_deviceId: "Device ID",
  gateway_details_group: "Group",
  gateway_details_ip_address: "IP Address",
  gateway_details_port: "Port",
  gateway_details_read_community: "Read Community",
  gateway_details_write_community: "Write Community",
  gateway_details_activated: "Activated",
  gateway_details_interval: "Interval",
  gateway_details_port_unit_id: "Unit ID",
  gateway_details_serial_number: "Serial Number",
  //dynamic tab
  gateway_dynamic_key: "Key",
  gateway_dynamic_oid: "OID",
  gateway_dynamic_unit: "Unit",
  gateway_dynamic_multiplier: "Multiplier",
  gateway_dynamic_type: "Type",
  gateway_dynamic_writable: "Writable",
  gateway_dynamic_logged: "Logged",
  gateway_dynamic_function: "Function",
  gateway_dynamic_fc: "FC",
  gateway_dynamic_address: "Address",
  gateway_dynamic_length: "Length",
  gateway_dynamic_modbus_type: "Modbus Type",
  gateway_dynamic_channel: "Channel",
  gateway_dynamic_sensor_type: "Sensor Type",

  //static tab
  gateway_statics_key: "Key",
  gateway_statics_oid: "OID",
  gateway_statics_unit: "Unit",
  gateway_statics_multiplier: "Multiplier",
  gateway_statics_type: "Type",
  gateway_statics_writable: "Writable",
  gateway_statics_logged: "Logged",
  gateway_statics_function: "Function",
  gateway_statics_fc: "FC",
  gateway_statics_address: "Address",
  gateway_statics_length: "Length",
  gateway_statics_modbus_type: "Modbus Type",
  gateway_statics_channel: "Channel",
  gateway_statics_sensor_type: "Sensor Type",
  //trap tab
  gateway_trap_oid: "OID",
  gateway_trap_key: "Attribute Name",

  //gateway alarms
  gateway_alarms_name_tab: "Name",
  gateway_alarms_description_tab: "Description",
  gateway_alarms_email: "Email",
  gateway_alarms_level: "Information",
  gateway_alarms_status: "Status",
  gateway_alarms_attribute_name: "Attribute Name",
  gateway_alarms_operator_name: "Operator",
  gateway_alarms_operator_value: "Value",

  gateway_alarm_info: "Information",
  gateway_alarm_warn: "Warning",
  gateway_alarm_crictical: "Critical",

  gateway_int: "Integer",
  gateway_double: "Double",
  gateway_string: "String",
  gateway_boolean: "Boolean",

  gateway_alarm_activated: "Activated",
  gateway_alarm_deactivated: "Deactivated",

  gateway_operator_eq: "EQ",
  gateway_operator_ne: "NE",
  gateway_operator_gt: "GT",
  gateway_operator_ge: "GE",
  gateway_operator_lt: "LT",
  gateway_operator_le: "LE",

  //gateway alarms table
  gateway_alarms_name: "Name",
  gateway_alarms_description: "Description",
  gateway_alarms_properties: "Properties",
  gateway_alarms_severity: "Severity",
  gateway_alarms_date: "Date",
  gateway_alarms_actions: "Actions",

  //protocols
  snmpv2c: "SNMP Agent",
  efento: "Efento BLE",
  modbustcp: "Modbus TCP",
  modbusrtu: "Modbus RTU",

  snmpv2c_address: "SNMP Agent",
  efento_address: "Efento Serial Number",
  modbustcp_address: "Modbus TCP Unit",
  modbusrtu_address: "Modbus RTU Unit",
  gateway_deviceId: "Device ID",

  gateway_device_online: "Online",
  gateway_device_offline: "Offline",

  gateway_na: "Not Available",
  gateway_delete: "Delete",
  gateway_setting: "Setting",

  gateway_dynamics: "Dynamics",
  gateway_statics: "Statics",
  gateway_trap: "Trap",
  gateway_alarms: "Alarms",

  gateway_true: "True",
  gateway_false: "False",

  gateway_public: "Public",
  gateway_private: "Private",

  gateway_config_create: "New",
  gateway_config_update: "Update",

  gateway_delete_device: "Delete Configuration",

  gateway_cancel_config_btn: "Back",
  gateway_submit_config_btn: "Submit",
  gateway_update_config_btn: "Update",

  gateway_success_message: "Configuration done successfully.",
  gateway_reinitialize_message: "Gateway reinitialized successfully.",
  gateway_device_delete_message: "Device configuration deleted successfully.",
};

export default messages;
