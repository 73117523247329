import React from "react";

// unable to get maps error from the API
// implement function to intercept console messages to check for listed google map errors
// https://stackoverflow.com/questions/45338480/try-catch-for-error-message-in-google-maps-api
function takeOverConsole(fallback) {
  var console = window.console;
  if (!console) return;

  function intercept(method) {
    var original = console[method];
    console[method] = function () {
      // All google maps error comes with the prefix 'Google Maps API error' or 'Google Maps API warning'
      // https://developers.google.com/maps/documentation/javascript/error-messages#invalid-key-map-error

      console.log("arguments[0]", arguments[0]); //eslint-disable-line
      // check message
      if (arguments[0] && arguments[0].indexOf("Google Maps") !== -1) {
        fallback();
      }

      if (original.apply) {
        // Do this for normal browsers
        original.apply(console, arguments);
      } else {
        // Do this for IE
        var message = Array.prototype.slice.apply(arguments).join(" ");
        original(message);
      }
    };
  }

  var methods = ["error"]; // only interested in the console.error method
  //var methods = Object.keys(console); // only interested in the console.error method
  for (var i = 0; i < methods.length; i++) intercept(methods[i]);
}

export default class MapErrorBoundary extends React.Component<
  { children: any; fallback: any },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    takeOverConsole(this.props.fallback);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    //console.log(`Error Caught: ${error.message} from ${info.componentStack}`);
  }

  render() {
    if (this.state.hasError) {
      // call fallback function
      return <></>;
    }

    if (this.props.children) {
      return this.props.children;
    }
  }
}
