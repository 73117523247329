const messages = {
  // General
  error: "Une erreur est survenue",

  // Input errors
  err_input_no_value: "Veuillez entrer une valeur",

  // Google Map errors
  get_address_fail: "Impossible de récupérer l'adresse d'entrée",
  get_map_fail: "Erreur lors de la récupération de l'adresse",
  map_no_coordinates: "Impossible de déterminer les coordonnées",

  // Validation errors
  validation_required: "Ce champ est obligatoire.",
  validation_invalid_ip: "L'adresse n'est pas valide",
  validation_end_ip_smaller:
    "L'IP de fin ne peut pas être inférieure à l'IP de début",
  validation_min_latitude: "La latitude ne peut pas être inférieure à -90",
  validation_max_latitude: "La latitude ne peut pas être supérieure à 90",
  validation_min_longitude: "La longitude ne peut pas être inférieure à -180",
  validation_max_longitude: "La longitude ne peut pas être supérieure à 180",
  validation_maxlength: "Longueur maximale dépassée",

  // Device errors
  device_delete_fail:
    "Erreur lors de la tentative de suppression de l'appareil",
  device_add_fail: "Erreur lors de la tentative d'ajout d'un appareil",
  err_file_upload_ext:
    "Impossible de traiter le fichier. Veuillez utiliser uniquement les extensions de fichier suivantes : ",
  err_file_upload: "Erreur lors de la tentative de téléchargement du fichier",
  update_setting_error:
    "Erreur lors de la tentative de mise à jour de votre paramètre.",
  no_devices_of_type: "Il n'y a aucun appareil des types sélectionnés",
  no_devices_in_site: "Il n'y a aucun appareil dans les sites sélectionnés",

  // User
  login_failed: "échec de la connexion",
  no_email_or_password: "Veuillez saisir votre email et votre mot de passe !",
  load_user_info_error:
    "Impossible de récupérer les informations de votre profil.",
  update_user_profile_failed: "Impossible de mettre à jour vos informations",
  get_users_error:
    "Erreur lors de l'obtention des informations sur les utilisateurs",

  // Telemetry
  no_filters: "Aucun filtre spécifié",
};

export default messages;
