const messages = {
  // Widgets
  add_widget: "Add Widget",
  critical_alarm: "Critical Alarm",
  water_leak: "Water Leak",
  pdu_alarm: "PDU Alarm",
  online_devices: "Online Devices",
  door_alarm: "Door Alarm",
  smoke_alarm: "Smoke Alarm",
  select_alert_type: "Select Alert Type*",
  delete_widget: "Delete Widget",
  delete_widget_message:
    "Deleting this widget will affect all other users. Are you sure you want to delete?",
  add_widget_success:
    "New Widget Added. This will affect the screens for all other users.",
  delete_widget_success:
    "Settings updated. This will affect the screens for all other users.",

  // Geographic Map
  geographic_map: "Geographic Map",

  // UPS Device
  ups_device: "UPS Device",
  no_alarm: "No Alarm",

  // Cooling Unit
  cooling_unit: "Cooling Unit",

  // Temperature and Humidity
  temp_humidity_chart_label: "Temperature and Humidity",
  temp_humidity_chart_y_label: "Temperature [°C]",
  temp_humidity_chart_y2_label: "Humidity",
  temperature: "Temperature",
  humidity: "Humidity",

  // Error Messages
  error_widget_exists: "Widget already exists",
  error_save_widget: "Unable to save widget",
};

export default messages;
