const messages = {
  // Languages
  "en-GB": "English",
  "zh-CN": "Chinese (中文)",
  "fr-FR": "French (française)",
  "de-CH": "German (Deutsch)",

  // menu
  menu_home: "主页",
  menu_site: "地点",
  "menu_device-monitoring": "设备监控",
  menu_alarm: "警报",
  menu_user: "账户管理",
  menu_profile: "账户资料",
  menu_tenant: "用户",
  menu_language: "语言",
  menu_license: "许可证书",
  menu_select_tenant: "选择用户",
  menu_telemetry: "遥测",
  menu_attachments: "附件",
  menu_gateway: "网关",
};

export default messages;
