import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { print } from "graphql/language/printer";
import { onError } from "@apollo/client/link/error";
import graphQLContext from "../context/graphQLContext";

const GraphQLApiProvider = (props) => {
  const { children, uri } = props;

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, response }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          const query = print(operation?.query) || "";

          // eslint-disable-next-line
          console.log(
            `[GraphQL error]: 
          Message: ${message}, 
          Extensions: ${JSON.stringify(extensions)},
          Location: ${JSON.stringify(locations)},
          Path: ${path}
          Operation Name: ${operation.operationName},
          Operation Query: 
          ${query},
          Operation Variables: ${JSON.stringify(operation.variables)},
          Response: ${JSON.stringify(response)}
        `
          );
        });
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }
  );

  const httpLink = createHttpLink({
    uri: uri,
    credentials: "include",
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: from([errorLink, httpLink]),
  });

  return (
    <graphQLContext.Provider
      value={{
        uri: uri,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </graphQLContext.Provider>
  );
};

export default GraphQLApiProvider;
