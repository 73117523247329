import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { getContentHeight } from "../utils/ScreenSize";

const drawerWidth = 240;

const SideBar = (props: any) => {
  const {
    children,
    menuList = [],
    topComponent = null,
    bottomComponent = null,
  } = props;

  /* menuList sample
   *   menuList = [
   *       {
   *           text: 'Menu Item Label',
   *           params: {},
   *           onClick: ()=>{}
   *       },
   *       ...
   * ]
   */

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            top: "unset",
            position: "sticky",
          },
        }}
      >
        <Box sx={{ overflow: "auto", height: getContentHeight() }}>
          {topComponent}
          <Divider />
          <List>
            {menuList.map((menu, index) => (
              <ListItem key={menu.text} disablePadding>
                <ListItemButton onClick={() => menu.onClick(menu.params)}>
                  <ListItemText primary={menu.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {bottomComponent && (
            <>
              <Divider />
              {bottomComponent}
            </>
          )}
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Box>
  );
};

export default SideBar;
