import { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import DarkModeContext from "../../providers/context/darkModeContext";
import { defaultNavBarWidth } from "../../constants";
import "../../fonts/Noto_Sans/NotoSans-Medium.ttf";
import "./fonts.css";

function debounce(func) {
  var timer;
  return function (event) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 100, event);
  };
}

export const useNavBarWidthListener = (callback) => {
  window.addEventListener("navBarChanged", () => {
    callback();
  });
};

export const useScrollBarListener = (callback) => {
  window.addEventListener("changeWidthWithScrollBar", () => {
    callback();
  });
};

export const useResizeListener = (callback) => {
  window.addEventListener(
    "resize",
    debounce(() => {
      callback();
    })
  );
};

export const getNavBarWidth = () => {
  if (window.localStorage.getItem("navBarWidth")) {
    return parseInt(window.localStorage.getItem("navBarWidth"));
  } else return defaultNavBarWidth;
};

export const getContentWidth = () => {
  const isScrollBarVisible =
    document.body.scrollHeight > document.body.clientHeight;

  if (isScrollBarVisible) return window.innerWidth - getNavBarWidth() - 17;
  return window.innerWidth - getNavBarWidth();
};

const CommonStyles = (props) => {
  const { children } = props;
  const { colors }: any = useContext(DarkModeContext);

  const defaultStyles = {
    marginLeft: getNavBarWidth(),
    backgroundColor: colors?.contentBg,
    minHeight: "100vh",
    width: getContentWidth(),
    fontFamily: "NotoSans-Regular",
  };

  const [commonStyles, setCommonStyle] = useState(defaultStyles);

  useEffect(() => {
    if (colors) {
      const currStyle = JSON.parse(JSON.stringify(commonStyles));
      currStyle.backgroundColor = colors.contentBg;
      setCommonStyle(currStyle);
    }
  }, [colors]);

  useNavBarWidthListener(() =>
    setCommonStyle({
      ...defaultStyles,
      marginLeft: parseInt(window.localStorage.getItem("navBarWidth")),
      width: getContentWidth(),
    })
  );

  useScrollBarListener(() =>
    setCommonStyle({
      ...defaultStyles,
      width: getContentWidth(),
    })
  );

  return (
    <Box style={{ backgroundColor: colors?.contentBg, overflow: "hidden" }}>
      <Box id={"commonStyles"} style={commonStyles}>
        {children}
      </Box>
    </Box>
  );
};

export default CommonStyles;
