import { useState, useEffect, useContext } from "react";
import UserContext from "./context/userContext";
import ApiContext from "../axios/context/apiContext";
import LoadingScreen from "../components/LoadingScreen";

const UserProvider = (props: any) => {
  // UserProvider has to be a child of ApiProvider with uri linked to SECURED_GATEWAY_URL
  // this way, UserProvider can access the ApiContext to retrieve the uri for redirect
  const { children, userProviderUri = "" } = props;
  const [user, setUser] = useState({});
  const { uri, apiInstanceWithCred }: any = useContext(ApiContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    apiInstanceWithCred
      .get((userProviderUri || uri) + "/user-profile")
      .then((response) => {
        if (response?.data) {
          setUser(response.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {});
  };

  return (
    <UserContext.Provider
      value={{
        user: user,
        setUser: setUser,
        getUserProfile: getUserProfile,
      }}
    >
      <LoadingScreen isLoading={isLoading} />
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
