import { useContext } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useIntl } from "react-intl";
import DarkModeContext from "../../providers/context/darkModeContext";
import {
  HomeIcon,
  DeviceIcon,
  ArrowRight,
  AlarmIcon,
  UserIcon,
  UsersIcon,
  AnalyticsIcon,
} from "../../images/Icons";

const BasicBreadcrumbs = (props) => {
  const { activePage, device } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const fontStyle = {
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0px",
    lineHeight: "20px",
    marginLeft: "10px",
  };

  const seperator = (
    <Box sx={{ marginLeft: "6px", marginRight: "6px" }}>
      <ArrowRight height={"8px"} width={"5px"} style={{ marginLeft: "14px" }} />
    </Box>
  );

  const getIcon = (linkLabel, isActive) => {
    const fill = isActive
      ? colors.breadCrumbsFontActive
      : colors.breadCrumbsFont;

    switch (linkLabel) {
      case "Home":
        return <HomeIcon fill={fill} height={"18px"} width={"18px"} />;
      case "Device":
        return <DeviceIcon fill={fill} height={"18px"} width={"18px"} />;
      case "Alarm":
        return <AlarmIcon fill={fill} height={"18px"} width={"18px"} />;
      case "User":
        return <UserIcon fill={fill} height={"18px"} width={"18px"} />;
      case "Tenants":
        return <UsersIcon fill={fill} height={"18px"} width={"18px"} />;
      case "Telemetry":
        return <TrendingUpIcon sx={{ color: fill }} />;
      case "Gateway":
        return <DeviceIcon fill={fill} height={"18px"} width={"18px"} />;
      case "Attachments":
        return <AttachFileIcon sx={{ color: fill }} />;
      case "Analytics":
      case "ForecastDetails":
      case "ForecastSetup":
        return <AnalyticsIcon fill={fill} height={"18px"} width={"18px"} />;
      default:
        return <DeviceIcon fill={fill} height={"18px"} width={"18px"} />;
    }
  };

  const getLink = (url: string, linkLabel: string, isActive: boolean) => (
    <Link
      underline="hover"
      sx={{ display: "flex", alignItems: "center" }}
      color="inherit"
      href={url}
    >
      {getIcon(linkLabel, isActive)}
      <Typography
        sx={{
          ...fontStyle,
          color: isActive
            ? colors.breadCrumbsFontActive
            : colors.breadCrumbsFont,
        }}
      >
        {intl.formatMessage({ id: linkLabel })}
      </Typography>
    </Link>
  );

  return (
    <Breadcrumbs separator={seperator} aria-label="breadcrumb">
      {getLink("/", "Home", activePage === "Home")}
      {activePage === "DeviceMonitoring" || activePage === "DeviceDetails"
        ? getLink(
            "/device-monitoring",
            "Device",
            activePage === "DeviceMonitoring"
          )
        : null}
      {activePage === "DeviceDetails"
        ? getLink(
            "/device-monitoring/deviceDetails/",
            device?.site?.name || "site",
            true
          )
        : null}
      {activePage === "Alarm"
        ? getLink("/alarm", "Alarm", activePage === "Alarm")
        : null}
      {activePage === "User"
        ? getLink("/user", "User", activePage === "User")
        : null}
      {activePage === "Tenants"
        ? getLink("/tenant", "Tenants", activePage === "Tenants")
        : null}
      {activePage === "Telemetry"
        ? getLink("/telemetry", "Telemetry", activePage === "Telemetry")
        : null}
      {activePage === "Gateway" || activePage === "manage-gateway"
        ? getLink("/gateway", "Gateway", activePage === "Gateway")
        : null}
      {activePage === "manage-gateway"
        ? getLink("/gateway/manage-gateway/", device?.name || " ", true)
        : null}
      {activePage === "Attachments"
        ? getLink(
            "/site/attachments",
            "Attachments",
            activePage === "Attachments"
          )
        : null}
      {activePage === "Analytics" ||
      activePage === "ForecastDetails" ||
      activePage === "ForecastSetup"
        ? getLink("/analytics", "Analytics", activePage === "Analytics")
        : null}
      {activePage === "ForecastDetails"
        ? getLink(
            "/analytics/forecastDetails",
            "ForecastDetails",
            activePage === "ForecastDetails"
          )
        : null}
      {activePage === "ForecastSetup"
        ? getLink(
            "/analytics/forecastDetails",
            "ForecastSetup",
            activePage === "ForecastSetup"
          )
        : null}
    </Breadcrumbs>
  );
};

export default BasicBreadcrumbs;
