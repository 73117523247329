// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/Noto_Sans/NotoSans-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../fonts/Noto_Sans/NotoSans-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../fonts/Noto_Sans/NotoSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "NotoSans-Medium";
  src: local("NotoSans-Medium"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
  font-family: "NotoSans-SemiBold";
  src: local("NotoSans-SemiBold"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: local("NotoSans-Regular"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
`, "",{"version":3,"sources":["webpack://./src/components/CommonStyles/fonts.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B;8DACqE;AACvE;;AAEA;EACE,gCAAgC;EAChC;8DACuE;AACzE;;AAEA;EACE,+BAA+B;EAC/B;8DACsE;AACxE","sourcesContent":["@font-face {\n  font-family: \"NotoSans-Medium\";\n  src: local(\"NotoSans-Medium\"),\n    url(\"../../fonts/Noto_Sans/NotoSans-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"NotoSans-SemiBold\";\n  src: local(\"NotoSans-SemiBold\"),\n    url(\"../../fonts/Noto_Sans/NotoSans-SemiBold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"NotoSans-Regular\";\n  src: local(\"NotoSans-Regular\"),\n    url(\"../../fonts/Noto_Sans/NotoSans-Regular.ttf\") format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
