import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingContext from "./context/loadingContext";

const useLoadingGif = () => {
  const [isLoading, setIsLoading] = useState(false);

  const LoadingGif = () => {
    return (
      <LoadingContext.Provider
        value={{ isLoading: isLoading, setIsLoading: setIsLoading }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </LoadingContext.Provider>
    );
  };

  return { setIsLoading, LoadingGif };
};

export default useLoadingGif;
