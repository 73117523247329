import { useRef, useEffect, useContext, useState } from "react";
// @ts-ignore
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./mapbox.css";
import { useIntl } from "react-intl";
import DarkModeContext from "../../providers/context/darkModeContext";
import { useResizeListener } from "../CommonStyles/index";
mapboxgl.accessToken =
  "pk.eyJ1Ijoia2Fpd2VpZmFuIiwiYSI6ImNscTR5bW4wYzBja3UyanEwaGNrMnEybTAifQ.xJZIM59xDINyPHgvIs_SqA";

const BasicMapBox = (props) => {
  const { locations, mapProps, setSelectedLocation } = props;
  const {
    projection = "globe",
    zoom = 5,
    minZoom = 1,
    dragPan = true,
  } = mapProps;
  const intl = useIntl();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const { colors, darkMode }: any = useContext(DarkModeContext);
  const [currMarkers, setCurrMarkers] = useState([]);

  const getPinColor = (highestSeverity) => {
    switch (highestSeverity) {
      case "INFORMATION":
        return colors.alertBulletNotCritical;
      case "WARNING":
        return colors.alertBulletCritical;
      case "CRITICAL":
        return colors.alertBulletExtremeCritical;
      default:
        return colors.alertBulletNone;
    }
  };

  const getlocationPin = (location) => {
    const color = getPinColor(location?.highestSeverity);

    const el = document.createElement("div");
    el.id = "map_" + location.id;
    el.className = "mapBoxMarker";
    el.onclick = () => {
      setSelectedLocation(location);
    };

    const xmlns = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(xmlns, "svg");
    el.appendChild(svg);

    svg.setAttributeNS(null, "viewBox", "0 0 32 32");
    svg.setAttributeNS(null, "width", "32px");
    svg.setAttributeNS(null, "height", "32px");

    const g1 = document.createElementNS(xmlns, "g");
    svg.appendChild(g1);
    g1.setAttributeNS(null, "stroke", "none");
    g1.setAttributeNS(null, "stroke-width", "1");
    g1.setAttributeNS(null, "fill", "none");
    g1.setAttributeNS(null, "fill-rule", "evenodd");

    const g2 = document.createElementNS(xmlns, "g");
    g1.appendChild(g2);
    //g1.setAttributeNS(null, 'transform', 'translate(-1134, -581)');

    const g3 = document.createElementNS(xmlns, "g");
    g2.appendChild(g3);
    //g3.setAttributeNS(null, 'transform', 'translate(1134, 581)');

    const rect = document.createElementNS(xmlns, "rect");
    g3.appendChild(rect);
    rect.setAttributeNS(null, "x", "0");
    rect.setAttributeNS(null, "y", "0");
    rect.setAttributeNS(null, "width", "32");
    rect.setAttributeNS(null, "height", "32");

    var path = document.createElementNS(xmlns, "path");
    g3.appendChild(path);
    path.setAttributeNS(
      null,
      "d",
      "M16,2.66666667 C10.4,2.66666667 5.33333333,6.96 5.33333333,13.6 C5.33333333,17.84 8.6,22.8266667 15.12,28.5733333 C15.6266667,29.0133333 16.3866667,29.0133333 16.8933333,28.5733333 C23.4,22.8266667 26.6666667,17.84 26.6666667,13.6 C26.6666667,6.96 21.6,2.66666667 16,2.66666667 Z M16,16 C14.5333333,16 13.3333333,14.8 13.3333333,13.3333333 C13.3333333,11.8666667 14.5333333,10.6666667 16,10.6666667 C17.4666667,10.6666667 18.6666667,11.8666667 18.6666667,13.3333333 C18.6666667,14.8 17.4666667,16 16,16 Z"
    );
    path.setAttributeNS(null, "fill", color);
    path.setAttributeNS(null, "fill-rule", "nonzero");

    return el;
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      projection: projection,
      style: darkMode
        ? "mapbox://styles/mapbox/dark-v10"
        : "mapbox://styles/mapbox/streets-v12",
      zoom: zoom,
      minZoom: minZoom,
      dragPan: dragPan,
      attributionControl: false,
    });

    map.current.on("load", function () {
      // on map load, set the locale
      let language = intl.locale.substring(0, intl.locale.indexOf("-"));
      if (language === "zh") language = "zh-Hans";
      map.current.getStyle().layers.forEach(function (thisLayer) {
        // multiple layers of labels to change. Eg.
        /*
          country-label
          state-label
          settlement-label
          settlement-subdivision-label
          airport-label
          poi-label
          water-point-label
          water-line-label
          natural-point-label
          natural-line-label
          waterway-label
          road-label 
        */
        if (thisLayer.id.indexOf("-label") > 0) {
          map.current.setLayoutProperty(thisLayer.id, "text-field", [
            "get",
            `name_${language}`,
          ]);
        }
      });
    });
  }, []);

  useEffect(() => {
    map.current.flyTo({
      center: [mapProps.center.lng, mapProps.center.lat],
      zoom: zoom,
      speed: 1.2,
      curve: 1,
      easing(t) {
        return t;
      },
    });
  }, [mapProps]);

  useEffect(() => {
    const markers = [];
    if (currMarkers.length > 0) {
      // remove all existing markers first
      currMarkers.forEach((marker) => {
        marker.remove();
      });
    }

    locations?.map((location: any) => {
      if (location.status === "ACTIVE") {
        const el = getlocationPin(location);

        // Create a new marker.
        const marker = new mapboxgl.Marker(el)
          .setLngLat([location.longitude, location.latitude])
          .addTo(map.current);

        markers.push(marker);
      }
    });
    setCurrMarkers(markers);
  }, [locations]);

  useResizeListener(() => {
    map.current && map.current.resize();
  });

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{ height: "100%" }}
        ref={mapContainer}
        className="map-container"
      />
    </div>
  );
};

export default BasicMapBox;
