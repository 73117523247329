import React, { useContext } from "react";
import UserContext from "../providers/context/userContext";
import useLoadingGif from "../hooks/useLoadingGif";
import {
  ADMIN_PERMISSIONS,
  TENANT_PERMISSIONS,
  OPERATOR_PERMISSIONS,
} from "./permissions";
//Define user roles and permissions.

const roles: Record<string, string[]> = {
  ADMIN: [...ADMIN_PERMISSIONS],
  TENANT: [...TENANT_PERMISSIONS],
  OPERATOR: [...OPERATOR_PERMISSIONS],
};

const hasAccess = (role: string, permission: string): boolean => {
  return roles[role] && roles[role].includes(permission);
};

const withPermission =
  (requiredPermission: string) =>
  (WrappedComponent: React.ComponentType<any>) => {
    const WithPermission: React.FC<any> = (props) => {
      const { LoadingGif } = useLoadingGif();
      const userContext = useContext<any>(UserContext);
      const {
        user: { role },
      } = userContext;
      if (!role) {
        return <LoadingGif />;
      } else if (role && !hasAccess(role, requiredPermission)) {
        window.location.replace("/home");
        return null;
      }
      return <WrappedComponent {...props} />;
    };
    return WithPermission;
  };

export { withPermission, hasAccess };
