import { useContext } from "react";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import Switch from "@mui/material/Switch";
import DarkModeContext from "../../providers/context/darkModeContext";

const BasicSwitch = (props) => {
  const { form, formItemLabel = "", rules = {}, handleOnChange } = props;
  const { colors }: any = useContext(DarkModeContext);

  const {
    formState: { errors },
  } = form;

  const sx = {
    width: 40,
    height: 24,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      transitionDuration: "300ms",
      color: colors.blueGray400,
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: colors.datwylerTeal,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "grey",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 20,
      height: 20,
      margin: "2px",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: colors.blueGray100,
      opacity: 1,
    },
  };

  return (
    <Controller
      control={form.control}
      name={formItemLabel}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        const handleOnChangeInject = (val) => {
          if (handleOnChange) handleOnChange(val);
          onChange(val);
        };

        return (
          <FormControl fullWidth>
            <Switch
              checked={value}
              onChange={handleOnChangeInject}
              sx={sx}
              disableRipple
            />
          </FormControl>
        );
      }}
    />
  );
};

export default BasicSwitch;
