const messages = {
  add_new: "Add New",
  select_country: "Select Country",

  // Add Edit Location
  add_location: "Add Location",
  enter_location_name: "Enter Location Name",
  enter_longitude: "Enter Longitude",
  enter_latitude: "Enter Latitude",
  enter_site_name: "Enter Site Name",
  enter_ip_address: "Enter IP Address",
  add_a_site: "Add a Site",
  confirm_delete_location:
    "Are you sure you want to delete this location? This action cannot be undone.",
  delete_location: "Delete Location",
  save_changes: "Save Changes",
  create_new: "Create New",
  add_location_success: "New Location Created",
  url: "URL",

  // Location Details
  coordinates: "Coordinates",
  longitude: "Longitude",
  latitude: "Latitude",
  number_of_devices: "Number of Devices",
  view: "View",

  // Errors
  error_unable_delete:
    "Unable to delete. One or more devices exist in this location/site",

  // Attachments
  attachments: "Attachments",
  Attachments: "Attachments",
  attachment_name: "Name",
  add_attachment: "Add Attachment",
  select_format: "Select File Format",
  download: "Download",
  format: "Format",
  upload_success: "Upload Success",
  attachment_info: "Attachment Information",
  size: "Size",
  uploaded_by: "Uploaded By",
  uploaded_on: "Uploaded On",
};

export default messages;
