import { useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import DatwylerLogo from "../../images/Datwyler_logo.png";
import DarkModeContext from "../../providers/context/darkModeContext";

const LoadingScreen = (props: any) => {
  const { isLoading } = props;
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Backdrop
      sx={{
        backgroundColor: "#FFFFFF",
        color: colors.datwylerTeal,
        zIndex: (theme) => theme.zIndex.drawer + 1000,
      }}
      open={isLoading}
    >
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <img
            src={DatwylerLogo}
            style={{
              width: 191,
              height: 35,
              background: "white",
            }}
            alt="datwyler logo"
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <CircularProgress color="inherit" />
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default LoadingScreen;
