const messages = {
  // Widgets
  add_widget: "添加小部件",
  critical_alarm: "紧急警报",
  water_leak: "漏水",
  pdu_alarm: "PDU警报",
  online_devices: "在线设备",
  door_alarm: "门警报器",
  smoke_alarm: "烟雾警报器",
  select_alert_type: "选择警报类型*",
  delete_widget: "删除小部件",
  delete_widget_message: "删除此小部件将影响所有其他账户。你确定你要删除？",
  add_widget_success: "添加了新的小部件。这将影响所有其他账户的屏幕。",
  delete_widget_success: "设置更新。这将影响所有其他账户的屏幕。",

  // Geographic Map
  geographic_map: "地理地图",

  // UPS Device
  ups_device: "不间断电源设备",
  no_alarm: "无警报",

  // Cooling Unit
  cooling_unit: "空调",

  // Temperature and Humidity
  temp_humidity_chart_label: "温度与湿度",
  temp_humidity_chart_y_label: "温度 [°C]",
  temp_humidity_chart_y2_label: "湿度",
  temperature: "温度",
  humidity: "湿度",

  // Error Messages
  error_widget_exists: "小部件已存在",
  error_save_widget: "无法保存小部件",
};

export default messages;
