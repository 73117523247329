const messages = {
  // General
  error: "An error has occurred",

  // Input errors
  err_input_no_value: "Please key in a value",

  // Google Map errors
  get_address_fail: "Unable to retrieve the input address",
  get_map_fail: "Error in retrieving address",
  map_no_coordinates: "Unable to determine coordinates",

  // Validation errors
  validation_required: "This field is required.",
  validation_invalid_ip: "IP Address is invalid",
  validation_end_ip_smaller: "End IP cannot be smaller than start IP",
  validation_min_latitude: "Latitude cannot be less than -90",
  validation_max_latitude: "Latitude cannot be more than 90",
  validation_min_longitude: "Longitude cannot be less than -180",
  validation_max_longitude: "Longitude cannot be more than 180",
  validation_maxlength: "Maximum length exceeded",

  // Device errors
  device_delete_fail: "Error while trying to delete device",
  device_add_fail: "Error while trying to add device",
  err_file_upload_ext:
    "Unable to process file. Please use the following file extensions only: ",
  err_file_upload: "Error while trying to upload the file",
  update_setting_error: "Error while trying to update your setting.",
  no_devices_of_type: "There are no devices of selected types",
  no_devices_in_site: "There are no devices in selected sites",

  // User
  login_failed: "Login failed",
  no_email_or_password: "Please enter your email and password!",
  load_user_info_error: "Unable to retrieve your profile information.",
  update_user_profile_failed: "Unable to update your information",
  get_users_error: "Error getting users information",

  // Telemetry
  no_filters: "No filters specified",
};

export default messages;
