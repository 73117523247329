import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import BasicDateRangePickerWithoutForm from "./WithoutForm/BasicDateRangePickerWithoutForm";
import { getEpochTime } from "../../utils/dateUtils";

const BasicDateRange = (props) => {
  const { form, formItemLabel = "", rules = {} } = props;

  const {
    formState: { errors },
  } = form;

  return (
    <Controller
      control={form.control}
      name={formItemLabel}
      rules={rules}
      render={({ field: { onChange } }) => {
        const handleOnChange = (startDate, endDate) => {
          // returns 2 values in the form
          // eg. form.getvalues([formItemLabel]) = {startDate: Dayjs, endDate: Dayjs}
          onChange({ startDate: startDate, endDate: endDate });
        };

        return (
          <FormControl fullWidth>
            <BasicDateRangePickerWithoutForm
              {...props}
              handleOnChange={handleOnChange}
              errors={errors[formItemLabel]}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default BasicDateRange;
