import { useEffect, useState, useContext } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { menuItemsSort, getBasicSelectSx } from "./utils";
import DarkModeContext from "../../providers/context/darkModeContext";

const BasicSelect = (props) => {
  const {
    menuItems,
    label = undefined,
    form,
    formItemLabel = "",
    isDisabled = false,
    rules = {},
    overrideOnChange = null,
    overrideStyle = {},
    isDisableMenuTranslations = false,
    defaultValue,
    size,
  } = props;
  const { colors }: any = useContext(DarkModeContext);
  const [sortedMenuItem, setSortedMenuItem] = useState(menuItems);
  const {
    formState: { errors },
  } = form;
  const intl = useIntl();

  useEffect(() => {
    setSortedMenuItem(menuItemsSort(menuItems));
  }, [menuItems]);

  const getMenuLabels = (label) => {
    return isDisableMenuTranslations
      ? label
      : intl.formatMessage({ id: label });
  };

  return (
    <Controller
      control={form.control}
      name={formItemLabel}
      rules={rules}
      render={({ field: { onChange, value = defaultValue } }) => {
        const handleOnChange = (e) => {
          if (overrideOnChange) overrideOnChange(e.target.value);
          onChange(e);
        };

        return (
          <FormControl
            fullWidth
            disabled={isDisabled}
            error={!!errors[formItemLabel]}
            size={size}
          >
            {label && (
              <InputLabel
                id={label}
                sx={{
                  fontFamily: "NotoSans-Regular",
                  "&&.Mui-focused": {
                    color: colors.datwylerTeal,
                    width: "200px",
                  },
                }}
              >
                {intl.formatMessage({ id: label })}
              </InputLabel>
            )}
            <Select
              labelId={label}
              id={"select-" + label}
              value={value ? value : ""}
              label={label ? intl.formatMessage({ id: label }) : label}
              onChange={handleOnChange}
              style={overrideStyle}
              sx={getBasicSelectSx(colors)}
            >
              {sortedMenuItem.map((menuItem) => {
                const menuItemLabel = getMenuLabels(menuItem.label);
                return (
                  <MenuItem
                    disabled={menuItem.isDisabled}
                    key={menuItem.label}
                    value={menuItem.value}
                  >
                    {menuItemLabel}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{errors[formItemLabel]?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default BasicSelect;
