import ApiContext from "./context/apiContext";
import axios from "axios";
import useLoadingGif from "../hooks/useLoadingGif";
import { redirectToLogin } from "../utils/authUtils";

const ApiProvider = (props: any) => {
  const { children, uri } = props;
  const { LoadingGif, setIsLoading } = useLoadingGif();

  const apiInstance = axios.create({
    baseURL: uri,
  });

  const apiInstanceWithCred = axios.create({
    baseURL: uri,
    withCredentials: true,
    headers: {
      get: {
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "http://localhost:9000",
      },
    },
  });

  const initLoadingInterceptor = (instance) => {
    instance.interceptors.request.use((config) => {
      setIsLoading(true);
      return config;
    });

    instance.interceptors.response.use(
      (response) => {
        setIsLoading(false);
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          redirectToLogin(uri);
        }
        setIsLoading(false);
        return Promise.reject(error);
      }
    );
  };

  initLoadingInterceptor(apiInstance);
  initLoadingInterceptor(apiInstanceWithCred);

  return (
    <ApiContext.Provider
      value={{
        uri: uri,
        apiInstance: apiInstance,
        apiInstanceWithCred: apiInstanceWithCred,
      }}
    >
      <LoadingGif />
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
