const messages = {
  add_new: "Ajouter nouveau",
  select_country: "Choisissez le pays",

  // Add Edit Location
  add_location: "Ajouter un emplacement",
  enter_location_name: "Entrez le nom de l'emplacement",
  enter_longitude: "Entrez la longitude",
  enter_latitude: "Entrez la latitude",
  enter_site_name: "Entrez le nom du site",
  enter_ip_address: "Entrez l'adresse IP",
  add_a_site: "Ajouter un site",
  confirm_delete_location:
    "Êtes-vous sûr de vouloir supprimer cet emplacement? Cette action ne peut pas être annulée.",
  delete_location: "Supprimer l'emplacement",
  save_changes: "Sauvegarder les modifications",
  create_new: "Créer un nouveau",
  add_location_success: "Nouvel emplacement créé",
  url: "URL",

  // Location Details
  coordinates: "Coordonnés",
  longitude: "Longitude",
  latitude: "Latitude",
  number_of_devices: "Nombre d'appareils",
  view: "Voir",

  // Errors
  error_unable_delete:
    "Impossible de supprimer. Un ou plusieurs appareils existent à cet emplacement/site",

  // Attachments
  attachments: "Pièce jointes",
  Attachments: "Pièce jointes",
  attachment_name: "Nom de fichier",
  add_attachment: "Ajouter une pièce jointe",
  select_format: "Format de fichier",
  download: "Télécharger",
  format: "Format de fichier",
  upload_success: "Télécharger le succès",
  attachment_info: "Informations sur la pièce jointe",
  size: "Taille du fichier",
  uploaded_by: "Telechargé par",
  uploaded_on: "Date de téléchargement",
};

export default messages;
