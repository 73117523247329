export const menuItemsSort = (menuItems) => {
  const test = [...menuItems];
  return test.sort(function (a, b) {
    if (a.priority > b.priority) return 1;
    else return -1;
  });
};

export const getBasicSelectMenuProps = (
  scrollBarSx,
  menuPaperSx,
  menuPropsSx,
  colors
) => {
  return {
    autoFocus: true,
    sx: {
      marginTop: "8px",
      "&& .Mui-selected": {
        backgroundColor: colors.selectDropDownBgSelected,
        color: colors.selectDropDownFontSelected,
      },
      "&& .Mui-selected:hover": {
        backgroundColor: colors.selectDropDownBgActive,
        borderColor: colors.datwylerTeal,
      },
      "&& .MuiMenu-paper": {
        borderRadius: "8px",
        backgroundColor: colors.selectDropDownBg,
        color: colors.selectDropDownFont,
        ...scrollBarSx,
        ...menuPaperSx,
      },
      ...menuPropsSx,
    },
  };
};

export const getBasicSelectSx = (colors) => {
  return {
    borderRadius: "8px",
    color: colors.selectFont,
    "&.MuiInput-root::after": {
      borderColor: colors.datwylerTeal,
    },
    "&&.MuiInput-root:hover::before": {
      borderColor: colors.selectStandardUnderlineFocused,
    },
    "&.MuiInput-root::before": {
      borderColor: colors.selectStandardUnderline + "6b",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.datwylerTeal,
    },
    "&:hover": {
      color: colors.datwylerTeal,
      "&& fieldset": {
        borderColor: colors.datwylerTeal,
      },
      "& .Mui-focused": {
        color: colors.datwylerTeal,
      },
    },
    "&& fieldset": {
      borderColor: colors.selectOutline,

      "&& legend": {
        maxWidth: "0.01px",
      },
    },
    ".MuiSvgIcon-root ": {
      fill: colors.selectDropDownArrow,
    },
  };
};

export const basicSelectDisplayPropsStyle = {
  paddingTop: "12px",
  paddingBottom: "13px",
  paddingLeft: "16px",
  minHeight: "0px",
  borderRadius: "8px",
  height: "19px",
};
