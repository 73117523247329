const messages = {
  // Login
  email: "电子邮件",
  login: "登录",
  password: "密码",

  // Profile
  my_profile: "我的资料",
  name: "姓名",
  company: "公司",
  contact_number: "联系电话",
  role: "角色",
  change_password: "更改密码",
  profile_info: "档案信息",
  log_out: "登出",
  confirm_logout: "您确定要退出吗?",

  // Users
  add_user: "添加账户",
  update_profile_info: "更新个人资料信息",
  first_name: "名",
  last_name: "姓",
  deactivate_user: "停用账户",
  excel_filename_user: "账户报告",
  select_country_code: "请选择国家代码",

  // Roles
  ADMIN: "管理员",
  OPERATOR: "操作员",

  // success messages
  user_deactivated_success: "账户已成功停用",
  new_user_added: "新账户添加成功",
};

export default messages;
