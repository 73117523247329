const messages = {
  // Widgets
  add_widget: "Ajouter un widget",
  critical_alarm: "Alarme critique",
  water_leak: "Fuite d'eau",
  pdu_alarm: "Alarme PDU",
  online_devices: "Appareils en ligne",
  door_alarm: "Alarme de porte",
  smoke_alarm: "Détecteur de fumé",
  select_alert_type: "Sélectionnez le type d'alerte*",
  delete_widget: "Supprimer le widget",
  delete_widget_message:
    "La suppression de ce widget affectera tous les autres utilisateurs. Etes-vous sûr que vous voulez supprimer?",
  add_widget_success:
    "Nouveau widget ajouté. Cela affectera les écrans de tous les autres utilisateurs.",
  delete_widget_success:
    "Paramètres mis à jour. Cela affectera les écrans de tous les autres utilisateurs.",

  // Geographic Map
  geographic_map: "Carte géographique",

  // UPS Device
  ups_device: "Périphérique ASI",
  no_alarm: "Pas d'alarme",

  // Cooling Unit
  cooling_unit: "Unité de refroidissement",

  // Temperature and Humidity
  temp_humidity_chart_label: "Température et humidité",
  temp_humidity_chart_y_label: "Température [°C]",
  temp_humidity_chart_y2_label: "Humidité",
  temperature: "Température",
  humidity: "Humidité",

  // Error Messages
  error_widget_exists: "Le widget existe déjà",
  error_save_widget: "Impossible d'enregistrer le widget",
};

export default messages;
